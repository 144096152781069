import axios from "axios";
import React, {useRef, useState, useEffect} from "react";
import {AiOutlineFieldTime} from 'react-icons/ai'
import {CiDiscount1} from 'react-icons/ci'

const Discounts = () => {

    const [discountView, setDiscountView] = useState(false);
    const [discount, setDiscount] = useState([10, 20, 25, 30])
    const [settings, setSettings] = useState(false)
    const [hrsDeinze, setHrsDeinze] = useState({
        // 0: {index: 0, day: "Maandag", opens: "09:00", closes: "20:00", opened: false},
        // 1: {index: 1, day: "Dinsdag", opens: "09:00", closes: "20:00", opened: true},
        // 2: {index: 2, day: "Woensdag", opens: "09:00", closes: "20:00", opened: true},
        // 3: {index: 3, day: "Donderdag", opens: "09:00", closes: "20:00", opened: true},
        // 4: {index: 4, day: "Vrijdag", opens: "09:00", closes: "20:00", opened: true},
        // 5: {index: 5, day: "Zaterdag", opens: "09:00", closes: "20:00", opened: true},
        // 6: {index: 6, day: "Zondag", opens: "09:00", closes: "20:00", opened: false}
    })

    const [hrsDentergem, setHrsDentergem] = useState({
        // 0: {index: 0, day: "Maandag", opens: "09:00", closes: "20:00", opened: false},
        // 1: {index: 1, day: "Dinsdag", opens: "09:00", closes: "20:00", opened: true},
        // 2: {index: 2, day: "Woensdag", opens: "09:00", closes: "20:00", opened: true},
        // 3: {index: 3, day: "Donderdag", opens: "09:00", closes: "20:00", opened: true},
        // 4: {index: 4, day: "Vrijdag", opens: "09:00", closes: "20:00", opened: true},
        // 5: {index: 5, day: "Zaterdag", opens: "09:00", closes: "20:00", opened: true},
        // 6: {index: 6, day: "Zondag", opens: "09:00", closes: "20:00", opened: false}
    })

    const [hrsDeinzeVacation, setHrsDeinzeVacation] = useState({
        // 0: {index: 0, day: "Maandag", opens: "09:00", closes: "20:00", opened: false},
        // 1: {index: 1, day: "Dinsdag", opens: "09:00", closes: "20:00", opened: true},
        // 2: {index: 2, day: "Woensdag", opens: "09:00", closes: "20:00", opened: true},
        // 3: {index: 3, day: "Donderdag", opens: "09:00", closes: "20:00", opened: true},
        // 4: {index: 4, day: "Vrijdag", opens: "09:00", closes: "20:00", opened: true},
        // 5: {index: 5, day: "Zaterdag", opens: "09:00", closes: "20:00", opened: true},
        // 6: {index: 6, day: "Zondag", opens: "09:00", closes: "20:00", opened: false}
    })

    const [hrsDentergemVacation, setHrsDentergemVacation] = useState({
        // 0: {index: 0, day: "Maandag", opens: "09:00", closes: "20:00", opened: false},
        // 1: {index: 1, day: "Dinsdag", opens: "09:00", closes: "20:00", opened: true},
        // 2: {index: 2, day: "Woensdag", opens: "09:00", closes: "20:00", opened: true},
        // 3: {index: 3, day: "Donderdag", opens: "09:00", closes: "20:00", opened: true},
        // 4: {index: 4, day: "Vrijdag", opens: "09:00", closes: "20:00", opened: true},
        // 5: {index: 5, day: "Zaterdag", opens: "09:00", closes: "20:00", opened: true},
        // 6: {index: 6, day: "Zondag", opens: "09:00", closes: "20:00", opened: false}
    })

    const [hrsDeinzeLateNight, setHrsDeinzeLateNight] = useState({
        // 0: {index: 0, day: "Maandag", opens: "09:00", closes: "20:00", opened: false},
        // 1: {index: 1, day: "Dinsdag", opens: "09:00", closes: "20:00", opened: true},
        // 2: {index: 2, day: "Woensdag", opens: "09:00", closes: "20:00", opened: true},
        // 3: {index: 3, day: "Donderdag", opens: "09:00", closes: "20:00", opened: true},
        // 4: {index: 4, day: "Vrijdag", opens: "09:00", closes: "20:00", opened: true},
        // 5: {index: 5, day: "Zaterdag", opens: "09:00", closes: "20:00", opened: true},
        // 6: {index: 6, day: "Zondag", opens: "09:00", closes: "20:00", opened: false}
    })

    const [hrsDentergemLateNight, setHrsDentergemLateNight] = useState({
        // 0: {index: 0, day: "Maandag", opens: "09:00", closes: "20:00", opened: false},
        // 1: {index: 1, day: "Dinsdag", opens: "09:00", closes: "20:00", opened: true},
        // 2: {index: 2, day: "Woensdag", opens: "09:00", closes: "20:00", opened: true},
        // 3: {index: 3, day: "Donderdag", opens: "09:00", closes: "20:00", opened: true},
        // 4: {index: 4, day: "Vrijdag", opens: "09:00", closes: "20:00", opened: true},
        // 5: {index: 5, day: "Zaterdag", opens: "09:00", closes: "20:00", opened: true},
        // 6: {index: 6, day: "Zondag", opens: "09:00", closes: "20:00", opened: false}
    })

    const sortData = (data, attr) => {
        var arr = [];
        for (var prop in data) {
            if (data.hasOwnProperty(prop)) {
                var obj = {};
                obj[prop] = data[prop];
                obj.tempSortName = data[prop][attr];
                arr.push(obj);
            }
        }

        arr.sort(function (a, b) {
            var at = a.tempSortName,
                bt = b.tempSortName;
            return at > bt ? 1 : (at < bt ? -1 : 0);
        });

        var result = [];
        for (var i = 0, l = arr.length; i < l; i++) {
            var obj = arr[i];
            delete obj.tempSortName;
            for (var prop in obj) {
                if (obj.hasOwnProperty(prop)) {
                    var id = prop;
                }
            }
            var item = obj[id];
            result.push(item);
        }
        return result;
    }

    const saveChanges = () => {
        const data = {
            businessHours: {
                deinze: hrsDeinze,
                dentergem: hrsDentergem,
                deinzeVacation: hrsDeinzeVacation,
                dentergemVacation: hrsDentergemVacation,
                deinzeLateNight: hrsDeinzeLateNight,
                dentergemLateNight: hrsDentergemLateNight
            },
            group_discount_10: settings.group_discount_10,
            group_discount_25: settings.group_discount_25,
            group_discount_50: settings.group_discount_50,
            interval: settings.interval
        }
        console.log(data)
        axios.post(`${process.env.REACT_APP_BASE_URL}/api/settings`, data).then(res => console.log(res))
    }

    useEffect(() => {
        setDiscount(discount.sort((a, b) => (a.type > b.type) ? 1 : ((b.type > a.type) ? -1 : 0)))

        axios.get(`${process.env.REACT_APP_BASE_URL}/api/settings`).then(res => {
            console.log(res.data)
            const deinze = sortData(res.data.businessHours.deinze, 'index')
            const dentergem = sortData(res.data.businessHours.dentergem, 'index')
            const deinzeVacation = sortData(res.data.businessHours.deinzeVacation, 'index')
            const deinzeLateNight = sortData(res.data.businessHours.deinzeLateNight, 'index')
            const dentergemVacation = sortData(res.data.businessHours.dentergemVacation, 'index')
            const dentergemLateNight = sortData(res.data.businessHours.dentergemLateNight, 'index')
            console.log(deinze)
            setHrsDeinze(deinze)
            setHrsDeinzeVacation(deinzeVacation)
            setHrsDeinzeLateNight(deinzeLateNight)
            setHrsDentergem(dentergem)
            setHrsDentergemVacation(dentergemVacation)
            setHrsDentergemLateNight(dentergemLateNight)
            setSettings(res.data)
        })
    }, []);

    // const [activeK, setactiveK] = useState(0);
    // const [activeS, setActiveS] = useState(0);
    // const [activeSI, setActiveSI] = useState(0);
    // const [activeL, setActiveL] = useState(0);
    //
    // const [discountKayak, setDiscountKayak] = useState([
    //     {type: "Kajak", amount: "10%", color: "bg-indigo-100"},
    //     {type: "Kajak", amount: "20%", color: "bg-indigo-100"},
    //     {type: "Kajak", amount: "25%", color: "bg-indigo-100"},
    //     {type: "Kajak", amount: "30%", color: "bg-indigo-100"}])
    //
    // const [discountSup, setDiscountSup] = useState([
    //     {type: "Sup", amount: "10%", color: "bg-indigo-100"},
    //     {type: "Sup", amount: "20%", color: "bg-indigo-100"},
    //     {type: "Sup", amount: "25%", color: "bg-indigo-100"},
    //     {type: "Sup", amount: "30%", color: "bg-indigo-100"}])
    //
    // const [discountSupIsland, setDiscountSupIsland] = useState([
    //     {type: "Sup eilanden", amount: "10%", color: "bg-indigo-100"},
    //     {type: "Sup eilanden", amount: "20%", color: "bg-indigo-100"},
    //     {type: "Sup eilanden", amount: "25%", color: "bg-indigo-100"},
    //     {type: "Sup eilanden", amount: "30%", color: "bg-indigo-100"}])
    //
    // const [discountLesson, setDiscountLesson] = useState([
    //     {type: "Initiatielessen", amount: "10%", color: "bg-indigo-100"},
    //     {type: "Initiatielessen", amount: "20%", color: "bg-indigo-100"},
    //     {type: "Initiatielessen", amount: "25%", color: "bg-indigo-100"},
    //     {type: "Initiatielessen", amount: "30%", color: "bg-indigo-100"}])

    return (
        <div className="mx-auto bg-slate-50 shadow rounded">
            <div className="xl:w-full bg-slate-100 py-5">
                <div className="flex items-center w-11/12 mx-auto">
                    <div className="">
                        <h4 className="text-3xl font-bold leading-tight text-gray-800 text-left w-full ">Instellingen</h4>
                    </div>
                </div>
            </div>
            <div className=" flex flex-col">
                <div className="flex justify-start">
                    <div className=" bg-slate-50 py-6 pl-16">
                        <div className="flex items-center mb-8 text-gray-800">
                            <AiOutlineFieldTime className=" text-2xl"/>
                            <p className="text-sm font-bold ml-2 text-gray-800">Beheer openingstijden</p>
                        </div>
                        <div className="flex justify-between items-center mb-8">
                            <fieldset className=" w-48 border border-slate-300 px-8 rounded-md">
                                <legend className=" px-2 whitespace-nowrap font-bold">Openingsuren Dentergem</legend>
                                {Object.entries(hrsDentergem).map(([key, el]) =>
                                    <div key={key} className="flex flex-row justify-center items-center my-5">
                                        <div className="flex flex-row items-center">
                                            <label className=" mr-16 w-24">{el.day} </label>
                                            <input type="time"
                                                   min="08:00" max="23:00"
                                                   className=" bg-slate-200 px-5 py-1 rounded-md"
                                                   onChange={(e) => setHrsDentergem(prev => ({
                                                       ...prev,
                                                       [key]: {...prev[key], opens: e.target.value}
                                                   }))} defaultValue={el.opens}/>
                                            <span className=" mx-5">tot</span>
                                            <input type="time"
                                                   min="08:00" max="23:00"
                                                   className=" bg-slate-200 px-5 py-1 rounded-md"
                                                   onChange={(e) => setHrsDentergem(prev => ({
                                                       ...prev,
                                                       [key]: {...prev[key], closes: e.target.value}
                                                   }))} defaultValue={el.closes}/>
                                        </div>
                                        {/*<div className=" flex justify-center items-center">*/}
                                        {/*    <input type="checkbox" className=" w-5 h-5 ml-8"*/}
                                        {/*           onChange={(e) => setHrsDentergem(prev => ({*/}
                                        {/*               ...prev, [key]: {...prev[key], opened: e.target.checked}*/}
                                        {/*           }))} checked={el.opened}/>*/}
                                        {/*</div>*/}
                                    </div>)}
                            </fieldset>
                        </div>
                    </div>
                    <div className=" ml-16 mx-auto bg-slate-50 py-6">
                        <div className="flex items-center mb-8 text-gray-800">
                            <AiOutlineFieldTime className=" text-2xl opacity-0"/>
                            <p className="text-sm font-bold ml-2 text-gray-800"></p>
                        </div>
                        <div className="flex justify-between items-center mb-8">
                            <fieldset className=" w-48 border border-slate-300 px-8 rounded-md">
                                <legend className=" px-2 whitespace-nowrap font-bold">Openingsuren Deinze</legend>
                                {Object.entries(hrsDeinze).map(([key, el]) =>
                                    <div key={key} className="flex flex-row justify-center items-center my-5">
                                        <div className="flex flex-row items-center">
                                            <label className=" mr-16 w-24">{el.day} </label>
                                            <input type="time"
                                                   min="08:00" max="23:00"
                                                   className=" bg-slate-200 px-5 py-1 rounded-md"
                                                   onChange={(e) => setHrsDeinze(prev => ({
                                                       ...prev,
                                                       [key]: {...prev[key], opens: e.target.value}
                                                   }))} defaultValue={el.opens}/>
                                            <span className=" mx-5">tot</span>
                                            <input type="time"
                                                   min="08:00" max="23:00"
                                                   className=" bg-slate-200 px-5 py-1 rounded-md"
                                                   onChange={(e) => setHrsDeinze(prev => ({
                                                       ...prev,
                                                       [key]: {...prev[key], closes: e.target.value}
                                                   }))} defaultValue={el.closes}/>
                                        </div>
                                        {/*<div className=" flex justify-center items-center">*/}
                                        {/*    <input type="checkbox" className=" w-5 h-5 ml-8"*/}
                                        {/*           onChange={(e) => setHrsDeinze(prev => ({*/}
                                        {/*               ...prev, [key]: {...prev[key], opened: e.target.checked}*/}
                                        {/*           }))} checked={el.opened}/>*/}
                                        {/*</div>*/}
                                    </div>)}
                            </fieldset>
                        </div>
                    </div>
                </div>


                <div className="flex justify-start">
                    <div className=" bg-slate-50 py-6 pl-16">

                        <div className="flex justify-between items-center mb-8">
                            <fieldset className=" w-48 border border-slate-300 px-8 rounded-md">
                                <legend className=" px-2 whitespace-nowrap font-bold">Openingsuren Schoolvakantie Dentergem
                                </legend>
                                {Object.entries(hrsDentergemVacation).map(([key, el]) =>
                                    <div key={key} className="flex flex-row justify-center items-center my-5">
                                        <div className="flex flex-row items-center">
                                            <label className=" mr-16 w-24">{el.day} </label>
                                            <input type="time"
                                                   min="08:00" max="23:00"
                                                   className=" bg-slate-200 px-5 py-1 rounded-md"
                                                   onChange={(e) => setHrsDentergemVacation(prev => ({
                                                       ...prev,
                                                       [key]: {...prev[key], opens: e.target.value}
                                                   }))} defaultValue={el.opens}/>
                                            <span className=" mx-5">tot</span>
                                            <input type="time"
                                                   min="08:00" max="23:00"
                                                   className=" bg-slate-200 px-5 py-1 rounded-md"
                                                   onChange={(e) => setHrsDentergemVacation(prev => ({
                                                       ...prev,
                                                       [key]: {...prev[key], closes: e.target.value}
                                                   }))} defaultValue={el.closes}/>
                                        </div>
                                        {/*<div className=" flex justify-center items-center">*/}
                                        {/*    <input type="checkbox" className=" w-5 h-5 ml-8"*/}
                                        {/*           onChange={(e) => setHrsDentergemVacation(prev => ({*/}
                                        {/*               ...prev, [key]: {...prev[key], opened: e.target.checked}*/}
                                        {/*           }))} checked={el.opened}/>*/}
                                        {/*</div>*/}
                                    </div>)}
                            </fieldset>
                        </div>
                    </div>
                    <div className=" ml-16 mx-auto bg-slate-50 py-6">

                        <div className="flex justify-between items-center mb-8">
                            <fieldset className=" w-48 border border-slate-300 px-8 rounded-md">
                                <legend className=" px-2 whitespace-nowrap font-bold">Openingsuren Schoolvakantie
                                    Deinze
                                </legend>
                                {Object.entries(hrsDeinzeVacation).map(([key, el]) =>
                                    <div key={key} className="flex flex-row justify-center items-center my-5">
                                        <div className="flex flex-row items-center">
                                            <label className=" mr-16 w-24">{el.day} </label>
                                            <input type="time"
                                                   min="08:00" max="23:00"
                                                   className=" bg-slate-200 px-5 py-1 rounded-md"
                                                   onChange={(e) => setHrsDeinzeVacation(prev => ({
                                                       ...prev,
                                                       [key]: {...prev[key], opens: e.target.value}
                                                   }))} defaultValue={el.opens}/>
                                            <span className=" mx-5">tot</span>
                                            <input type="time"
                                                   min="08:00" max="23:00"
                                                   className=" bg-slate-200 px-5 py-1 rounded-md"
                                                   onChange={(e) => setHrsDeinzeVacation(prev => ({
                                                       ...prev,
                                                       [key]: {...prev[key], closes: e.target.value}
                                                   }))} defaultValue={el.closes}/>
                                        </div>
                                        {/*<div className=" flex justify-center items-center">*/}
                                        {/*    <input type="checkbox" className=" w-5 h-5 ml-8"*/}
                                        {/*           onChange={(e) => setHrsDeinzeVacation(prev => ({*/}
                                        {/*               ...prev, [key]: {...prev[key], opened: e.target.checked}*/}
                                        {/*           }))} checked={el.opened}/>*/}
                                        {/*</div>*/}
                                    </div>)}
                            </fieldset>
                        </div>
                    </div>
                </div>


                {/*Late night*/}
                <div className="flex justify-start">
                    <div className=" bg-slate-50 py-6 pl-16">

                        <div className="flex justify-between items-center mb-8">
                            <fieldset className=" w-48 border border-slate-300 px-8 rounded-md">
                                <legend className=" px-2 whitespace-nowrap font-bold">Openingsuren Late Night Dentergem
                                </legend>
                                {Object.entries(hrsDentergemLateNight).map(([key, el]) =>
                                    <div key={key} className="flex flex-row justify-center items-center my-5">
                                        <div className="flex flex-row items-center">
                                            <label className=" mr-16 w-24">{el.day} </label>
                                            <input type="time"
                                                   min="08:00" max="23:00" className=" bg-slate-200 px-5 py-1 rounded-md" onChange={(e) => setHrsDentergemLateNight(prev => ({
                                                ...prev,
                                                [key]: { ...prev[key], opens: e.target.value }
                                            }))} defaultValue={el.opens} />
                                            <span className=" mx-5"> tot</span>
                                            <input type="time"
                                                   min="08:00" max="23:00"
                                                   className=" bg-slate-200 px-5 py-1 rounded-md"
                                                   onChange={(e) => setHrsDentergemLateNight(prev => ({
                                                       ...prev,
                                                       [key]: {...prev[key], closes: e.target.value}
                                                   }))} defaultValue={el.closes}/>
                                        </div>
                                        {/*<div className=" flex justify-center items-center">*/}
                                        {/*    <input type="checkbox" className=" w-5 h-5 ml-8"*/}
                                        {/*           onChange={(e) => setHrsDentergemLateNight(prev => ({*/}
                                        {/*               ...prev, [key]: {...prev[key], opened: e.target.checked}*/}
                                        {/*           }))} checked={el.opened}/>*/}
                                        {/*</div>*/}
                                    </div>)}
                            </fieldset>
                        </div>
                    </div>
                    <div className=" ml-16 mx-auto bg-slate-50 py-6">

                        <div className="flex justify-between items-center mb-8">
                            <fieldset className=" w-48 border border-slate-300 px-8 rounded-md">
                                <legend className=" px-2 whitespace-nowrap font-bold">Openingsuren Late Night Deinze
                                </legend>
                                {Object.entries(hrsDeinzeLateNight).map(([key, el]) =>
                                    <div key={key} className="flex flex-row justify-center items-center my-5">
                                        <div className="flex flex-row items-center">
                                            <label className=" mr-16 w-24">{el.day} </label>
                                            <input type="time"
                                                   min="08:00" max="23:00" className=" bg-slate-200 px-5 py-1 rounded-md" onChange={(e) => setHrsDeinzeLateNight(prev => ({
                                                ...prev,
                                                [key]: { ...prev[key], opens: e.target.value }
                                            }))} defaultValue={el.opens} />
                                            <span className=" mx-5"> tot</span>
                                            <input type="time"
                                                   min="08:00" max="23:00"
                                                   className=" bg-slate-200 px-5 py-1 rounded-md"
                                                   onChange={(e) => setHrsDeinzeLateNight(prev => ({
                                                       ...prev,
                                                       [key]: {...prev[key], closes: e.target.value}
                                                   }))} defaultValue={el.closes}/>
                                        </div>
                                        {/*<div className=" flex justify-center items-center">*/}
                                        {/*    <input type="checkbox" className=" w-5 h-5 ml-8"*/}
                                        {/*           onChange={(e) => setHrsDeinzeLateNight(prev => ({*/}
                                        {/*               ...prev, [key]: {...prev[key], opened: e.target.checked}*/}
                                        {/*           }))} checked={el.opened}/>*/}
                                        {/*</div>*/}
                                    </div>)}
                            </fieldset>
                        </div>
                    </div>
                </div>


                <div className=" flex flex-col">
                    {settings && <div className="container bg-slate-50 py-6 px-16">
                        <div className="flex items-center mb-8 text-gray-800">
                            <CiDiscount1 className=" text-2xl"/>
                            <p className="text-sm font-bold ml-2 text-gray-800">Beheer groepskorting</p>
                        </div>
                        <div className="flex justify-between items-center mb-8">
                            <fieldset className="border border-slate-300 px-8 pb-4 pt-2 rounded-md">
                                <legend className=" px-2 whitespace-nowrap font-bold">Groepskortingen</legend>
                                {/*<div className="flex flex-row items-center py-8">*/}
                                {/*    {Object.values(discountKayak).map((el, key) =>*/}
                                {/*        <div key={key} className={` ${el.color} ${activeK == key ? "bg-indigo-600 text-white" : ""} w-44 bg-blue-300 mx-4 px-7 py-3 rounded-md cursor-pointer hover:bg-opacity-60 transition-all font-bold text-center`} onClick={() => setactiveK(key)}>*/}
                                {/*            {console.log(key, el)}*/}
                                {/*            <span className="block font-normal">{el.type} </span> {el.amount}*/}
                                {/*        </div>*/}
                                {/*    )}*/}
                                {/*</div>*/}
                                {/*<div className="flex flex-row items-center py-8">*/}
                                {/*    {Object.values(discountSup).map((el, key) =>*/}
                                {/*        <div key={key} className={` ${el.color} ${activeS == key ? "bg-indigo-600 text-white" : ""} w-44 bg-blue-300 mx-4 px-7 py-3 rounded-md cursor-pointer hover:bg-opacity-60 transition-all font-bold text-center`} onClick={() => setActiveS(key)}>*/}
                                {/*            <span className="block font-normal">{el.type} </span> {el.amount}*/}
                                {/*        </div>*/}
                                {/*    )}*/}
                                {/*</div>*/}
                                {/*<div className="flex flex-row items-center py-8">*/}
                                {/*    {Object.values(discountSupIsland).map((el, key) =>*/}
                                {/*        <div key={key} className={` ${el.color} ${activeSI == key ? "bg-indigo-600 text-white" : ""} w-44 bg-blue-300 mx-4 px-7 py-3 rounded-md cursor-pointer hover:bg-opacity-60 transition-all font-bold text-center`} onClick={() => setActiveSI(key)}>*/}
                                {/*            <span className="block font-normal">{el.type} </span> {el.amount}*/}
                                {/*        </div>*/}
                                {/*    )}*/}
                                {/*</div>*/}
                                {/*<div className="flex flex-row items-center py-8">*/}
                                {/*    {Object.values(discountLesson).map((el, key) =>*/}
                                {/*        <div key={key} className={` ${el.color} ${activeL == key ? "bg-indigo-600 text-white" : ""} w-44 bg-blue-300 mx-4 px-7 py-3 rounded-md cursor-pointer hover:bg-opacity-60 transition-all font-bold text-center`} onClick={() => setActiveL(key)}>*/}
                                {/*            <span className="block font-normal">{el.type} </span> {el.amount}*/}
                                {/*        </div>*/}
                                {/*    )}*/}
                                {/*</div>*/}

                                <div className="flex justify-between">
                                    <p>Vanaf 10 personen</p>
                                    <input type="number" value={settings.group_discount_10}
                                           onChange={(e) => setSettings({
                                               ...settings,
                                               group_discount_10: e.target.value
                                           })} className="ml-4 mr-1 text-end"/>
                                    <span>%</span>
                                </div>
                                <div className="flex justify-between my-4">
                                    <p>Vanaf 25 personen</p>
                                    <input type="number" value={settings.group_discount_25}
                                           onChange={(e) => setSettings({
                                               ...settings,
                                               group_discount_25: e.target.value
                                           })} className="ml-4 mr-1 text-end"/>
                                    <span>%</span>
                                </div>
                                <div className="flex justify-between">
                                    <p>Vanaf 50 personen</p>
                                    <input type="number" value={settings.group_discount_50}
                                           onChange={(e) => setSettings({
                                               ...settings,
                                               group_discount_50: e.target.value
                                           })} className="ml-4 mr-1 text-end"/>
                                    <span>%</span>
                                </div>

                            </fieldset>
                        </div>
                    </div>}

                    {settings && <div className="container bg-slate-50 py-6 px-16">
                        <div className="flex justify-between items-center mb-8">
                            <fieldset className="border border-slate-300 px-8 pb-4 pt-2 rounded-md">
                                <legend className=" px-2 whitespace-nowrap font-bold">Algemene instellingen</legend>

                                <div className="flex justify-between">
                                    <p>Tijdsslot interval</p>
                                    <input type="number" value={settings.interval}
                                           onChange={(e) => setSettings({
                                               ...settings,
                                               interval: e.target.value
                                           })} className="ml-4 mr-1 text-end"/>
                                    <span>min</span>
                                </div>

                            </fieldset>
                        </div>
                    </div>}

                    <div
                        className="w-full py-4 sm:px-12 px-4 bg-slate-200 dark:bg-gray-700 flex justify-end rounded-bl rounded-br">
                        <button onClick={() => saveChanges()}
                                className="bg-cyan-500 focus:outline-none transition duration-150 ease-in-out hover:bg-cyan-600 rounded text-white px-8 py-2 text-sm">Save
                        </button>
                    </div>
                </div>
                {/* <div className="container mx-auto bg-slate-50 py-6 px-16">
                        <div className="flex items-center mb-8 text-gray-800">
                            <CiDiscount1 className=" text-2xl" />
                            <p className="text-sm font-bold ml-2 text-gray-800">Beheer groepskorting</p>
                        </div>
                        <div className="flex justify-between items-center mb-8">
                            <fieldset className=" w-48 border border-slate-300 px-8 rounded-md">
                                <legend className=" px-2 whitespace-nowrap font-bold">Groepskortingen</legend>
                                <div className="flex flex-row items-center py-8">
                                    {discount.map((el, key) =>
                                        <div key={key} className={` bg-blue-50 mx-4 px-7 py-3 rounded-md cursor-pointer hover:bg-opacity-60 transition-all font-bold text-center`} onClick={() => setDiscountView(true)} >
                                            <span className=" font-normal">Suppen </span> {el}%
                                        </div>
                                    )}
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <div className="w-full py-4 sm:px-12 px-4 bg-slate-200 dark:bg-gray-700 flex justify-end rounded-bl rounded-br">
                        <button className="bg-cyan-500 focus:outline-none transition duration-150 ease-in-out hover:bg-cyan-600 rounded text-white px-8 py-2 text-sm" type="button" onClick={saveChanges}>Save</button>
                    </div> */}
            </div>

            {/* <div className={`${discountView ? "flex" : "hidden"} w-screen h-screen fixed z-50 inset-0 bg-opacity-70 bg-black flex justify-center items-center`}>
                <div className="px-7 py-5 flex flex-col items-center bg-white rounded-md text-gray-600 ">
                    <h4 className=" text-xl mb-2 font-medium ">Kortingscode</h4>
                    <p className=" font-bold text-3xl">juh72gS0hshs</p>
                    <button className="text-cyan-500 bg-slate-50 hover:text-white border border-cyan-500 rounded-md px-4 py-1 bg-transparent hover:bg-cyan-500 bg transition-all mt-6" onClick={() => setDiscountView(false)}>Sluiten</button>
                </div>
            </div> */}
        </div>
    );
};
export default Discounts;
