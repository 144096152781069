import { useLocation } from "react-router";
import { useEffect } from "react";

const AuthAgent = () => {

    console.log(('test'))

    const location = useLocation();

    useEffect(() => {
        console.log('AuthAgent')
        const at = localStorage.getItem('__nn_at__')
        const e = localStorage.getItem('__nn_e__')
        if (!at && window.location.pathname !== "/signin") {
            if (window.location.pathname !== "/") localStorage.setItem('__nn_rf__', window.location.pathname)
            window.location = "signin"
        } else if (e) {
            const expiry = new Date(parseInt(e))
            console.log(expiry)
        }
    }, [location])

    useEffect(() => {
        if (!localStorage.getItem('__nn_l__')) localStorage.setItem('__nn_l__', 'deinze')
    }, [])

    return ''

}
export default AuthAgent