import React, { useContext, useEffect, useState } from "react";
import { redirect, useLocation } from "react-router";

const SiteContext = React.createContext();

const SiteProvider = (props) => {

    const [editForm, setEditForm] = useState(false);
    const [createForm, setCreateForm] = useState(false);
    const [tmpData, setTmpData] = useState(false);
    const [search, setSearch] = useState('');
    const [warning, setWarning] = useState(false);
    const [continueDelete, setContinueDelete] = useState(false);
    const [deletePending, setDeletePending] = useState(false);
    const [warningMessage, setWarningMessage] = useState();

    useEffect(() => {
        // console.log(search)
    }, [search]);

    return (
        <SiteContext.Provider
            value={{ editForm, setEditForm, tmpData, setTmpData, createForm, setCreateForm, search, setSearch, warning, setWarning, continueDelete, setContinueDelete, deletePending, setDeletePending, warningMessage, setWarningMessage }}>
            {props.children}
        </SiteContext.Provider >
    )
}

const SiteConsumer = SiteContext.Consumer;

export { SiteConsumer, SiteContext }
export default SiteProvider