import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/main.scss'
import './index.css';
import App from './App';
import 'tw-elements';
import {
  createBrowserRouter, RouterProvider, Routes, Route, Router, BrowserRouter
} from "react-router-dom";
import SiteProvider, { SiteContext } from "./contexts/SiteContext";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <SiteProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </SiteProvider>
  </>
);
