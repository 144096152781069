import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {Bar} from 'react-chartjs-2';
import faker from 'faker';

const BarChart = ({lastWeek}) => {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    console.log(lastWeek)

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Bestellingen per maand',
            },
            scales: {
                y: {
                    min: 0,
                    ticks: {
                        stepSize: 1
                    },
                    grid: {
                        display: true
                    },
                    suggestedMax: lastWeek.suggestedMax
                },
                x: {
                    grid: {
                        display: true
                    }
                }
            }
        },
    };

    const data = {
        datasets: [
            {
                label: 'Vorig jaar',
                data: lastWeek.countsLastYear,
                backgroundColor: 'rgba(77, 182, 172, 0.5)',
            },
            {
                label: 'Laatste 12 maanden',
                data: lastWeek.counts,
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            }
        ],
    };

    return <Bar options={options} data={data}/>;
}
export default BarChart