import React, { useState } from "react";
import axios from "axios";
import Logo from "../assets/images/logo/Logo-IBgroup.png";

const Auth = () => {

    const [formData, setFormData] = useState({})
    const [at, setAt] = useState(false)
    const [message, setMessage] = useState(false)

    const signIn = async () => {
        axios.post(process.env.REACT_APP_BASE_URL + '/api/auth/signin', formData).then(r => {
            if (r.data && r.data.user && r.data.user.stsTokenManager) {
                const accessToken = r.data.user.stsTokenManager.accessToken
                const expire = r.data.user.stsTokenManager.expirationTime
                const userData = r.data.userData

                console.log(r)
                console.log(expire)
                console.log(userData)


                localStorage.setItem('__nn_at__', accessToken)
                localStorage.setItem('__nn_e__', expire)
                localStorage.setItem('__nn_u__', userData.email)
                localStorage.setItem('__nn_ud__', JSON.stringify(userData))

                if (localStorage.getItem('__nn_rf__')) {
                    const rf = localStorage.getItem('__nn_rf__')
                    localStorage.removeItem('__nn_rf__')
                    window.location = rf
                } else {
                    window.location = '/orders'
                }
            } else {
                const error = r.data.code
                console.log(error)
                console.log(r)
                const errorMessages = {
                    "auth/missing-email": "Gelieve een correct e-mailadres in te vullen",
                    "auth/invalid-email": "Gelieve een correct e-mailadres in te vullen",
                    "auth/wrong-password": "E-mailadres en/of wachtwoord is onjuist",
                    "auth/internal-error": "Gelieve een wachtwoord in te vullen",
                }

                setMessage(errorMessages[error])
                console.log(errorMessages[error])
            }
        })
    }

    const resetPassword = async () => {
        if(formData.email){
            axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/reset-password`, {email: formData.email}).then(r => {
                console.log(r)
                setMessage(r.data)
            }).catch(err => console.log(err))
        }else{
            setMessage('Gelieve een geldig e-mailadres in te voeren.')
        }
    }

    return (
        <div className="flex justify-center absolute top-1/4 lg:w-1/2 w-full xl:left-1/4">
            <div className="flex flex-col xl:w-1/3">
                <img src={Logo} alt="logo" className="pr-3 pl-1 mb-16 w-48 mx-auto" />
                <p className="text-red-500">{message}</p>
                <input type="email" placeholder="e-mailadres..."
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })} value={formData.email}
                    className="my-4 p-2 pl-3 rounded-sm" />
                <input type="password" placeholder="wachtwoord..."
                    onChange={(e) => setFormData({ ...formData, password: e.target.value })} value={formData.password}
                    className="my-4 p-2 pl-3 rounded-sm" />
                <button onClick={() => signIn()}
                    className="my-4 p-2 mr-3 focus:outline-none transition duration-150 ease-in-out rounded hover:bg-cyan-500 hover:text-white text-cyan-500 border-cyan-500 border px-5 py-2 text-md">Log
                    in
                </button>
                <button onClick={() => resetPassword()}
                    className="my-4 p-2 mr-3 focus:outline-none transition duration-150 ease-in-out rounded hover:bg-cyan-500 hover:text-white text-cyan-500 border-cyan-500 border px-5 py-2 text-md">Wachtwoord vergeten
                </button>
            </div>
        </div>
    )
}
export default Auth