import React, { useEffect, useState, useContext } from "react";
import axios from 'axios';
import Form from "../components/Form";
import { SiteContext } from "../contexts/SiteContext"
import Select from "react-select";
import _ from "lodash";
import { AiOutlineFieldTime } from "react-icons/ai";
import DeletePopup from "../components/DeletePopup";

const Services = () => {
    const [services, setServices] = useState(false)
    const [options, setOptions] = useState(false)
    const [categories, setCategories] = useState(false)
    const [vessels, setVessels] = useState(false)
    const [serviceObjs, setServiceObjs] = useState([]);
    const contextStorage = useContext(SiteContext)
    const [createForm, setCreateForm] = useState();
    const [itemIndex, setItemIndex] = useState();
    const [activeService, setActiveService] = useState(false);
    const [formData, setFormData] = useState({});
    const [file, setFile] = useState(false);
    const [itemID, setItemID] = useState();
    const [inProgress, setInProgress] = useState(false);

    // Messages
    const [addFormSaveButton, setAddFormSaveButton] = useState('Toevoegen')

    const handleCreateService = () => {
        setCreateForm(true)
        setInProgress(false)
    }

    const [hrsGent, setHrsGent] = useState({
        monday: { index: 0, day: "Maandag", opens: "09:00", closes: "20:00", opened: false },
        tuesday: { index: 1, day: "Dinsdag", opens: "09:00", closes: "20:00", opened: true },
        wednesday: { index: 2, day: "Woensdag", opens: "09:00", closes: "20:00", opened: true },
        thursday: { index: 3, day: "Donderdag", opens: "09:00", closes: "20:00", opened: true },
        friday: { index: 4, day: "Vrijdag", opens: "09:00", closes: "20:00", opened: true },
        saturday: { index: 5, day: "Zaterdag", opens: "09:00", closes: "20:00", opened: true },
        sunday: { index: 6, day: "Zondag", opens: "09:00", closes: "20:00", opened: false }
    })

    const [hrsDeinze, setHrsDeinze] = useState({
        monday: { index: 0, day: "Maandag", opens: "09:00", closes: "20:00", opened: false },
        tuesday: { index: 1, day: "Dinsdag", opens: "09:00", closes: "20:00", opened: true },
        wednesday: { index: 2, day: "Woensdag", opens: "09:00", closes: "20:00", opened: true },
        thursday: { index: 3, day: "Donderdag", opens: "09:00", closes: "20:00", opened: true },
        friday: { index: 4, day: "Vrijdag", opens: "09:00", closes: "20:00", opened: true },
        saturday: { index: 5, day: "Zaterdag", opens: "09:00", closes: "20:00", opened: true },
        sunday: { index: 6, day: "Zondag", opens: "09:00", closes: "20:00", opened: false }
    })


    const getServices = async (init) => {

        // Preloader
        let cache = {}
        if (init && localStorage.getItem('preload_services')) {
            cache = JSON.parse(localStorage.getItem('preload_services'))
            setServices(cache)
            console.log(cache)
        }
        // END Preloader


        if (!categories || !vessels) {
            // Fetch categories and vessels (probably don't need vessels anymore)
            const cats = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/categories`)
            const vess = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/vessels`)
            setCategories(cats.data)
            setVessels(vess.data)
        }

        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/services`)
        res.data.sort((a, b) => (a.categorie > b.categorie) ? 1 : ((b.categorie > a.categorie) ? -1 : 0))
        console.log(res.data)

        if (res.data && !_.isEqual(cache, res.data)) {
            console.log('CACHE NOT EQUAL')
            setServices(res.data)
        }
        localStorage.setItem('preload_services', JSON.stringify(res.data))
    }

    const getOptions = async () => {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/options`)
        const filtered = res.data.filter(option => !option.soft_deleted)
        setOptions(filtered)
    }

    useEffect(() => {
        getServices(true)
        getOptions()
    }, []);

    // useEffect(() => {
    //     services && services.map(order => setServiceObjs(serviceObjs => [...serviceObjs, order]))
    // }, [services]);

    const handleClick = (item) => {
        contextStorage.setEditForm(true)
        setFormData(item)
        console.log(item)
        // setActiveService(item)
    }

    useEffect(() => {
        console.log('UPDATE')
        console.log(contextStorage.tmpData)
        if (contextStorage.tmpData) {
            console.log(contextStorage.tmpData)
            console.log('RAN')

            let config = {
                'content-type': 'multipart/form-data'
            }
            // if(typeof contextStorage.tmpData.img === "object") {
            //     console.log(contextStorage.tmpData.img.type)
            //     console.log(contextStorage.tmpData.img.size)
            //     config = {
            //         'content-type': contextStorage.tmpData.img.type,
            //         'content-length': `${contextStorage.tmpData.img.size}`
            //     }
            // }
            console.log(contextStorage.tmpData)
            console.log(config)

            const fd = new FormData()
            Object.entries(contextStorage.tmpData).forEach(([key, value]) => {
                if(typeof value === "object" && key !== 'img'){
                    fd.append(key, JSON.stringify(value))
                }else{
                    fd.append(key, value)
                }
            })

            axios.post(process.env.REACT_APP_BASE_URL + "/api/services", fd, config).then(res => {
                console.log(res)
                contextStorage.setTmpData(false)
                contextStorage.setEditForm(false)
                setActiveService(false)
                getServices()
            }).catch(err => console.log(err))
        } else {
            console.log('UPDATE DID NOT RUN: contextStorage.tmpData == ' + contextStorage.tmpData)
        }
    }, [contextStorage.tmpData]);

    useEffect(() => {
        // setActiveService(serviceObjs[itemIndex])
        console.log(formData)
    }, [formData]);

    const categoryName = (catId) => {
        const cat = categories.find(c => c.id === catId)
        return cat ? cat.name : ''
    }

    const handleSave = async () => {
        setInProgress(true)
        console.log('CREATE')
        const fd = new FormData()
        fd.append('name', formData.name || '')
        fd.append('img', formData.img)
        fd.append('sort', formData.sort)
        fd.append('categorie', formData.categorie)
        fd.append('duration', formData.duration || '')
        fd.append('priceAdult', formData.priceAdult || '')
        fd.append('priceChild', formData.priceChild || '')
        fd.append('group_price', formData.group_price || '')
        // fd.append('minPeopleForGroup', formData.minPeopleForGroup)
        fd.append('interval', formData.interval || 15)
        fd.append('opens', formData.opens)
        fd.append('closes', formData.closes)
        fd.append('vessel', JSON.stringify(formData.vessel))
        fd.append('days', JSON.stringify(formData.days))
        fd.append('locations', JSON.stringify(formData.locations))
        fd.append('options', formData.options ? JSON.stringify(formData.options) : false)
        fd.append('latenight', formData.latenight)
        fd.append('price_per_vessel', formData.price_per_vessel || 0)
        fd.append('usr', localStorage.getItem('__nn_u__'))

        console.log(formData)
        setAddFormSaveButton('Even geduld...')
        axios.post(process.env.REACT_APP_BASE_URL + '/api/services/add', fd, {
            // 'content-type': 'multipart/form-data'
            'content-type': file.type,
            'content-length': `${file.size}`
        }).then(r => {
            getServices()
            setAddFormSaveButton('Toevoegen')
            setCreateForm(false)
        }).catch(e => {
            console.log(e)
        })
    }

    const handleImageUpload = (e) => {
        console.log(e.target.files)
        setFile(e.target.files[0])
        setFormData({ ...formData, img: e.target.files[0] })
    }

    const categorySelect = (e) => {
        console.log(e)
        setFormData({ ...formData, categorie: e.value })
    }

    const optionsSelect = (e) => {
        console.log(e)
        setFormData({ ...formData, options: e })
    }

    const vesselSelect = (redundantType, e) => {
        console.log(e)
        setFormData({ ...formData, vessel: e })
    }

    // const handleDelete = (id) => {
    //     axios.get(process.env.REACT_APP_BASE_URL + '/api/services/delete/' + id).then(r => getServices())
    // }

    const handleDelete = (id) => {
        setItemID(id)
        contextStorage.setWarning(true)
    }


    useEffect(() => {
        contextStorage.continueDelete ?
            axios.get(`${process.env.REACT_APP_BASE_URL}/api/services/delete/${itemID}/${localStorage.getItem('__nn_u__')}`)
                .then(r => {
                    getServices()
                    contextStorage.setDeletePending(true)
                    setTimeout(() => {
                        contextStorage.setWarning(false)
                        contextStorage.setDeletePending(false)
                        contextStorage.setContinueDelete(false)
                    }, 1000);
                })
                .catch(e => {
                    contextStorage.setDeletePending(true)
                    contextStorage.setWarningMessage("Verwijderen lukt niet")
                    setTimeout(() => {
                        contextStorage.setDeletePending(false)
                        contextStorage.setContinueDelete(false)
                    }, 1000);
                })
            : contextStorage.setDeletePending(false)
    }, [contextStorage.continueDelete]);

    const handleTimeRangeService = (field, value) => setFormData({ ...formData, [field]: value })

    const setAvailableIn = (location, state) => {
        const copy = _.cloneDeep(formData)
        const locations = copy.locations || {dentergem: false, deinze: false}
        locations[location] = state
        copy.locations = locations
        setFormData(copy)
    }

    return (
        <div>
            <div
                className="mx-auto flex flex-col md:flex-row items-start md:items-center justify-between py-10 mb-2 bg-slate-200 rounded-md px-6">
                <h4 className="text-3xl font-bold leading-tight text-gray-800 ">Overzicht van alle diensten</h4>
                <div className="mt-6 md:mt-0">
                    {/*<button className="mr-3 focus:outline-none transition duration-150 ease-in-out rounded hover:bg-cyan-500 hover:text-white text-cyan-500 border-cyan-500 border px-5 py-2 text-md">Back</button>*/}
                    <button
                        className="transition focus:outline-none duration-150 ease-in-out border border-cyan-500 bg-cyan-500 rounded text-white px-8 py-2 text-md hover:text-cyan-500 hover:bg-gray-100"
                        onClick={handleCreateService}>Nieuwe dienst aanmaken
                    </button>
                </div>
            </div>

            {/*{formData && <div className="fixed left-0 bottom-0 bg-white">*/}
            {/*    <pre>*/}
            {/*        {JSON.stringify(formData, null, 2)}*/}
            {/*    </pre>*/}
            {/*</div>}*/}

            <table className="w-full text-left bg-slate-50 rounded-md overflow-hidden py-24">
                <thead className="bg-slate-200 w-full text-gray-800 rounded-tr-md rounded-tl-md overflow-hidden">
                    <tr className=" mb-3 border-b border-slate-200">
                        <th className=" py-5 px-5 text-left">Image</th>
                        <th className=" py-5 px-5 text-left">Naam</th>
                        <th className=" py-5 px-5 text-left">Tijdsduur</th>
                        <th className=" py-5 px-5 text-left">Order interval</th>
                        <th className=" py-5 px-5 text-left">Categorie</th>
                        <th className=" py-5 px-5 text-left">Prijs volwassene</th>
                        <th className=" py-5 px-5 text-left">Prijs kind</th>
                        {/*<th className=" py-5 px-5 text-left">Prijs groep</th>*/}
                        <th className=" py-5 px-5 text-left"></th>
                    </tr>
                </thead>
                <tbody>
                    {services && services.length == 0 || services == undefined ?
                        <h2 className=" pl-10 text-4xl py-12 w-full whitespace-nowrap">Geen diensten</h2>
                        : categories && services && services.map((el, index) =>
                            <tr key={index} className="capitalize border-b border-slate-200">
                                <td className="pt-4 px-5 pb-5 text-xs sm:text-sm"><img src={el.img}
                                    className=" w-20 h-20 rounded-md"
                                    alt="" /></td>
                                <td className="pt-4 px-5 pb-5 text-xs sm:text-sm">{el.name}</td>
                                <td className="pt-4 px-5 pb-5 text-xs sm:text-sm">{el.duration} min</td>
                                <td className="pt-4 px-5 pb-5 text-xs sm:text-sm">{el.interval} min</td>
                                <td className="pt-4 px-5 pb-5 text-gray-800 text-xs sm:text-sm">{categoryName(el.categorie)}</td>
                                <td className="pt-4 px-5 pb-5 text-gray-800 text-xs sm:text-sm">{el.priceAdult ? `€ ${el.priceAdult}` : 'eenheidsprijs'}</td>
                                <td className="pt-4 px-5 pb-5 text-gray-800 text-xs sm:text-sm">{el.priceChild ? `€ ${el.priceChild}` : el.priceAdult ? `€ ${el.priceAdult}` : 'eenheidsprijs'}</td>
                                {/*<td className="pt-4 px-5 pb-5 text-gray-800 text-xs sm:text-sm">€ {el.priceGroup} </td>*/}
                                <td className="px-5 py-3">
                                    <button
                                        className="text-cyan-500 bg-slate-50 hover:text-white border border-cyan-500 rounded-md px-4 py-1 hover:bg-cyan-500 bg transition-all"
                                        onClick={() => handleClick(el)}>Aanpassen
                                    </button>
                                    &nbsp;
                                    <button
                                        className="text-red-500 bg-slate-50 hover:text-white border border-red-500 rounded-md px-4 py-1 hover:bg-red-500 bg transition-all"
                                        onClick={() => handleDelete(el.id)}>Verwijderen
                                    </button>
                                </td>
                            </tr>)}
                </tbody>
            </table>
            {formData && categories && vessels && options && <Form data={formData} clear={() => setFormData(false)} customFields={[{
                type: 'file',
                name: 'img',
                label: 'Afbeelding'
            }, { type: 'select-multi', name: 'vessel', label: 'Vaartuig', options: vessels }, {
                type: 'select-string',
                name: 'categorie',
                options: categories
            }, { type: 'select-multi', name: 'options', options: options }, {
                type: 'timerangeservice',
                name: 'openingsuren'
            }, { type: 'days', name: 'openingsdagen' }, {
                type: 'toggle',
                name: 'group_price',
                label: 'Groepsprijs'
            }, { type: 'number', name: 'price_per_vessel', label: 'Prijs per artikel/voertuig' }, {
                type: 'number',
                name: 'max_cap',
                label: 'Max. capaciteit (indien geen vaartuig)'
            }, {
                type: 'locations',
                name: 'locations',
                label: 'Beschikbaar in'
            }]} />}
            <div
                className={` ${createForm ? "fixed" : "hidden "} fixed h-screen w-screen inset-0 flex flex-col justify-center items-center bg-black bg-opacity-70 z-50`}>
                <div
                    className="dynamic-width pb-0 bg-white min-w-[50vw] max-h-[80vh] overflow-y-scroll rounded-tl-md rounded-tr-md block">
                    <div className="flex flex-col items-center w-full pt-7">
                        <h4 className="text-3xl font-normal text-cyan-500">Nieuwe dienst aanmaken</h4>
                    </div>
                    <div className="grid grid-cols-2 gap-x-10 gap-y-2 py-10 px-10">

                        <div className="mb-4 mx-2">

                            <div>
                                <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                    Naam
                                </label>
                            </div>
                            <div className="">
                                <input
                                    className="bg-gray-100 appearance-none border-2 border-gray-100 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                    id="inline-full-name" type="text"
                                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                    value={formData.name || ""} />
                            </div>
                        </div>

                        <div className="mb-4 mx-2">
                            <div>
                                <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                    Afbeelding
                                </label>
                            </div>
                            <div
                                className="bg-gray-100 appearance-none border-2 border-gray-100 rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500">
                                <input type="file" accept="image/*" onChange={handleImageUpload} />
                            </div>
                        </div>

                        {categories && <div>
                            <div>
                                <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                    Categorie
                                </label>
                            </div>
                            <div
                                className="bg-gray-100 appearance-none border-2 border-gray-100 rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500">
                                <Select
                                    onChange={(selectedOption) => categorySelect(selectedOption)}
                                    options={categories.map(option => {
                                        return { value: option.id, label: option.name }
                                    })} />
                            </div>
                        </div>}

                        {vessels && <div>
                            <div>
                                <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                    Vaartuig
                                </label>
                            </div>
                            <div
                                className="bg-gray-100 appearance-none border-2 border-gray-100 rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500">
                                {/*<Select*/}
                                {/*    onChange={(selectedOption) => vesselSelect(selectedOption)}*/}
                                {/*    options={vessels.map(option => {*/}
                                {/*        return {value: option.id, label: option.name}*/}
                                {/*    })}/>*/}
                                <Select
                                    isMulti
                                    onChange={(selectedOptions) => vesselSelect('vessel', selectedOptions)}
                                    options={vessels.map(option => {
                                        return { value: option.id, label: option.name }
                                    })} />
                            </div>
                        </div>}

                        {options && <div>
                            <div>
                                <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                    Opties
                                </label>
                            </div>
                            <div
                                className="bg-gray-100 appearance-none border-2 border-gray-100 rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500">
                                <Select isMulti
                                    onChange={(selectedOption) => optionsSelect(selectedOption)}
                                    options={options.map(option => {
                                        return { value: option.id, label: option.name }
                                    })} />
                            </div>
                        </div>}

                        <div className="mb-4 mx-2">

                            <div>
                                <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                    Duurtijd (minuten)
                                </label>
                            </div>
                            <div className="">
                                <input
                                    className="bg-gray-100 appearance-none border-2 border-gray-100 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                    id="inline-full-name" type="number"
                                    onChange={(e) => setFormData({ ...formData, duration: e.target.value })}
                                    value={formData.duration || ""} />
                            </div>
                        </div>

                        <div className="mb-4 mx-2">

                            <div>
                                <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                    Max. capaciteit (indien geen vaartuig)
                                </label>
                            </div>
                            <div className="">
                                <input
                                    className="bg-gray-100 appearance-none border-2 border-gray-100 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                    id="inline-full-name" type="number"
                                    onChange={(e) => setFormData({ ...formData, max_cap: e.target.value })}
                                    value={formData.max_cap || ""} />
                            </div>
                        </div>

                        <div className="mb-4 mx-2">

                            <div>
                                <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                    Prijs per voertuig
                                </label>
                            </div>
                            <div className="">
                                <input
                                    className="bg-gray-100 appearance-none border-2 border-gray-100 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                    id="inline-full-name" type="number"
                                    onChange={(e) => setFormData({ ...formData, price_per_vessel: e.target.value })}
                                    value={formData.price_per_vessel || ""} />
                            </div>
                        </div>

                        <div className="mb-4 mx-2">

                            <div>
                                <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                    Min. aantal minuten voor start
                                </label>
                            </div>
                            <div className="">
                                <input
                                    className="bg-gray-100 appearance-none border-2 border-gray-100 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                    id="inline-full-name" type="number"
                                    onChange={(e) => setFormData({ ...formData, interval: e.target.value })}
                                    value={formData.interval || ""} />
                            </div>
                        </div>

                        <div className="mb-4 mx-2">

                            <div>
                                <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                    Prijs volwassene
                                </label>
                            </div>
                            <div className="">
                                <input
                                    className="bg-gray-100 appearance-none border-2 border-gray-100 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                    id="inline-full-name" type="number"
                                    onChange={(e) => setFormData({ ...formData, priceAdult: e.target.value })}
                                    value={formData.priceAdult || ""} />
                            </div>
                        </div>

                        <div className="mb-4 mx-2">

                            <div>
                                <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                    Prijs kind
                                </label>
                            </div>
                            <div className="">
                                <input
                                    className="bg-gray-100 appearance-none border-2 border-gray-100 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                    id="inline-full-name" type="number"
                                    onChange={(e) => setFormData({ ...formData, priceChild: e.target.value })}
                                    value={formData.priceChild || ""} />
                            </div>
                        </div>

                        <div className="mb-4 mx-2">

                            <div>
                                <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                    Groepskorting
                                </label>
                            </div>
                            <div className="">
                                <input className="" id="inline-full-name" type="checkbox"
                                    onChange={(e) => setFormData({ ...formData, group_price: e.target.checked })} />

                            </div>
                        </div>

                        <div className="mb-4 mx-2">

                            <div>
                                <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                    Beschikbaar in
                                </label>
                            </div>
                            <div className="flex">
                                <label className="flex flex-col items-center mr-4">
                                    Dentergem
                                    <input className="" id="inline-full-name" type="checkbox"
                                           onChange={(e) => setAvailableIn('dentergem', e.target.checked)} />
                                </label>
                                <label className="flex flex-col items-center">
                                    Deinze
                                <input className="" id="inline-full-name" type="checkbox"
                                       onChange={(e) => setAvailableIn('deinze', e.target.checked)} />
                                </label>
                            </div>
                        </div>

                        <div className="mb-4 mx-2">

                            <div>
                                <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                    Openingsuren
                                </label>
                            </div>
                            <div className="">
                                <div className="flex flex-row items-center">
                                    <input type="time"
                                        min="08:00" max="23:00" className=" bg-slate-200 px-5 py-1 rounded-md"
                                        onChange={(e) => handleTimeRangeService('opens', e.target.value)}
                                        defaultValue={'15:00'} />
                                    <span className=" mx-5">tot</span>
                                    <input type="time"
                                        min="08:00" max="23:00" className=" bg-slate-200 px-5 py-1 rounded-md"
                                        onChange={(e) => handleTimeRangeService('closes', e.target.value)}
                                        defaultValue={'16:00'} />
                                    <div className="flex">
                                        <label className="mx-2">Late Night</label>
                                        <input type="checkbox" className=" bg-slate-200 px-5 py-1 rounded-md"
                                            onChange={(e) => setFormData({
                                                ...formData,
                                                latenight: e.target.checked
                                            })} />

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mb-4 mx-2">

                            <div>
                                <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                    Openingsdagen
                                </label>
                            </div>
                            <div className="">
                                <div className="flex flex-row items-center justify-between">
                                    <div className="flex flex-col"><label>Ma</label><input type="checkbox" onChange={(e) => setFormData(prevStyle => ({ ...prevStyle, days: { ...prevStyle.days, monday: e.target.checked } }))} /></div>
                                    <div className="flex flex-col"><label>Di</label><input type="checkbox" onChange={(e) => setFormData(prevStyle => ({ ...prevStyle, days: { ...prevStyle.days, tuesday: e.target.checked } }))} /></div>
                                    <div className="flex flex-col"><label>Woe</label><input type="checkbox" onChange={(e) => setFormData(prevStyle => ({ ...prevStyle, days: { ...prevStyle.days, wednesday: e.target.checked } }))} /></div>
                                    <div className="flex flex-col"><label>Do</label><input type="checkbox" onChange={(e) => setFormData(prevStyle => ({ ...prevStyle, days: { ...prevStyle.days, thursday: e.target.checked } }))} /></div>
                                    <div className="flex flex-col"><label>Vrij</label><input type="checkbox" onChange={(e) => setFormData(prevStyle => ({ ...prevStyle, days: { ...prevStyle.days, friday: e.target.checked } }))} /></div>
                                    <div className="flex flex-col"><label>Za</label><input type="checkbox" onChange={(e) => setFormData(prevStyle => ({ ...prevStyle, days: { ...prevStyle.days, saturday: e.target.checked } }))} /></div>
                                    <div className="flex flex-col"><label>Zo</label><input type="checkbox" onChange={(e) => setFormData(prevStyle => ({ ...prevStyle, days: { ...prevStyle.days, sunday: e.target.checked } }))} /></div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className={`bg-slate-200 py-6 flex justify-end pr-5 rounded-br-md rounded-bl-md`}>
                        <button
                            className={`bg-cyan-500 focus:outline-none transition duration-150 ease-in-out hover:bg-cyan-600 rounded text-white px-8 py-2 text-sm mr-4 ${inProgress ? " bg-red-300 pointer-events-none" : "pointer-events-auto"}`}
                            onClick={() => handleSave()}>{addFormSaveButton}
                        </button>
                        <button
                            className="text-cyan-500 bg-slate-50 hover:text-white border border-cyan-500 rounded-md px-4 py-1 bg-transparent hover:bg-cyan-500 bg transition-all"
                            onClick={() => setCreateForm(false)}>Sluiten
                        </button>
                    </div>
                </div>
            </div>
            <DeletePopup />
        </div>
    )
}
export default Services