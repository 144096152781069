import React, {useEffect, useState, useContext} from "react";
import {SiteContext} from "../contexts/SiteContext"
import Select from "react-select";
import {format} from 'date-fns'
import DatePicker from "react-datepicker";
import _ from "lodash";

const Form = ({data, customFields, buttonText, clear}) => {

    const [calculatedWidth, setCalculatedWidth] = useState(false);
    const [elOpacity, setElOpacity] = useState(0);
    const [formData, setFormData] = useState(false);
    const contextStorage = useContext(SiteContext)

    const labelMap = {
        consumer: 'Klant',
        duration: 'Duurtijd',
        service: 'Categorie',
        serviceDetail: 'Activiteit',
        status: 'Status',
        interval: 'Min. aantal minuten voor start',
        // priceGroup: 'Prijs groep',
        priceAdult: 'Prijs volwassene',
        priceChild: 'Prijs kind',
        minPeopleForGroup: 'Min. personen voor groepsprijs',
        capacity: 'Aantal personen',
        inventory_gent: 'Inventaris Gent',
        inventory_deinze: 'Inventaris Deinze',
        name: 'Naam',
        price: 'Prijs',
        redirect: 'Doorverwijs-url',
        beschrijving: 'Korte beschrijving',
        sort: 'Volgorde',
        code: 'code',
        label: 'Naam (intern)',
        percentage: 'Korting (in %)'
    }

    const roles = [{
        label: "Administrator",
        value: 'administrator',
    }, {
        label: "Werknemer",
        value: 'werknemer'
    }]

    useEffect(() => {
        // console.log('data', data)
        // console.log('customFields', customFields)
        // console.log('DATA CHANGED')

        // if(formData && formData.validFrom){
        //     console.log(formData.validFrom)
        //     console.log(new Date(formData.validFrom))
        //     console.log(format(new Date(formData.validFrom), 'dd-MM-yyyy'))
        //     console.log(format(new Date(formData.validFrom), 'yyyy-MM-dd'))
        // }

        data && setFormData(data)
    }, [data]);


    useEffect(() => {
        console.log('Form FormData', formData)
    }, [formData])
    // console.log(customFields)
    // console.log(data)

    useEffect(() => {
        if (contextStorage.editForm) {
            setCalculatedWidth(true)
            setTimeout(() => {
                setCalculatedWidth(document.querySelector('.dynamic-width').offsetWidth)
                setElOpacity(100)
            }, 300);
        }
    }, [contextStorage.editForm]);

    const handleSave = () => {
        // console.log(formData)
        const payload = formData
        if (data.id) payload.id = data.id
        if (localStorage.getItem('__nn_u__')) payload.usr = localStorage.getItem('__nn_u__')


        console.log('payload', payload)
        contextStorage.setTmpData(payload)
        setFormData(false)
        if (clear) {
            clear()
        } else {
            console.log('No clear()-function was plugged in.')
        }
    }

    const selectUpdate = (option, field) => {
        setFormData({...formData, [field]: option.value})
    }

    const multiSelect = (field, e) => {
        setFormData({...formData, [field]: e})
    }

    const handleTimeRangeService = (field, value) => setFormData({...formData, [field]: value})

    const handleToggle = (field, e) => {
        // console.log(field, e.target.checked)
        setFormData({...formData, [field]: e.target.checked})
    }

    const closeForm = () => {
        contextStorage.setEditForm(false)
        setFormData(false)
        if (clear) {
            clear()
        } else {
            console.log('No clear()-function was plugged in.')
        }
    }

    const getSelectValueFromCategory = (options, category) => {
        const result = options.find(o => o.id === category)
        if (!result) return
        // console.log({value: result.id, label: result.name})
        return {value: result.id, label: result.name}
    }

    const getRole = (role) => {
        const result = roles.find(o => o.value === role)
        if (!result) return
        return result
    }

    const setAvailableIn = (location, state) => {
        const copy = _.cloneDeep(formData)
        const locations = copy.locations || {dentergem: false, deinze: false}
        locations[location] = state
        copy.locations = locations
        setFormData(copy)
    }

    return (

        <div
            className={`${contextStorage.editForm ? 'fixed' : "hidden"} h-screen w-screen inset-0 flex flex-col justify-center items-center bg-black bg-opacity-70 z-50`}>
            <div className="min-w-[50vw]">
                <div className="pb-0 bg-white w-full max-h-[80vh] overflow-y-scroll rounded-tl-md rounded-tr-md block">
                    <div className="flex flex-col items-center w-full pt-7">
                        <h4 className="text-3xl font-normal leading-tight text-cyan-500">Gegevens bijwerken</h4>
                    </div>
                    <div className="grid grid-cols-1 xl:grid-cols-2 gap-x-10 gap-y-2 py-10 px-10">
                        {formData && customFields && customFields.length > 0 && customFields.map((field, key) => (field.type === 'validateVoucher' && !formData.voucher) ? 'condition' : (
                            <div key={key} className="mb-4 mx-2">
                                <div>
                                    <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                        {field.label || field.name}
                                    </label>
                                </div>
                                <div className="">
                                    {field.type === 'select' && console.log(field)}
                                    {field.type === 'select' && (
                                        <Select
                                            onChange={(selectedOption) => selectUpdate(selectedOption, field.name)}
                                            // defaultValue={field.options.find(o => o.value === data.categorie)}
                                            options={field.options.map((option, key) => {
                                                return {value: option.id, label: option.name}
                                            })}
                                        />
                                    )}
                                    {field.type === 'select-role' && (
                                        <Select
                                            onChange={(selectedOption) => selectUpdate(selectedOption, field.name)}
                                            value={getRole(formData.role)}
                                            options={roles}
                                        />
                                    )}
                                    {field.type === 'select-string' && (
                                        <Select
                                            onChange={(selectedOption) => selectUpdate(selectedOption, field.name)}
                                            value={getSelectValueFromCategory(field.options, formData.categorie)}
                                            options={field.options.map((option, key) => {
                                                return {value: option.id, label: option.name}
                                            })}
                                        />
                                    )}
                                    {field.type === 'select-multi' && (
                                        <Select
                                            isMulti
                                            // defaultValue={{ label: data.vessel.map(el => el.label), value: data.vessel.map(el => el.value) }}
                                            onChange={(selectedOptions) => multiSelect(field.name, selectedOptions)}
                                            value={formData[field.name]}
                                            options={field.options.map(option => {
                                                return {value: option.id, label: option.name}
                                            })}/>
                                    )}
                                    {field.type === 'file' && (
                                        <input
                                            className="bg-gray-100 appearance-none border-2 border-gray-100 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                            id="inline-full-name" type="file" accept="image/*"
                                            onChange={(e) => setFormData({...formData, img: e.target.files[0]})}/>
                                    )}
                                    {field.type === 'toggle' && (
                                        <input
                                            // className="bg-gray-100 appearance-none border-2 border-gray-100 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                            id="inline-full-name" type="checkbox" className="mt-6"
                                            checked={formData[field.name] === true || formData[field.name] === "true"}
                                            onChange={(e) => handleToggle(field.name, e)}/>
                                    )}
                                    {field.type === 'timerange' && (
                                        <div className="flex flex-row items-center">
                                            <input type="time"
                                                   min="08:00" max="23:00"
                                                   className=" bg-slate-200 px-5 py-1 rounded-md"
                                                   onChange={(e) => handleTimeRangeService('opens', e.target.value)}
                                                   defaultValue={'15:00'}/>
                                            <span className=" mx-5">tot</span>
                                            <input type="time"
                                                   min="08:00" max="23:00"
                                                   className=" bg-slate-200 px-5 py-1 rounded-md"
                                                   onChange={(e) => handleTimeRangeService('closes', e.target.value)}
                                                   defaultValue={'16:00'}/>
                                        </div>
                                    )}
                                    {field.type === 'datepicker' && (
                                        <>
                                            <p>{console.log(formData[field.name])}</p>
                                            <DatePicker dateFormat="dd-MM-yyyy"
                                                        selected={new Date(formData[field.name])}
                                                        onChange={(date) => setFormData({
                                                            ...formData,
                                                            [field.name]: date
                                                        })}/>
                                        </>
                                    )}
                                    {field.type === 'number' && (
                                        <input
                                            className="bg-gray-100 appearance-none border-2 border-gray-100 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                            id="inline-full-name" type="number"
                                            onChange={(e) => setFormData({...formData, [field.name]: e.target.value})}
                                            value={formData[field.name]}/>
                                    )}
                                    {field.type === 'text' && (
                                        <input
                                            className="bg-gray-100 appearance-none border-2 border-gray-100 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                            id="inline-full-name" type="text"
                                            onChange={(e) => setFormData({...formData, [field.name]: e.target.value})}
                                            value={formData[field.name]}/>
                                    )}
                                    {field.type === 'validateVoucher' && formData.voucher && (
                                        <div className="flex justify-between">
                                            <button
                                                className={formData.payment.status === 'afgekeurd' ? "bg-cyan-500 focus:outline-none transition duration-150 ease-in-out hover:bg-cyan-600 rounded text-white px-8 py-2 text-sm mr-4" : "text-cyan-500 bg-slate-50 hover:text-white border border-cyan-500 rounded-md px-4 py-1 bg-transparent hover:bg-cyan-500 bg transition-all mr-4"}
                                                onClick={() => setFormData(prevStyle => ({
                                                    ...prevStyle,
                                                    payment: {...prevStyle.payment, status: 'afgekeurd'}
                                                }))}>Afwijzen
                                            </button>
                                            <button
                                                className={formData.payment.status === 'goedgekeurd' ? "bg-cyan-500 focus:outline-none transition duration-150 ease-in-out hover:bg-cyan-600 rounded text-white px-8 py-2 text-sm" : "text-cyan-500 bg-slate-50 hover:text-white border border-cyan-500 rounded-md px-4 py-1 bg-transparent hover:bg-cyan-500 bg transition-all"}
                                                onClick={() => setFormData(prevStyle => ({
                                                    ...prevStyle,
                                                    payment: {...prevStyle.payment, status: 'goedgekeurd'}
                                                }))}>Goedkeuren
                                            </button>
                                            {formData.voucher && <button
                                                className="bg-cyan-500 focus:outline-none transition duration-150 ease-in-out hover:bg-cyan-600 rounded text-white px-8 py-2 text-sm ml-4"
                                                onClick={() => window.open(formData.voucher, '_blank')}>Bekijken</button>}
                                        </div>
                                    )}
                                    {formData && field.type == 'timerangeservice' && (
                                        <div className="mb-4 mx-2">
                                            <div className="">
                                                <div className="flex flex-row items-center">
                                                    {console.log(formData)}
                                                    <input type="time"
                                                           min="08:00" max="23:00"
                                                           className=" bg-slate-200 px-5 py-1 rounded-md"
                                                           onChange={(e) => handleTimeRangeService('opens', e.target.value)}
                                                           defaultValue={formData.opens}/>
                                                    <span className=" mx-5">tot</span>
                                                    <input type="time"
                                                           min="08:00" max="23:00"
                                                           className=" bg-slate-200 px-5 py-1 rounded-md"
                                                           onChange={(e) => handleTimeRangeService('closes', e.target.value)}
                                                           defaultValue={formData.closes}/>
                                                    <div className="flex">
                                                        <label className="mx-2">Late Night</label>
                                                        <input type="checkbox"
                                                               checked={formData.latenight && formData.latenight !== false && formData.latenight !== "false" && formData.latenight !== "undefined"}
                                                               className=" bg-slate-200 px-5 py-1 rounded-md"
                                                               onChange={(e) => setFormData({
                                                                   ...formData,
                                                                   latenight: e.target.checked
                                                               })}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)}
                                    {field.type == 'days' && (
                                        <div className="mb-4 mx-2">
                                            <div className="">
                                                <div className="flex flex-row items-center justify-between">
                                                    <div className="flex flex-col"><label>Ma</label><input
                                                        type="checkbox"
                                                        checked={(formData.days && formData.days['monday']) ? formData.days['monday'] : false}
                                                        onChange={(e) => setFormData(prevStyle => ({
                                                            ...prevStyle,
                                                            days: {...prevStyle.days, monday: e.target.checked}
                                                        }))}/></div>
                                                    <div className="flex flex-col"><label>Di</label><input
                                                        type="checkbox"
                                                        checked={(formData.days && formData.days['tuesday']) ? formData.days['tuesday'] : false}
                                                        onChange={(e) => setFormData(prevStyle => ({
                                                            ...prevStyle,
                                                            days: {...prevStyle.days, tuesday: e.target.checked}
                                                        }))}/></div>
                                                    <div className="flex flex-col"><label>Woe</label><input
                                                        type="checkbox"
                                                        checked={(formData.days && formData.days['wednesday']) ? formData.days['wednesday'] : false}
                                                        onChange={(e) => setFormData(prevStyle => ({
                                                            ...prevStyle,
                                                            days: {...prevStyle.days, wednesday: e.target.checked}
                                                        }))}/></div>
                                                    <div className="flex flex-col"><label>Do</label><input
                                                        type="checkbox"
                                                        checked={(formData.days && formData.days['thursday']) ? formData.days['thursday'] : false}
                                                        onChange={(e) => setFormData(prevStyle => ({
                                                            ...prevStyle,
                                                            days: {...prevStyle.days, thursday: e.target.checked}
                                                        }))}/></div>
                                                    <div className="flex flex-col"><label>Vrij</label><input
                                                        type="checkbox"
                                                        checked={(formData.days && formData.days['friday']) ? formData.days['friday'] : false}
                                                        onChange={(e) => setFormData(prevStyle => ({
                                                            ...prevStyle,
                                                            days: {...prevStyle.days, friday: e.target.checked}
                                                        }))}/></div>
                                                    <div className="flex flex-col"><label>Za</label><input
                                                        type="checkbox"
                                                        checked={(formData.days && formData.days['saturday']) ? formData.days['saturday'] : false}
                                                        onChange={(e) => setFormData(prevStyle => ({
                                                            ...prevStyle,
                                                            days: {...prevStyle.days, saturday: e.target.checked}
                                                        }))}/></div>
                                                    <div className="flex flex-col"><label>Zo</label><input
                                                        type="checkbox"
                                                        checked={(formData.days && formData.days['sunday']) ? formData.days['sunday'] : false}
                                                        onChange={(e) => setFormData(prevStyle => ({
                                                            ...prevStyle,
                                                            days: {...prevStyle.days, sunday: e.target.checked}
                                                        }))}/></div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {field.type == 'locations' &&
                                    <div className="mb-4 mx-2">
                                        <div className="flex">
                                            <label className="flex flex-col items-center mr-4">
                                                Dentergem
                                                <input className="" id="inline-full-name" type="checkbox"
                                                       checked={formData.locations && formData.locations.dentergem}
                                                       onChange={(e) => setAvailableIn('dentergem', e.target.checked)}/>
                                            </label>
                                            <label className="flex flex-col items-center">
                                                Deinze
                                                <input className="" id="inline-full-name" type="checkbox"
                                                       checked={formData.locations && formData.locations.deinze}
                                                       onChange={(e) => setAvailableIn('deinze', e.target.checked)}/>
                                            </label>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        ))}

                        {data && Object.entries(data).map(([key, value]) =>
                            // (!key.startsWith("_") && key !== "id" && key !== "img" &&
                            (labelMap[key] &&
                                (
                                    <div key={key} className="mb-4 mx-2">
                                        <div>
                                            <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                                {labelMap[key]}
                                            </label>
                                        </div>
                                        <div className="">
                                            <input
                                                className="bg-gray-100 appearance-none border-2 border-gray-100 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                                id="inline-full-name" type="text" value={formData[key]}
                                                onChange={(e) => setFormData({...formData, [key]: e.target.value})}/>
                                        </div>
                                    </div>
                                )))}

                        {/*payment*/}
                        {data && data.payment && Object.entries(data.payment).map(([key, value]) =>
                            // (!key.startsWith("_") && key !== "id" && key !== "img" &&
                            (labelMap[key] &&
                                (
                                    <div key={key} className="mb-4 mx-2">
                                        <div>
                                            <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                                {labelMap[key]}
                                            </label>
                                        </div>
                                        <div className="">
                                            <input
                                                className="bg-gray-100 appearance-none border-2 border-gray-100 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                                id="inline-full-name" type="text" placeholder={value}
                                                onChange={(e) => setFormData(prevStyle => ({
                                                    ...prevStyle,
                                                    payment: {...prevStyle.payment, status: e.target.value}
                                                }))}/>
                                        </div>
                                    </div>
                                )))}
                    </div>
                </div>
                {/* {calculatedWidth ? */}
                <div className={`bg-slate-200 py-6 flex justify-end pr-5 rounded-br-md rounded-bl-md w-full`}
                    // style={{ width: calculatedWidth + "px", opacity: elOpacity }}
                >
                    <button
                        className="bg-cyan-500 focus:outline-none transition duration-150 ease-in-out hover:bg-cyan-600 rounded text-white px-8 py-2 text-sm mr-4"
                        onClick={() => handleSave()}>{buttonText || 'Opslaan'}
                    </button>
                    <button
                        className="text-cyan-500 bg-slate-50 hover:text-white border border-cyan-500 rounded-md px-4 py-1 bg-transparent hover:bg-cyan-500 bg transition-all"
                        onClick={() => closeForm()}>Sluiten
                    </button>
                </div>
                {/* : ""} */}
            </div>
        </div>
    )
}

export default Form