import React, {useContext, useEffect, useState} from "react";
import axios from 'axios';
import {SiteContext} from "../contexts/SiteContext";
import _ from "lodash";

const Services = () => {
    const contextStorage = useContext(SiteContext)
    const [customers, setCustomers] = useState(false)
    const [serviceObjs, setServiceObjs] = useState([]);
    const [editService, setEditService] = useState(false);
    const [itemIndex, setItemIndex] = useState();
    const [activeService, setActiveService] = useState();
    const colors = ['red', 'orange', 'amber', 'yellow', 'green', 'emerald', 'teal', 'cyan', 'sky', 'blue', 'indigo', 'rose', 'pink', 'purple', 'violet']


    const getCustomers = async () => {
        // Preloader
        let cache = {}
        if (localStorage.getItem('preload_customers')) {
            cache = JSON.parse(localStorage.getItem('preload_customers'))
            setCustomers(cache)
        }
        // END Preloader

        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/customers`)
        res.data.sort((a, b) => b.created_at - a.created_at)

        if (!_.isEqual(cache, res.data)) setCustomers(res.data)
        console.log(res.data)

        res.data.forEach(cus => console.log(cus.firstname || 'ERROR', cus))

        localStorage.setItem('preload_customers', JSON.stringify(res.data))
    }

    function getRandom(colors) {
        return colors[Math.floor((Math.random() * colors.length))];
    }

    useEffect(() => {
        console.log('customers page !!!!')
        getCustomers()
    }, []);

    useEffect(() => {
        customers && customers.map(order => setServiceObjs(serviceObjs => [...serviceObjs, order]))
    }, [customers]);

    useEffect(() => {
        console.log(customers)
    }, [customers]);

    const handleClick = (index) => {
        setEditService(true)
        setItemIndex(index)
    }

    useEffect(() => {
        setActiveService(serviceObjs[itemIndex])
    }, [itemIndex]);

    useEffect(() => {
        if(customers && contextStorage.search) {
            const tmpCustomers = customers
            const filteredOrders = tmpCustomers.filter(customer => ((customer.firstname || '')+(customer.lastname || '')).toLowerCase().includes(contextStorage.search.toLowerCase()))
            setCustomers(filteredOrders)
        }else{
            const cache = JSON.parse(localStorage.getItem('preload_customers'))
            setCustomers(cache)
        }
    }, [contextStorage.search]);

    return (
        <div className=" mx-auto overflow-x-auto">
            <div className="mx-auto flex flex-col md:flex-row items-start md:items-center justify-between py-10 mb-2 bg-slate-200 rounded-md px-6">
                <h4 className="text-3xl font-bold leading-tight text-gray-800">Klanten</h4>
                <div className="mt-6 md:mt-0">
                    {/*<button className="mr-3 focus:outline-none transition duration-150 ease-in-out rounded hover:bg-cyan-500 hover:text-white text-cyan-500 border-cyan-500 border px-5 py-2 text-md">Back</button>*/}
                    {/*<button className="transition focus:outline-none duration-150 ease-in-out border border-cyan-500 bg-cyan-500 rounded text-white px-8 py-2 text-md hover:text-cyan-500 hover:bg-gray-100">Klant aanmaken</button>*/}
                </div>
            </div>
            <table className="w-full shadow text-left bg-slate-50 rounded-md overflow-hidden">
                <thead className="bg-slate-200 w-full text-gray-800 rounded-tr-md rounded-tl-md overflow-hidden">
                    <tr className="border-b border-slate-200">
                        <th className="py-5 pl-2 sm:pl-5 text-base text-gray-800">#</th>
                        <th className="py-5 pl-2 sm:pl-10 w-1/6 text-base">Voornaam</th>
                        <th className="py-5 w-1/6 text-base">Achternaam</th>
                        <th className="py-5 w-1/6 text-base">Email</th>
                        <th className="py-5 w-1/6 text-base">Telefoon</th>
                        <th className="py-5 w-1/6 text-base">Geboortedatum</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {customers && customers.length == 0 || customers == undefined ?
                        <h2 className=" pl-10 text-4xl py-12 w-full whitespace-nowrap">Klanten worden opgehaald</h2>
                        : customers && customers.map((el, index) =>
                            <tr className="border-b border-slate-200">
                                <td className="pl-2 sm:pl-5 pr-2 py-4">
                                    <div className={`h-12 w-12 mb-4 lg:mb-0 mr-4 bg-${colors[Math.floor(Math.random() * colors.length)]}-500 bg-green-500 flex justify-center items-center rounded-full`}>
                                        {el.firstname && el.lastname && <p className="text-gray-100 font-bold tracking-wide">{el.firstname.charAt(0) + el.lastname.charAt(0)} </p>}
                                    </div>
                                </td>
                                <td className="pl-2 sm:pl-10 pr-2 py-4 w-1/6">{el.firstname} </td>
                                <td className="pt-4 pb-5 text-gray-800 text-base w-1/6">{el.lastname} </td>
                                <td className="pt-4 pb-5 text-gray-800 text-base w-1/6">{el.email} </td>
                                <td className="pt-4 pb-5 text-gray-800 text-base w-1/6">{el.phone} </td>
                                <td className="pt-4 pb-5 text-gray-800 text-base w-1/6">{el.date_of_birth} </td>
                                <td className=" px-5 py-3"><a href={`/customers/${el.id}`} className="text-cyan-500 hover:text-white border border-cyan-500 rounded-md px-4 py-1 hover:bg-cyan-500 bg transition-all" onClick={() => handleClick(index)}>Bestellingen</a></td>
                            </tr>
                        )}
                </tbody>
            </table>
        </div>
    )
}
export default Services
