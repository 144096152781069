import React, {useEffect, useState, useRef, useContext} from "react";
import {useParams} from "react-router-dom";
import axios from 'axios';
import {SiteContext} from "../contexts/SiteContext"
import Form from "../components/Form";
import {format} from 'date-fns'
import Select from "react-select";
import _ from "lodash";

const CustomerDetail = (props) => {
    const contextStorage = useContext(SiteContext)
    const [customer, setCustomer] = useState(false)
    const [orders, setOrders] = useState(false)
    const [activeOrder, setActiveOrder] = useState(false);
    const [formData, setFormData] = useState({});
    const [changes, setChanges] = useState(false);

    let {id} = useParams();

    const getCustomer = async () => {
        console.log(id)
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/customers/${id}`)
        setCustomer(res.data)

        const ordersRes = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/general-orders/customer/${id}`)
        console.log(ordersRes.data)
        setOrders(ordersRes.data)
    }

    const statuses = [{
        label: "In afwachting",
        value: 'in afwachting',
    }, {
        label: "Betaald",
        value: 'betaald'
    }, {
        label: "Afgekeurd (voucher)",
        value: 'afgekeurd'
    }, {
        label: "Betaling mislukt",
        value: 'betaling mislukt'
    }, {
        label: "Te controleren (voucher)",
        value: 'te controleren'
    }, {
        label: "Terugbetaald",
        value: 'terugbetaald'
    }]

    const validateVoucher = async (status, id) => {
        axios.post(process.env.REACT_APP_BASE_URL + '/api/general-orders/update/'+id, {"payment.status": status}, {
            // 'content-type': 'multipart/form-data'
            'content-type': 'application/json',
        }).then(r => {
            getCustomer()
            setActiveOrder(false)
        }).catch(e => console.log(e))
    }

    // useEffect(() => {
    //     console.log(contextStorage.tmpData)
    //     axios.post(`${process.env.REACT_APP_BASE_URL}/api/customers/${id}`, contextStorage.tmpData).then(res => {
    //         console.log(res)
    //         contextStorage.setTmpData({})
    //     }).catch(err => console.log(err))
    // }, [contextStorage.tmpData]);

    useEffect(() => {
        getCustomer()
    }, []);

    const handleClick = (index, id, el) => {
        // contextStorage.setEditForm(true)
        setFormData({ ...formData, id })
        setActiveOrder(el)
        // setItemIndex(index)
    }

    const updateStatus = async (id, status) => {
        console.log(`Changing order "${id}"'s status to: ${status.value}`)
        const payload = _.cloneDeep(activeOrder)
        payload.payment.status = status.value
        setChanges(payload)
    }

    const saveChangedStatus = async () => {
        axios.post(process.env.REACT_APP_BASE_URL+'/api/general-orders/update/'+changes.id, changes).then(r => {
            setChanges(false)
            setActiveOrder(false)
            getCustomer()
        })
    }

    return (
        <div>
            <div className="mx-auto overflow-x-auto">
                <div
                    className="mx-auto flex flex-col md:flex-row items-start md:items-center justify-between mb-2 rounded-md pr-6">
                    <h4 className="text-3xl font-bold leading-tight text-gray-800 mt-16">{customer?.firstname + ' ' + customer?.lastname} </h4>
                    <div className="mt-6 md:mt-0">
                        {/*<button*/}
                        {/*    className="mr-3 focus:outline-none transition duration-150 ease-in-out rounded hover:bg-cyan-500 hover:text-white text-cyan-500 border-cyan-500 border px-5 py-2 text-md">Back*/}
                        {/*</button>*/}
                        {/*<button*/}
                        {/*    className="transition focus:outline-none duration-150 ease-in-out border border-cyan-500 bg-cyan-500 rounded text-white px-8 py-2 text-md hover:text-cyan-500 hover:bg-gray-100">Klant*/}
                        {/*    aanmaken*/}
                        {/*</button>*/}
                    </div>
                </div>
                <table className="w-full shadow text-left bg-slate-50 rounded-lg py-24">
                    <thead className="bg-slate-200 w-full">
                    <tr className="border-b border-slate-200">
                        <th className="py-5 pl-2 sm:pl-5 text-base text-gray-800"></th>
                        <th className="py-5 pl-2 sm:pl-10 w-1/6 text-base text-gray-800">Voornaam</th>
                        <th className="py-5 w-1/6 text-base text-gray-800">Achternaam</th>
                        <th className="py-5 w-1/6 text-base text-gray-800">Email</th>
                        <th className="py-5 w-1/6 text-base text-gray-800">Telefoon</th>
                        <th className="py-5 w-1/6 text-base text-gray-800">Adres</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    <tr className=" border-b bg-slate-50">
                        <td className="pl-2 sm:pl-5 pr-2 py-4">
                            <div
                                className="h-12 w-12 mb-4 lg:mb-0 mr-4 bg-purple-500 flex justify-center items-center rounded-full">
                                <p className="text-gray-100 font-bold tracking-wide">BD</p>
                            </div>
                        </td>
                        <td className="pl-2 sm:pl-10 pr-2 py-4 w-1/6">{customer?.firstname} </td>
                        <td className="pt-4 pb-5 text-gray-800 text-base w-1/6">{customer?.lastname} </td>
                        <td className="pt-4 pb-5 text-gray-800 text-base w-1/6">{customer?.email} </td>
                        <td className="pt-4 pb-5 text-gray-800 text-base w-1/6">{customer?.phone} </td>
                        <td className="pt-4 pb-5 text-gray-800 text-base w-1/6">{customer?.street} {customer?.number}, {customer?.postal} {customer?.city}</td>
                        <td className=" px-5 py-3">
                            {/*<button*/}
                            {/*    className="text-cyan-500 hover:text-white border border-cyan-500 rounded-md px-4 py-1 hover:bg-cyan-500 bg transition-all"*/}
                            {/*    onClick={() => contextStorage.setEditForm(true)}>Klant bijwerken*/}
                            {/*</button>*/}
                        </td>
                    </tr>
                    </tbody>
                </table>
                {/*<Form data={customer} />*/}


                <div
                    className="mx-auto flex flex-col md:flex-row items-start md:items-center justify-between mb-2 rounded-md pr-6">
                    <h4 className="text-3xl font-bold leading-tight text-gray-800 mt-16">Laatste bestellingen</h4>
                </div>
                <table className="w-full shadow text-left bg-slate-50 rounded-lg py-24">
                    <thead className="bg-slate-200 w-full">
                    <tr className="border-b border-slate-200">
                        <th className="py-5 pl-2 sm:pl-10 w-1/6 text-base text-gray-800">Bestelnummer</th>
                        <th className="py-5 w-1/6 text-base  text-gray-800">Datum</th>
                        {/*<th className="py-5 w-1/6 text-base  text-gray-800">Werknemer</th>*/}
                        <th className="py-5 w-1/6 text-base  text-gray-800">Klant</th>
                        {/*<th className="py-5 w-1/6 text-base  text-gray-800">Activiteit</th>*/}
                        {/*<th className="py-5 w-1/6 text-base  text-gray-800">Tijdsduur</th>*/}
                        <th className="py-5 w-1/6 text-base  text-gray-800">Kortingscode</th>
                        <th className="py-5 w-1/6 text-base  text-gray-800">Betaling</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {orders && orders.map((el, key) => (
                        <tr className="border-b">
                            <td className="pl-2 sm:pl-10 pr-2 py-4 w-1/6">{el.id}</td>
                            <td className="pt-4 pb-5 w-1/6">
                                <div className=" flex flex-col">
                                    <div>
                                        {/*<p className=" text-gray-800 pb-1 capitalize">{new Date(el.date).toISOString().split('T')[0]} {el.time}</p>*/}
                                        <p className=" text-gray-800 pb-1 capitalize">{format(new Date(el.created_at), "dd-MM-yyyy HH:mm")}</p>
                                    </div>
                                </div>
                            </td>
                            {/*<td className="pt-4 pb-5 w-1/6">{el.employee ? el.employee : 'Online'}</td>*/}
                            <td className="pr-2 py-4 w-1/6">
                                <div className="flex items-center">
                                    <div className="">
                                        <p className=" text-gray-800 mb-1">{el.full_customer.firstname} {el.full_customer.lastname}</p>
                                        <a href={`/customers/${el.full_customer.email}`}
                                           className="text-xs mb-0.5 text-cyan-500 hover:underline block">{el.full_customer.email}</a>
                                        {/* <a href={`/customers/${el.customer}`} className="text-xs mb-0.5 text-cyan-500 hover:underline block">+32 496 47 46 66</a> */}
                                    </div>
                                </div>
                            </td>
                            {/*<td className="pt-4 pb-5 text-gray-800 w-1/6">{el.serviceDetail}</td>*/}
                            {/*<td className="pt-4 pb-5 text-gray-800 w-1/6">{el.duration} Min</td>*/}
                            {/*<td className="pt-4 pb-5 text-gray-800 w-1/6">{el.payment.status || 'onbekend'} {el.id}</td>*/}
                            <td className="pt-4 pb-5 w-1/6">{el.coupon ? (`${el.coupon.coupon.code} (€ ${el.coupon.discountAmount})`) : 'Geen'}</td>
                            <td className="pt-4 pb-5 text-gray-800 w-1/6">€ {(el.coupon ? (parseFloat(el.payment.amount) - parseFloat(el.coupon.discountAmount)) : el.payment.amount.toFixed(2)) || ''} | {el.payment.method || ''} |
                                <span
                                    onClick={() => el.payment.status === 'te controleren' && window.open(el.voucher, '_blank')}
                                    className={` capitalize font-medium ${el.payment.status === 'te controleren' && 'cursor-pointer'} ${el.payment.status == 'betaald' || el.payment.status == 'goedgekeurd' ? ' text-green-500' : el.payment.status == 'in afwachting' ? ' text-yellow-500' : ' text-red-600'}`}> {el.payment.status} </span>
                            </td>
                            {/*<td className="pl-2 sm:pl-10 pr-2 py-4 w-1/6">*/}
                            {/*    <div className=" flex flex-col">*/}
                            {/*        <div>*/}
                            {/*            <p className=" text-gray-800 pb-1 capitalize">{format(new Date(order.created_at), "dd-MM-yyyy")} {format(new Date(order.created_at), "HH:mm")}</p>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</td>*/}
                            {/*<td className="pt-4 pb-5 w-1/6">{order.employee ? order.employee : 'Online'}</td>*/}
                            {/*<td className="pr-2 py-4 w-1/6">*/}
                            {/*    <div className="flex items-center">*/}
                            {/*        <div className="">*/}
                            {/*            <p className=" text-gray-800 mb-1">{customer.firstname} {customer.lastname}</p>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</td>*/}
                            {/*<td className="pt-4 pb-5 text-gray-800 w-1/6">{order.serviceDetail}</td>*/}
                            {/*<td className="pt-4 pb-5 text-gray-800 w-1/6">{order.duration} min</td>*/}
                            {/*<td className="pt-4 pb-5 text-gray-800 w-1/6">€ {order.payment.amount} | {order.payment.method} |*/}
                            {/*    <span*/}
                            {/*        className={` capitalize font-medium ${order.payment.status === 'te controleren' && 'cursor-pointer'} ${order.payment.status == 'betaald' || order.payment.status == 'goedgekeurd' ? ' text-green-500' : order.payment.status == 'in afwachting' ? ' text-yellow-500' : ' text-red-600'}`}> {order.payment.status} </span>*/}
                            {/*</td>*/}
                            <td className="flex items-center px-5 py-3">
                                <button
                                    className="transition focus:outline-none duration-150 ease-in-out border border-cyan-500 bg-cyan-500 rounded text-white px-8 py-2 text-md hover:text-cyan-500 hover:bg-gray-100 mr-4"
                                    onClick={() => handleClick(key, el.id, el)}>Detail
                                </button>
                                <button
                                    className="text-cyan-500 hover:text-white border border-cyan-500 rounded-md px-4 py-1 hover:bg-cyan-500 bg transition-all whitespace-nowrap"
                                    onClick={() => window.location = '/orders/' + el.id}>Bekijk reservaties
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                {activeOrder && (
                    <div
                        className={`${activeOrder && activeOrder.full_customer ? 'fixed' : "hidden"} h-screen w-screen inset-0 flex flex-col justify-center items-center bg-black bg-opacity-70 z-50`}>
                        <div className="min-w-[50vw]">
                            <div className="pb-0 bg-white w-full max-h-[80vh] overflow-y-scroll rounded-tl-md rounded-tr-md block">
                                <div className="flex flex-col items-center w-full pt-7">
                                    <h4 className="text-3xl font-normal leading-tight text-cyan-500">Order detail</h4>
                                </div>
                                <div className="grid grid-cols-1 xl:grid-cols-2 gap-x-10 gap-y-2 py-10 px-10">
                                    {/*{activeOrder && activeOrder.full_customer && Object.entries(activeOrder.full_customer).map(el =>*/}
                                    {/*    <div className="mb-4 mx-2">*/}
                                    {/*        <div>*/}
                                    {/*            <label className="block text-gray-500 font-bold mb-1.5 capitalize">*/}
                                    {/*                {el[0]}*/}
                                    {/*            </label>*/}
                                    {/*        </div>*/}
                                    {/*        <p>{el[1]} </p>*/}
                                    {/*    </div>*/}
                                    {/*)}*/}
                                    <div className="mb-4 mx-2">
                                        <div>
                                            <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                                Aangemaakt op
                                            </label>
                                        </div>
                                        <p>{format(new Date(activeOrder.created_at), 'dd-MM-yyyy HH:mm')}</p>
                                    </div>

                                    <div className="mb-4 mx-2">
                                        <div>
                                            <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                                Naam
                                            </label>
                                        </div>
                                        <a href={`/customers/${activeOrder.full_customer.email}`}>{activeOrder.full_customer.firstname + ' ' + activeOrder.full_customer.lastname}</a>
                                    </div>

                                    <div className="mb-4 mx-2">
                                        <div>
                                            <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                                Adres
                                            </label>
                                        </div>
                                        <p>{`${activeOrder.full_customer.street} ${activeOrder.full_customer.number}, ${activeOrder.full_customer.postal} ${activeOrder.full_customer.city}`}</p>
                                    </div>

                                    <div className="mb-4 mx-2">
                                        <div>
                                            <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                                Bestelnummer
                                            </label>
                                        </div>
                                        <p>{activeOrder.id}</p>
                                    </div>

                                    <div className="mb-4 mx-2">
                                        <div>
                                            <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                                Status
                                            </label>
                                        </div>
                                        <div className="mb-4 mr-2">
                                            <Select
                                                onChange={(selectedOption) => updateStatus(activeOrder.id, selectedOption)}
                                                options={statuses}
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-4 mx-2">
                                        <div>
                                            <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                                Totaal
                                            </label>
                                        </div>
                                        <div className="mb-4 mr-2">
                                            <p>€ {activeOrder.total}</p>
                                        </div>
                                    </div>
                                    <div className="mb-4 mx-2">
                                        <div>
                                            <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                                Totaal
                                            </label>
                                        </div>
                                        <div className="mb-4 mr-2">
                                            <p>{activeOrder.coupon ? (`${activeOrder.coupon.coupon.code} (€ ${activeOrder.coupon.discountAmount})`) : 'Geen'}</p>
                                        </div>
                                    </div>

                                    {activeOrder.payment.method === 'Voucher' && (
                                        <div className="mb-4 mx-2">
                                            <div>
                                                <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                                    Voucher valideren ({activeOrder.payment.status})
                                                </label>
                                            </div>
                                            <div className="flex justify-between">
                                                <button
                                                    className={activeOrder.payment.status === 'afgekeurd' ? "bg-cyan-500 focus:outline-none transition duration-150 ease-in-out hover:bg-cyan-600 rounded text-white px-8 py-2 text-sm mr-4" : "text-cyan-500 bg-slate-50 hover:text-white border border-cyan-500 rounded-md px-4 py-1 bg-transparent hover:bg-cyan-500 bg transition-all mr-4"}
                                                    onClick={() => validateVoucher('afgekeurd', activeOrder.id)}>Afwijzen
                                                </button>
                                                <button
                                                    className={activeOrder.payment.status === 'goedgekeurd' ? "bg-cyan-500 focus:outline-none transition duration-150 ease-in-out hover:bg-cyan-600 rounded text-white px-8 py-2 text-sm" : "text-cyan-500 bg-slate-50 hover:text-white border border-cyan-500 rounded-md px-4 py-1 bg-transparent hover:bg-cyan-500 bg transition-all"}
                                                    onClick={() => validateVoucher('goedgekeurd', activeOrder.id)}>Goedkeuren
                                                </button>
                                                {activeOrder.voucher && <button
                                                    className="bg-cyan-500 focus:outline-none transition duration-150 ease-in-out hover:bg-cyan-600 rounded text-white px-8 py-2 text-sm ml-4"
                                                    onClick={() => window.open(activeOrder.voucher, '_blank')}>Bekijken</button>}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className={`bg-slate-200 py-6 flex justify-end pr-5 rounded-br-md rounded-bl-md w-full`}>
                                {changes && (
                                    <button
                                        className="transition focus:outline-none duration-150 ease-in-out border border-cyan-500 bg-cyan-500 rounded text-white px-8 py-2 text-md hover:text-cyan-500 hover:bg-gray-100 mr-4"
                                        onClick={() => saveChangedStatus()}>Status bijwerken
                                    </button>
                                )}
                                <button
                                    className="text-cyan-500 bg-slate-50 hover:text-white border border-cyan-500 rounded-md px-4 py-1 bg-transparent hover:bg-cyan-500 bg transition-all"
                                    onClick={() => {
                                        setActiveOrder(null)
                                        contextStorage.setEditForm(false)
                                        setChanges(false)
                                    }}>Sluiten
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                {/*{activeOrder && <Form data={activeOrder}/>}*/}
            </div>
        </div>
    )
}
export default CustomerDetail
