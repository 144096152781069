import React, { useEffect, useState, useContext } from "react";
import { SiteContext } from "../contexts/SiteContext"
import Locale from "./Locale";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import Select from 'react-select';

const FormCreate = ({ data }) => {

    const [calculatedWidth, setCalculatedWidth] = useState(false);
    const [elOpacity, setElOpacity] = useState(0);
    const [formData, setFormData] = useState({});
    const contextStorage = useContext(SiteContext)
    const [excludedDates, setExcludedDates] = useState([])
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [customers, setCustomers] = useState([{ value: "Nieuwe klant", label: "Nieuwe klant" }]);
    const [categories, setCategories] = useState([{ value: "Suppen 2u", label: "Suppen 2u" }, { value: "Kajakken 2u", label: "Kajakken 2u" }, { value: "Ontbijt + kajakken", label: "Ontbijt + kajakken" }, { value: "Ontbijt + suppen", label: "Ontbijt + suppen" }]);

    useEffect(() => {
        data && setFormData(data)
    }, [data]);

    useEffect(() => {
        // console.log(contextStorage.createForm)
        if (contextStorage.createForm) {
            setCalculatedWidth(true)
            setTimeout(() => {
                setCalculatedWidth(document.querySelector('.dynamic-width').offsetWidth)
                setElOpacity(100)
            }, 300);
        }
    }, [contextStorage.createForm]);

    useEffect(() => {
        // console.log(calculatedWidth)
    }, [calculatedWidth]);

    const handleSave = () => {
        contextStorage.setTmpData(formData)
    }

    const getCustomers = async () => {
        let arr = []
        await axios.get(`${process.env.REACT_APP_BASE_URL}/api/customers`)
            .then(res => res.data.map(el => {
                setCustomers(prev => [
                    ...prev,
                    {
                        value: el.lastname + ' ' + el.firstname,
                        label: el.lastname + ' ' + el.firstname
                    },
                ])
            }))
    }

    useEffect(() => {
        getCustomers()
    }, []);

    useEffect(() => {
        // console.log(customers)
    }, [customers]);

    const handleSelect = () => {

    }


    return (
        <div className={` ${contextStorage.createForm ? "fixed" : "hidden "} fixed h-screen w-screen inset-0 flex flex-col justify-center items-center bg-black bg-opacity-70 z-50`}>
            <div className="dynamic-width pb-0 bg-white min-w-[50vw] max-h-[80vh] overflow-y-scroll rounded-tl-md rounded-tr-md block">
                <div className="flex flex-col items-center w-full pt-7">
                    <h4 className="text-3xl font-normal text-cyan-500">Nieuwe aanmaken</h4>
                </div>
                <div className="grid grid-cols-2 gap-x-10 gap-y-2 py-10 px-10">
                    <div className="mb-4 mx-2" >
                        <div>
                            <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                Datum
                            </label>
                        </div >
                        <div className="bg-gray-100 appearance-none border-2 border-gray-100 rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500">
                            <DatePicker
                                onChange={(date) => setSelectedDate(date)}
                                selected={new Date()}
                                minDate={new Date()}
                                excludeDates={excludedDates.length && excludedDates}
                                className=" w-full h-full bg-gray-100 py-2 px-4" />
                        </div>
                    </div>
                    <div className="mb-4 mx-2" >
                        <div>
                            <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                Klant
                            </label>
                        </div >
                        <div className="bg-gray-100 appearance-none border-2 border-gray-100 rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500">
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                defaultValue={customers && customers[0]}
                                options={customers}
                                onChange={(items) => handleSelect(items)}
                            />
                        </div>
                    </div>
                    <div className="mb-4 mx-2" >
                        <div>
                            <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                Klant
                            </label>
                        </div >
                        <div className="bg-gray-100 appearance-none border-2 border-gray-100 rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500">
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                defaultValue={categories && categories[0]}
                                options={categories}
                                onChange={(items) => handleSelect(items)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* {calculatedWidth ?
                <div className={`bg-slate-200 py-6 flex justify-end pr-5 rounded-br-md rounded-bl-md`} style={{ width: calculatedWidth + "px", opacity: elOpacity }}>
                    <button className="bg-cyan-500 focus:outline-none transition duration-150 ease-in-out hover:bg-cyan-600 rounded text-white px-8 py-2 text-sm mr-4" onClick={handleSave} >Save</button>
                    <button className="text-cyan-500 bg-slate-50 hover:text-white border border-cyan-500 rounded-md px-4 py-1 bg-transparent hover:bg-cyan-500 bg transition-all" onClick={() => contextStorage.setEditForm(false)}>Sluiten</button>
                </div> : ""} */}
        </div>
    )
}

export default FormCreate