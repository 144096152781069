import Analytics from './pages/Analytics';
import Calendar from './pages/Calendar';
import Orders from './pages/Orders';
import Services from './pages/Services';
import Customers from './pages/Customers';
import CustomersDetail from './pages/CustomerDetail';
import {
  createBrowserRouter, RouterProvider, Routes, Route, Router
} from "react-router-dom";
import Sidebar from './components/sidebar';
import MasterLayout from './components/MasterLayout';
import Overview from './pages/Overview';
import Settings from './pages/Settings';
import Users from './pages/Users';
import Discounts from './pages/Discounts';
import Categories from "./pages/Categories";
import Auth from "./pages/Auth";
import AuthAgent from "./contexts/AuthAgent.js";
import Vessels from "./pages/Vessels";
import Reservations from "./pages/Reservations";
import Options from "./pages/Options";
import OrderDetail from "./pages/OrderDetail";

const router = [
  {
    path: "/",
    element: <Reservations />,
  },
  {
    path: "analytics",
    element: <Analytics />,
  }, {
    path: "calendar",
    element: <Calendar />,
  }, {
    path: "reservations",
    element: <Reservations />,
  }, {
    path: "services",
    element: <Services />,
  }, {
    path: "settings",
    element: <Settings />,
  }, {
    path: "users",
    element: <Users />,
  }, {
    path: "customers",
    element: <Customers />,
  }, {
    path: "discounts",
    element: <Discounts />,
  }, {
    path: "customers/:id",
    element: <CustomersDetail />,
  }, {
    path: "categories/",
    element: <Categories />,
  }, {
    path: "signin",
    element: <Auth />,
  }, {
    path: "vessels",
    element: <Vessels />,
  },
  {
    path: "orders",
    element: <Orders />,
  },
  {
    path: "orders/:id",
    element: <OrderDetail />,
  },
  {
    path: "options",
    element: <Options />,
  }
];

function App() {
  return (
    <>
      <MasterLayout>
        <AuthAgent />
        <Routes>
          {router.map(el => <Route path={el.path} element={el.element} />)}
        </Routes>
      </MasterLayout>
      {/* <Sidebar /> */}
    </>
  );
}

export default App;