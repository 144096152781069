import React, {useEffect, useState, useContext} from "react";
import axios from 'axios';
import 'tw-elements';
import {SiteContext} from "../contexts/SiteContext"
import Form from "../components/Form";
import FormCreate from "../components/FormCreate";
import {addMinutes, format} from 'date-fns'
import {useParams} from "react-router-dom";
import Select from "react-select";
import _ from "lodash";


const OrderDetail = () => {
    const contextStorage = useContext(SiteContext)
    const [orders, setOrders] = useState(false)
    const [orderObjs, setOrderObjs] = useState([]);
    const [itemIndex, setItemIndex] = useState();
    const [activeOrder, setActiveOrder] = useState();
    const [formData, setFormData] = useState({});
    const [options, setOptions] = useState(false);

    const [showCancellation, setShowCancellation] = useState(false);
    const [cancellationReason, setCancellationReason] = useState('');
    const [cancelButton, setCancelButton] = useState('Verwijderen zonder mail');
    const [cancelMailButton, setCancelMailButton] = useState('Verwijderen met mail');
    const [changes, setChanges] = useState(false);

    let { id } = useParams();

    const handleCancellation = (mail, id) => {
        mail ? setCancelMailButton('Aan het annuleren...') : setCancelButton('Aan het annuleren...')
        axios.post(`${process.env.REACT_APP_BASE_URL}/api/orders/cancel`, {
            id: id,
            mail: mail,
            reason: cancellationReason
        }).then(r => {
            console.log(r)
            setShowCancellation(false)
            getReservationsFromOrder().then(r => {
                mail ? setCancelMailButton('Verwijderen met mail') : setCancelButton('Verwijderen zonder mail.')
            })
        })
    }

    const statuses = [{
        label: "In afwachting",
        value: 'in afwachting',
    }, {
        label: "Betaald",
        value: 'betaald'
    }, {
        label: "Afgekeurd (voucher)",
        value: 'afgekeurd'
    }, {
        label: "Betaling mislukt",
        value: 'betaling mislukt'
    }, {
        label: "Te controleren (voucher)",
        value: 'te controleren'
    }, {
        label: "Terugbetaald",
        value: 'terugbetaald'
    }]

    useEffect(() => {
        console.log(SiteContext)
    }, []);

    const getReservationsFromOrder = async () => {
        const res = await axios.get(`https://deinze-kajakt-api.vercel.app/api/general-orders/reservations/${id}`)
        const optsres = await axios.get(`https://deinze-kajakt-api.vercel.app/api/options`)
        console.log(res)
        setOptions(optsres.data)
        res.data.sort((a, b) => b.date - a.date)

        setOrders(res.data)
    }

    useEffect(() => {
        getReservationsFromOrder()
    }, []);

    useEffect(() => {
        orders && orders.map(order => setOrderObjs(orderObjs => [...orderObjs, order]))
    }, [orders]);

    useEffect(() => {
        console.log(contextStorage.tmpData)
        if (contextStorage.tmpData) {
            axios.post(`${process.env.REACT_APP_BASE_URL}/api/orders`, contextStorage.tmpData, {headers: {'content-type':'application/json'}}).then(res => {
                console.log(res)
                contextStorage.setTmpData(false)
                contextStorage.setEditForm(false)
                getReservationsFromOrder()
            }).catch(err => {
                contextStorage.setTmpData(false)
                contextStorage.setEditForm(false)
                getReservationsFromOrder()
            })
        }
    }, [contextStorage.tmpData]);

    const handleClick = (index, id) => {
        contextStorage.setEditForm(true)
        setFormData({...formData, id})
        setItemIndex(index)
    }

    useEffect(() => {
        setActiveOrder(orderObjs[itemIndex])
    }, [itemIndex]);

    const endTimeString = (time, duration) => {
        const timesplit = time.split(':')
        const starttime = new Date()
        starttime.setHours(timesplit[0])
        starttime.setMinutes(timesplit[1])
        const endtime = addMinutes(starttime, duration)
        console.log(starttime)
        console.log(endtime)
        const starthour = starttime.getHours().toString().length === 2 ? starttime.getHours() : `${starttime.getHours().toString()}0`
        const startminutes = starttime.getMinutes().toString().length === 2 ? starttime.getMinutes() : `${starttime.getMinutes().toString()}0`
        const endhour = endtime.getHours().toString().length === 2 ? endtime.getHours() : `${endtime.getHours().toString()}0`
        const endminutes = endtime.getMinutes().toString().length === 2 ? endtime.getMinutes() : `${endtime.getMinutes().toString()}0`
        console.log(`${endtime.getMinutes().toString()}0`)
        const string = `${endhour}:${endminutes}`
        return string
    }

    const getOptionName = (id) => {
        if(!options) return
        const opt = options.find(opt => opt.id === id)
        return opt ? opt.name : false
    }

    // const saveChangedStatus = async () => {
    //     axios.post(process.env.REACT_APP_BASE_URL+'/api/general-orders/update/'+changes.id, changes).then(r => {
    //         setChanges(false)
    //         setActiveOrder(false)
    //         getReservationsFromOrder()
    //     })
    //     console.log('set changes')
    // }
    //
    // const updateStatus = async (id, status) => {
    //     console.log(`Changing order "${id}"'s status to: ${status.value}`)
    //     const payload = _.cloneDeep(activeOrder)
    //     payload.payment.status = status.value
    //     setChanges(payload)
    // }

    return (
        <div className="mx-auto overflow-x-auto">
            <div
                className="mx-auto flex flex-col md:flex-row items-start md:items-center justify-between py-10 mb-2 bg-slate-200 rounded-md px-6">
                <h4 className="text-3xl font-bold leading-tight text-gray-800 ">Reservaties</h4>
                <div className="mt-6 md:mt-0">
                    {/*<button className="mr-3 focus:outline-none transition duration-150 ease-in-out rounded hover:bg-cyan-500 hover:text-white text-cyan-500 border-cyan-500 border px-5 py-2 text-md">Back</button>*/}
                    {/*<button*/}
                    {/*    className="transition focus:outline-none duration-150 ease-in-out border border-cyan-500 bg-cyan-500 rounded text-white px-8 py-2 text-md hover:text-cyan-500 hover:bg-gray-100"*/}
                    {/*    onClick={() => window.location = process.env.REACT_APP_FORM_URL + '/admin/' + localStorage.getItem('__nn_at__')}>Bestelling*/}
                    {/*    aanmaken*/}
                    {/*</button>*/}
                </div>
            </div>
            <table className="w-full shadow text-left bg-slate-50 rounded-lg py-24">
                <thead className="bg-slate-200 w-full">
                <tr className="border-b border-slate-200">
                    <th className="py-5 pl-2 sm:pl-10 w-1/6 text-base text-gray-800">Reservatie datum</th>
                    <th className="py-5 w-1/6 text-base  text-gray-800">Werknemer</th>
                    {/*<th className="py-5 w-1/6 text-base  text-gray-800">Klant</th>*/}
                    <th className="py-5 w-1/6 text-base  text-gray-800">Activiteit</th>
                    <th className="py-5 w-1/6 text-base  text-gray-800">Capaciteit</th>
                    <th className="py-5 w-1/6 text-base  text-gray-800">Opties</th>
                    {/*<th className="py-5 w-1/6 text-base  text-gray-800">Status</th>*/}
                    {/*<th className="py-5 w-1/6 text-base  text-gray-800">Betaling</th>*/}
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {orders && orders.length == 0 || orders == undefined ?
                    <h2 className=" pl-10 text-4xl py-12 w-full whitespace-nowrap">Orders worden opgehaald</h2>
                    :
                    orders && orders.map((el, index) =>
                        <tr className="border-b border-slate-200">
                            <td className="pl-2 sm:pl-10 pr-2 py-4 w-1/6">
                                <div className=" flex flex-col">
                                    <div>
                                        {/*<p className=" text-gray-800 pb-1 capitalize">{new Date(el.date).toISOString().split('T')[0]} {el.time}</p>*/}
                                        <p className=" text-gray-800 pb-1 capitalize">{`${format(new Date(el.date), "dd-MM-yyyy")} ${el.time} - ${endTimeString(el.time, el.duration)}`}</p>
                                    </div>
                                </div>
                            </td>
                            <td className="pt-4 pb-5 w-1/6">{el.employee ? el.employee : 'Online'}</td>
                            {/*<td className="pr-2 py-4 w-1/6">*/}
                            {/*    <div className="flex items-center">*/}
                            {/*        <div className="">*/}
                            {/*            <p className=" text-gray-800 mb-1">{el.customer.firstname} {el.customer.lastname}</p>*/}
                            {/*            <a href={`/customers/${el.customer.email}`}*/}
                            {/*               className="text-xs mb-0.5 text-cyan-500 hover:underline block">{el.customer.email}</a>*/}
                            {/*            /!* <a href={`/customers/${el.customer}`} className="text-xs mb-0.5 text-cyan-500 hover:underline block">+32 496 47 46 66</a> *!/*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</td>*/}

                            <td className="pt-4 pb-5 text-gray-800 w-1/6">{el.serviceDetail}</td>
                            <td className="pt-4 pb-5 text-gray-800 w-1/6">{el.vessel_amount && el.vessel_amount > 0 ? <p>{el.vessel_amount + ' voertuigen'}</p> : <div><p>{el.adult} volwassenen</p><p>{el.child} kinderen</p></div> }</td>
                            <td className="pt-4 pb-5 text-gray-800 w-1/6">
                                <ul>
                                    {el.options ? Object.entries(el.options).map(([key, value], i) => <li
                                        key={i}>{getOptionName(key) ? value : ''} {getOptionName(key)}</li>) : 'Geen'}
                                </ul>
                            </td>
                            {/*<td className="pt-4 pb-5 text-gray-800 w-1/6">€ {el.payment.amount} | {el.payment.method} |*/}
                            {/*    <span*/}
                            {/*        // onClick={() => el.payment.status === 'te controleren' && window.open(el.voucher, '_blank')}*/}
                            {/*        className={` capitalize font-medium ${el.payment.status === 'te controleren' && 'cursor-pointer'} ${el.payment.status == 'betaald' || el.payment.status == 'goedgekeurd' ? ' text-green-500' : el.payment.status == 'in afwachting' ? ' text-yellow-500' : ' text-red-600'}`}> {el.payment.status} </span>*/}
                            {/*</td>*/}
                            <td className="flex px-5 py-3">
                                {el.payment.status === 'geannuleerd' ? (
                                    <p className="text-red-500 text-center">Geannuleerd</p>
                                ):(
                                    <>
                                        <button
                                            className="text-cyan-500 hover:text-white border border-cyan-500 rounded-md px-4 py-1 hover:bg-cyan-500 bg transition-all"
                                            onClick={() => handleClick(index, el.id, el)}>Detail
                                        </button>
                                        <button
                                            className="ml-2 text-red-500 hover:text-white border border-red-500 rounded-md px-4 py-1 hover:bg-red-500 bg transition-all"
                                            onClick={() => setShowCancellation(el)}>Annuleren
                                        </button>
                                    </>
                                )}
                            </td>
                        </tr>)}
                </tbody>
            </table>
            {showCancellation &&
            <div
                className="fixed inset-0 bg-black bg-opacity-70 rounded-md mx-auto flex flex-col w-full h-full justify-center items-center z-50">
                <div className=" relative bg-slate-100 py-16 px-20 rounded-2xl">
                    <p className=" text-center text-2xl mb-9 text-gray-500">{showCancellation.serviceDetail}</p>
                    <p>Bericht voor annulatie-mail</p>
                    <textarea className="p-1" cols="30" rows="5" value={cancellationReason}
                              onChange={(e) => setCancellationReason(e.target.value)}/>
                    <div className="flex flex-col">
                        <button onClick={() => handleCancellation(true, showCancellation.id)}
                                className=" rounded my-1 text-lg py-2 px-5 bg-cyan-500 text-white hover:bg-cyan-600">{cancelMailButton}</button>
                        <button onClick={() => handleCancellation(false, showCancellation.id)}
                                className=" rounded my-1 text-lg py-2 px-5  bg-cyan-600 text-white hover:bg-cyan-500">{cancelButton}</button>
                        <button onClick={() => setShowCancellation(false)}
                                className=" rounded my-1 text-lg py-2 px-5 border-red-500 border text-red-500 hover:bg-red-500 mt-5 hover:text-white">Annuleren
                        </button>
                    </div>
                </div>
            </div>}
            {activeOrder && (
                <div
                    className={`${activeOrder && activeOrder.full_customer ? 'fixed' : "hidden"} h-screen w-screen inset-0 flex flex-col justify-center items-center bg-black bg-opacity-70 z-50`}>
                    <div className="min-w-[50vw]">
                        <div className="pb-0 bg-white w-full max-h-[80vh] overflow-y-scroll rounded-tl-md rounded-tr-md block">
                            <div className="flex flex-col items-center w-full pt-7">
                                <h4 className="text-3xl font-normal leading-tight text-cyan-500">Reservatie detail</h4>
                            </div>
                            <div className="grid grid-cols-1 xl:grid-cols-2 gap-x-10 gap-y-2 py-10 px-10">
                                <div className="mb-4 mx-2">
                                    <div>
                                        <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                            Aangemaakt op
                                        </label>
                                    </div>
                                    <p>{format(new Date(activeOrder.created_at), 'dd-MM-yyyy HH:mm')}</p>
                                </div>

                                <div className="mb-4 mx-2">
                                    <div>
                                        <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                            Naam
                                        </label>
                                    </div>
                                    <a href={`/customers/${activeOrder.full_customer.email}`}>{activeOrder.full_customer.firstname + ' ' + activeOrder.full_customer.lastname}</a>
                                </div>

                                {activeOrder.vessel_amount && activeOrder.vessel_amount > 0 ? <div className="mb-4 mx-2">
                                    <div>
                                        <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                            Voertuigen
                                        </label>
                                    </div>
                                    <p>{activeOrder.vessel_amount}</p>
                                </div> : <>
                                    <div className="mb-4 mx-2">
                                        <div>
                                            <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                                Volwassenen
                                            </label>
                                        </div>
                                        <p>{activeOrder.adult}</p>
                                    </div>

                                    <div className="mb-4 mx-2">
                                        <div>
                                            <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                                Kinderen
                                            </label>
                                        </div>
                                        <p>{activeOrder.child}</p>
                                    </div>
                                </>}

                                <div className="mb-4 mx-2">
                                    <div>
                                        <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                            Datum
                                        </label>
                                    </div>
                                    <p>{format(new Date(activeOrder.date), "dd-MM-yyyy")}</p>
                                </div>

                                <div className="mb-4 mx-2">
                                    <div>
                                        <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                            Startuur
                                        </label>
                                    </div>
                                    <p>{activeOrder.time} u</p>
                                </div>

                                <div className="mb-4 mx-2">
                                    <div>
                                        <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                            Prijs
                                        </label>
                                    </div>
                                    <p>€ {activeOrder.subtotal}</p>
                                </div>

                                <div className="mb-4 mx-2">
                                    <div>
                                        <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                            Opties
                                        </label>
                                    </div>
                                    <ul>
                                        {activeOrder.options ? Object.entries(activeOrder.options).map(([key, value], i) => <li
                                            key={i}>{getOptionName(key) ? value : ''} {getOptionName(key)}</li>) : 'Geen'}
                                    </ul>
                                </div>


                                <div className="mb-4 mx-2">
                                    <div>
                                        <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                            Adres
                                        </label>
                                    </div>
                                    <p>{`${activeOrder.full_customer.street} ${activeOrder.full_customer.number}, ${activeOrder.full_customer.postal} ${activeOrder.full_customer.city}`}</p>
                                </div>

                                <div className="mb-4 mx-2">
                                    <div>
                                        <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                            Bestelnummer
                                        </label>
                                    </div>
                                    <a href={`/orders/${activeOrder.id}`}>{activeOrder.id}</a>
                                </div>

                                <div className="mb-4 mx-2">
                                    <div>
                                        <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                            Duurtijd
                                        </label>
                                    </div>
                                    <p>{activeOrder.duration}min</p>
                                </div>

                                <div className="mb-4 mx-2">
                                    <div>
                                        <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                            Activiteit
                                        </label>
                                    </div>
                                    <p>{activeOrder.serviceDetail}</p>
                                </div>

                                {/*<div className="mb-4 mx-2">*/}
                                {/*    <div>*/}
                                {/*        <label className="block text-gray-500 font-bold mb-1.5 capitalize">*/}
                                {/*            Status*/}
                                {/*        </label>*/}
                                {/*    </div>*/}
                                {/*    <div className="mb-4 mr-2">*/}
                                {/*        <Select*/}
                                {/*            onChange={(selectedOption) => updateStatus(activeOrder.id, selectedOption)}*/}
                                {/*            value={statuses.find(s => s.value === activeOrder.payment.status)}*/}
                                {/*            options={statuses}*/}
                                {/*        />*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                            </div>
                        </div>

                        <div className={`bg-slate-200 py-6 flex justify-end pr-5 rounded-br-md rounded-bl-md w-full`}>
                            {/*{changes && (*/}
                            {/*    <button*/}
                            {/*        className="transition focus:outline-none duration-150 ease-in-out border border-cyan-500 bg-cyan-500 rounded text-white px-8 py-2 text-md hover:text-cyan-500 hover:bg-gray-100 mr-4"*/}
                            {/*        onClick={() => saveChangedStatus()}>Status bijwerken*/}
                            {/*    </button>*/}
                            {/*)}*/}
                            <button
                                className="text-cyan-500 bg-slate-50 hover:text-white border border-cyan-500 rounded-md px-4 py-1 bg-transparent hover:bg-cyan-500 bg transition-all"
                                onClick={() => {
                                    setActiveOrder(null)
                                    contextStorage.setEditForm(false)
                                    setChanges(false)
                                }}>Sluiten
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {/*<Form data={activeOrder}/>*/}
            {/*<FormCreate th={['Aangemaakt op', 'Geldigheidsperiode', 'Korting', 'Categorie', 'Status']} td={orders}/>*/}
        </div>

    )
}
export default OrderDetail
