import React, {useEffect, useState, useContext} from "react";
import axios from 'axios';
import 'tw-elements';
import {SiteContext} from "../contexts/SiteContext"
import Form from "../components/Form";
import {format} from "date-fns"
import DatePicker from "react-datepicker";
import Select from "react-select";

const Discounts = () => {
    const contextStorage = useContext(SiteContext)
    const [discounts, setdiscounts] = useState(false)
    const [discountObjs, setdiscountObjs] = useState([]);
    const [itemIndex, setItemIndex] = useState();
    const [activediscount, setActivediscount] = useState();
    const [formData, setFormData] = useState(false);
    const [createForm, setCreateForm] = useState();
    const [addFormSaveButton, setAddFormSaveButton] = useState('Toevoegen')
    const [categories, setCategories] = useState(false)

    useEffect(() => {
        console.log(SiteContext)
    }, []);

    const getDiscounts = async () => {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/discounts`)
        const cats = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/categories`)
        const catsArray = cats.data
        catsArray.unshift({id: 'all', name: 'Alles'})
        console.log(res.data)
        setCategories(catsArray)
        setdiscounts(res.data)
    }

    useEffect(() => {
        getDiscounts()
    }, []);

    useEffect(() => {
        discounts && discounts.map(discount => setdiscountObjs(discountObjs => [...discountObjs, discount]))
    }, [discounts]);

    useEffect(() => {
        if(contextStorage.tmpData){
            console.log(contextStorage.tmpData)
            console.log('RAN')
            setAddFormSaveButton('Even geduld...')
            axios.post(process.env.REACT_APP_BASE_URL + "/api/discounts", contextStorage.tmpData, {headers: {'content-type':'application/json'}}).then(res => {
                console.log(res)
                setAddFormSaveButton('Opslaan')
                contextStorage.setTmpData(false)
                contextStorage.setEditForm(false)
                getDiscounts()
            }).catch(err => console.log(err))
        }
    }, [contextStorage.tmpData]);

    const handleSave = async () => {
        console.log('handleSave')

        const fd = {}
        fd['label'] = formData.label || 'naamloos'
        fd['percentage'] = formData.percentage
        fd['validFrom'] = formData.validFrom || new Date()
        fd['validUntil'] = formData.validUntil || new Date()
        fd['category'] = formData.category
        fd['code'] = formData.code

        console.log(formData)
        setAddFormSaveButton('Even geduld...')
        axios.post(process.env.REACT_APP_BASE_URL + '/api/discounts/add', fd, {
            // 'content-type': 'multipart/form-data'
            'content-type': 'application/json'
        }).then(r => {
            setFormData(false)
            getDiscounts()
            setAddFormSaveButton('Toevoegen')
            setCreateForm(false)
            // window.location.reload()
        }).catch(e => console.log(e))
    }

    const handleDelete = (id) => {
        axios.get(process.env.REACT_APP_BASE_URL + '/api/discounts/delete/' + id).then(r => getDiscounts())
    }

    const handleClick = (item) => {
        console.log(item)
        contextStorage.setEditForm(true)
        setFormData(item)
    }

    useEffect(() => {
        setActivediscount(discountObjs[itemIndex])
    }, [itemIndex]);

    const getValidity = (discount) => {
        if (new Date() >= new Date(discount.validFrom) && new Date() <= new Date(discount.validUntil)) {
            return <td className="pt-4 pb-5 text-xs sm:text-base w-1/6 text-green-500">Actief</td>
        } else if (new Date() < new Date(discount.validFrom)) {
            return <td className="pt-4 pb-5 text-xs sm:text-base w-1/6">Nog niet actief</td>
        } else if (new Date() > new Date(discount.validUntil)) {
            return <td className="pt-4 pb-5 text-xs sm:text-base w-1/6 text-red-500">Verlopen</td>
        }
    }

    const categorySelect = (e) => {
        console.log(e)
        setFormData({...formData, category: e.value})
    }

    const getCat = (catId) => {
        if(!categories) return
        const cat = categories.find(c => c.id == catId)
        return cat.name
    }

    return (
        <div className="mx-auto overflow-x-auto">
            <div
                className="mx-auto flex flex-col md:flex-row items-start md:items-center justify-between py-10 mb-2 bg-slate-200 rounded-md px-6">
                <h4 className="text-3xl font-bold leading-tight text-gray-800 ">Kortingen</h4>
                <div className="mt-6 md:mt-0">
                    {/*<button*/}
                    {/*    className="mr-3 focus:outline-none transition duration-150 ease-in-out rounded hover:bg-cyan-500 hover:text-white text-cyan-500 bdiscount-cyan-500 bdiscount px-5 py-2 text-md">Back*/}
                    {/*</button>*/}
                    <button
                        className="transition focus:outline-none duration-150 ease-in-out bdiscount bdiscount-cyan-500 bg-cyan-500 rounded text-white px-8 py-2 text-md hover:text-cyan-500 hover:bg-gray-100"
                        onClick={() => setCreateForm(true)}
                        >
                        Korting aanmaken
                    </button>
                </div>
            </div>
            <table className="w-full shadow text-left bg-slate-50 rounded-lg py-24">
                <thead className="bg-slate-200 w-full">
                <tr className="bdiscount-b bdiscount-slate-200">
                    <th className="py-5 pl-2 sm:pl-10 w-1/6 text-base text-gray-800">Naam (intern)</th>
                    <th className="py-5 w-1/6 text-base text-gray-800">Code</th>
                    <th className="py-5 w-1/6 text-base text-gray-800">Geldigheidsperiode</th>
                    <th className="py-5 w-1/6 text-base text-gray-800">Korting</th>
                    <th className="py-5 w-1/6 text-base text-gray-800">Toegepast op</th>
                    <th className="py-5 w-1/6 text-base text-gray-800">Status</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {discounts && discounts.length == 0 || discounts == undefined ?
                    <h2 className=" pl-10 text-4xl py-12 w-full whitespace-nowrap">Geen kortingscodes</h2>
                    :
                    discounts && discounts.map((el, index) =>
                        <tr className="bdiscount-b bdiscount-slate-200">
                            <td className="pl-2 sm:pl-10 pr-2 py-4 w-1/6">
                                <div className="flex flex-col">
                                    <div>
                                        <p className="text-gray-800 text-xs sm:text-base pb-1 capitalize">{el.label}</p>
                                    </div>
                                </div>
                            </td>
                            <td className="pt-4 pb-5 text-xs sm:text-base w-1/6">{el.code}</td>
                            <td className="pt-4 pb-5 text-xs sm:text-base w-1/6"><span
                                className="font-bold">{format(new Date(el.validFrom), 'dd-MM-yyyy')}</span> tot <span
                                className="font-bold">{format(new Date(el.validUntil), 'dd-MM-yyyy')}</span>
                            </td>
                            <td className="pt-4 pb-5 text-xs sm:text-base w-1/6">{el.percentage}%</td>
                            <td className="pr-2 py-4 w-1/6">
                                <div className="flex items-center">
                                    <div className=""><p className="text-gray-800 text-xs sm:text-base mb-1">{el.service === "all" ? "Alle" : getCat(el.category)}</p></div>
                                </div>
                            </td>

                            {getValidity(el)}

                            <td className="flex px-5 py-3">
                                <button
                                    className="text-cyan-500 bg-slate-50 hover:text-white border border-cyan-500 rounded-md px-4 py-1 hover:bg-cyan-500 bg transition-all"
                                    onClick={() => handleClick(el)}>Aanpassen
                                </button>
                                &nbsp;
                                <button
                                    className="text-red-500 bg-slate-50 hover:text-white border border-red-500 rounded-md px-4 py-1 hover:bg-red-500 bg transition-all"
                                    onClick={() => handleDelete(el.id)}>Verwijderen
                                </button>
                            </td>
                        </tr>)}
                </tbody>
            </table>
            {formData && !createForm && <Form data={formData} clear={() => setFormData(false)} customFields={[
                {
                    type: 'datepicker',
                    name: 'validFrom',
                    label: 'Geldig vanaf'
                },
                {
                    type: 'datepicker',
                    name: 'validUntil',
                    label: 'Geldig tot'
                }
            ]} />}

            <div
                className={` ${createForm ? "fixed" : "hidden "} fixed h-screen w-screen inset-0 flex flex-col justify-center items-center bg-black bg-opacity-70 z-50`}>
                <div
                    className="dynamic-width pb-0 bg-white min-w-[50vw] max-h-[80vh] overflow-y-scroll rounded-tl-md rounded-tr-md block">
                    <div className="flex flex-col items-center w-full pt-7">
                        <h4 className="text-3xl font-normal text-cyan-500">Nieuwe kortingscode aanmaken</h4>
                    </div>
                    <div className="grid grid-cols-2 gap-x-10 gap-y-2 py-10 px-10">

                        <div className="mb-4 mx-2">
                            <div>
                                <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                    Naam (intern)
                                </label>
                            </div>
                            <div className="">
                                <input
                                    className="bg-gray-100 appearance-none border-2 border-gray-100 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                    id="inline-full-name" type="text"
                                    onChange={(e) => setFormData({...formData, label: e.target.value})}
                                    value={formData.label}/>
                            </div>
                        </div>

                        <div className="mb-4 mx-2">
                            <div>
                                <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                    Code
                                </label>
                            </div>
                            <div className="">
                                <input
                                    className="bg-gray-100 appearance-none border-2 border-gray-100 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                    id="inline-full-name" type="text"
                                    onChange={(e) => setFormData({...formData, code: e.target.value})}
                                    value={formData.code}/>
                            </div>
                        </div>

                        <div className="mb-4 mx-2">
                            <div>
                                <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                    Geldig vanaf
                                </label>
                            </div>
                            <div className="">
                                <DatePicker dateFormat="dd-MM-yyyy" selected={formData.validFrom ? new Date(formData.validFrom) : new Date()} onChange={(date) => setFormData({...formData, validFrom: date})} />
                            </div>
                        </div>

                        <div className="mb-4 mx-2">
                            <div>
                                <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                    Geldig tot
                                </label>
                            </div>
                            <div className="">
                                <DatePicker dateFormat="dd-MM-yyyy" selected={formData.validUntil ? new Date(formData.validUntil) : new Date()} onChange={(date) => setFormData({...formData, validUntil: date})} />
                            </div>
                        </div>

                        <div className="mb-4 mx-2">
                            <div>
                                <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                    Kortingspercentage
                                </label>
                            </div>
                            <div className="">
                                <input
                                    className="bg-gray-100 appearance-none border-2 border-gray-100 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                    id="inline-full-name" type="number"
                                    onChange={(e) => setFormData({...formData, percentage: e.target.value})}
                                    value={formData.percentage}/>
                            </div>
                        </div>

                        {categories && <div>
                            <div>
                                <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                    Toepassen op
                                </label>
                            </div>
                            <div
                                className="bg-gray-100 appearance-none border-2 border-gray-100 rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500">
                                <Select
                                    onChange={(selectedOption) => categorySelect(selectedOption)}
                                    options={categories.map(option => {
                                        return {value: option.id, label: option.name}
                                    })}/>
                            </div>
                        </div>}

                        {/*<div className="mb-4 mx-2">*/}
                        {/*    <div>*/}
                        {/*        <label className="block text-gray-500 font-bold mb-1.5 capitalize">*/}
                        {/*            Geldigheidsperiode*/}
                        {/*        </label>*/}
                        {/*    </div>*/}
                        {/*    <div className="">*/}
                        {/*        <input*/}
                        {/*            className="bg-gray-100 appearance-none border-2 border-gray-100 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"*/}
                        {/*            id="inline-full-name" type="number"*/}
                        {/*            onChange={(e) => setFormData({...formData, price: e.target.value})}*/}
                        {/*            value={formData.price}/>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                    </div>
                    <div className={`bg-slate-200 py-6 flex justify-end pr-5 rounded-br-md rounded-bl-md`}>
                        <button
                            className="bg-cyan-500 focus:outline-none transition duration-150 ease-in-out hover:bg-cyan-600 rounded text-white px-8 py-2 text-sm mr-4"
                            disabled={!formData.code || !formData.percentage || !formData.category}
                            onClick={() => handleSave()}>{(!formData.code || !formData.percentage || !formData.category) ? 'Gelieve alle velden in te vullen' : addFormSaveButton}
                        </button>
                        <button
                            className="text-cyan-500 bg-slate-50 hover:text-white border border-cyan-500 rounded-md px-4 py-1 bg-transparent hover:bg-cyan-500 bg transition-all"
                            onClick={() => setCreateForm(false)}>Sluiten
                        </button>
                    </div>
                </div>
                {/* {calculatedWidth ?
                <div className={`bg-slate-200 py-6 flex justify-end pr-5 rounded-br-md rounded-bl-md`} style={{ width: calculatedWidth + "px", opacity: elOpacity }}>
                    <button className="bg-cyan-500 focus:outline-none transition duration-150 ease-in-out hover:bg-cyan-600 rounded text-white px-8 py-2 text-sm mr-4" onClick={handleSave} >Save</button>
                    <button className="text-cyan-500 bg-slate-50 hover:text-white border border-cyan-500 rounded-md px-4 py-1 bg-transparent hover:bg-cyan-500 bg transition-all" onClick={() => contextStorage.setEditForm(false)}>Sluiten</button>
                </div> : ""} */}
            </div>

        </div>

    )
}
export default Discounts
