import React, {useEffect, useState, useContext} from "react";
import axios from 'axios';
import 'tw-elements';
import {SiteContext} from "../contexts/SiteContext"
import Form from "../components/Form";
import FormCreate from "../components/FormCreate";
import {addMinutes, format} from 'date-fns'
import Select from "react-select";
import DeletePopup from "../components/DeletePopup";


const Users = () => {
    const [users, setUsers] = useState(false)
    const [showAddUser, setShowAddUser] = useState(false)
    const [newUser, setNewUser] = useState({})
    const [message, setMessage] = useState(false)
    const [formData, setFormData] = useState({});
    const contextStorage = useContext(SiteContext)
    const [itemID, setItemID] = useState();

    const getUsers = async () => {
        const res = await axios.get(`https://deinze-kajakt-api.vercel.app/api/auth`)

        res.data.sort((a, b) => b.date - a.date)

        setUsers(res.data)
    }

    useEffect(() => {
        getUsers()
    }, []);

    useEffect(() => {
        console.log('UPDATE')
        if (contextStorage.tmpData) {
            console.log(contextStorage.tmpData)
            console.log('RAN')
            axios.post(process.env.REACT_APP_BASE_URL + "/api/auth/update", contextStorage.tmpData).then(res => {
                console.log(res)
                contextStorage.setTmpData(false)
                contextStorage.setEditForm(false)
                // setActiveService(false)
                getUsers()
            }).catch(err => console.log(err))
        } else {
            console.log('UPDATE DID NOT RUN: contextStorage.tmpData == ' + contextStorage.tmpData)
        }
    }, [contextStorage.tmpData]);

    const resetPW = async (email) => {
        axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/resetpw`, {email})
    }

    const injectUser = async () => {
        console.log('INJECTUSER')
        if (newUser !== {} && newUser.password === newUser.passwordConfirm) {
            axios.post(process.env.REACT_APP_BASE_URL + '/api/auth/register', newUser).then(r => {
                if (r.data.code && r.data.code == 'auth/weak-password') {
                    setMessage('Zwak wachtwoord')
                } else {
                    getUsers()
                    setShowAddUser(false)
                }
            }).catch(err => console.log(err.data))
        } else {
            setMessage('Wachtwoorden komen niet overeen.')
        }
    }

    const setRole = (role) => {
        setNewUser({...newUser, role: role.value})
    }

    const roles = [{
        label: "Administrator",
        value: 'administrator',
    }, {
        label: "Werknemer",
        value: 'werknemer'
    }]

    const handleClick = (item) => {
        contextStorage.setEditForm(true)
        setFormData(item)
        console.log(item)
        // setActiveService(item)
    }

    const handleDelete = (id) => {
        // axios.post(process.env.REACT_APP_BASE_URL + '/api/auth/delete').then(r => getUsers())
        setItemID(id)
        contextStorage.setWarning(true)
    }

    useEffect(() => {
        contextStorage.continueDelete ?
            axios.get(process.env.REACT_APP_BASE_URL + '/api/auth/delete/' + itemID)
                .then(r => {
                    getUsers()
                    contextStorage.setDeletePending(true)
                    setTimeout(() => {
                        contextStorage.setWarning(false)
                        contextStorage.setDeletePending(false)
                        contextStorage.setContinueDelete(false)
                    }, 1000);
                })
                .catch(e => {
                    contextStorage.setDeletePending(true)
                    contextStorage.setWarningMessage("Verwijderen lukt niet")
                    setTimeout(() => {
                        contextStorage.setDeletePending(false)
                        contextStorage.setContinueDelete(false)
                    }, 1000);
                })
            : contextStorage.setDeletePending(false)
    }, [contextStorage.continueDelete]);

    return (
        <div className="mx-auto overflow-x-auto">
            <div
                className="mx-auto flex flex-col md:flex-row items-start md:items-center justify-between py-10 mb-2 bg-slate-200 rounded-md px-6">
                <h4 className="text-3xl font-bold leading-tight text-gray-800 ">Gebruikers</h4>
                <div className="mt-6 md:mt-0">
                    {/*<button className="mr-3 focus:outline-none transition duration-150 ease-in-out rounded hover:bg-cyan-500 hover:text-white text-cyan-500 border-cyan-500 border px-5 py-2 text-md">Back</button>*/}
                    <button
                        className="transition focus:outline-none duration-150 ease-in-out border border-cyan-500 bg-cyan-500 rounded text-white px-8 py-2 text-md hover:text-cyan-500 hover:bg-gray-100"
                        onClick={() => setShowAddUser(true)}>Gebruiker aanmaken
                    </button>
                </div>
            </div>
            <table className="w-full shadow text-left bg-slate-50 rounded-lg py-24">
                <thead className="bg-slate-200 w-full">
                <tr className="border-b border-slate-200">
                    <th className="py-5 pl-2 sm:pl-10 w-1/6 text-base text-gray-800">Naam</th>
                    <th className="py-5 w-1/6 text-base  text-gray-800">E-mail</th>
                    <th className="py-5 w-1/6 text-base  text-gray-800">Rechten</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {users && users.length == 0 || users == undefined ?
                    <h2 className=" pl-10 text-4xl py-12 w-full whitespace-nowrap">Orders worden opgehaald</h2>
                    :
                    users && users.map((el, index) =>
                        <tr className="border-b border-slate-200">
                            <td className="pl-2 sm:pl-10 pr-2 py-4 w-1/6">
                                <div className=" flex flex-col">
                                    <div>
                                        <p className=" text-gray-800 pb-1 capitalize">{el.name}</p>
                                    </div>
                                </div>
                            </td>
                            <td className="pt-4 pb-5 w-1/6">{el.email}</td>

                            <td className="pt-4 pb-5 text-gray-800 w-1/6">{el.role}</td>
                            <td className=" px-5 py-3">
                                <button
                                    className="text-cyan-500 bg-slate-50 hover:text-white border border-cyan-500 rounded-md px-4 py-1 hover:bg-cyan-500 bg transition-all"
                                    onClick={() => handleClick(el)}>Aanpassen
                                </button>
                                &nbsp;
                                <button
                                    className="text-cyan-500 hover:text-white border border-cyan-500 rounded-md px-4 py-1 hover:bg-cyan-500 bg transition-all"
                                    onClick={() => resetPW(el.email)}>Wachtwoord resetten
                                </button>
                                &nbsp;
                                <button
                                    className="text-red-500 bg-slate-50 hover:text-white border border-red-500 rounded-md px-4 py-1 hover:bg-red-500 bg transition-all"
                                    onClick={() => handleDelete(el.id)}>Verwijderen
                                </button>
                            </td>
                        </tr>)}
                </tbody>
            </table>

            {formData && <Form data={formData} clear={() => setFormData(false)}
                               customFields={[{
                                   type: 'text',
                                   name: 'email',
                                   label: 'E-mailadres'
                               }, {
                                   type: "select-role",
                                   name: 'role',
                                   label: 'Rechten'
                               }]}
            />}


            {showAddUser && <>
                <div
                    className={`fixed h-screen w-screen inset-0 flex flex-col justify-center items-center bg-black bg-opacity-70 z-50`}>
                    <div className="min-w-[50vw]">
                        <div
                            className="dynamic-width pb-0 bg-white w-full max-h-[80vh] overflow-y-scroll rounded-tl-md rounded-tr-md block">
                            <div className="flex flex-col items-center w-full pt-7">
                                <h4 className="text-3xl font-normal leading-tight text-cyan-500">Gebruiker aanmaken</h4>
                            </div>
                            <div className="grid grid-cols-2 gap-x-10 gap-y-2 py-10 px-10">
                                {message && <p className="ml-2 text-red-500">{message}</p>}
                                <br/>
                                <div className="mb-4 mx-2">
                                    <div>
                                        <label className="block text-gray-500 font-bold mb-1.5 capitalize">Naam</label>
                                    </div>
                                    <div className="">
                                        <input
                                            className="bg-gray-100 appearance-none border-2 border-gray-100 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                            id="inline-full-name" type="text"
                                            onChange={(e) => setNewUser({...newUser, name: e.target.value})}/>
                                    </div>
                                </div>
                                <div className="mb-4 mx-2">
                                    <div>
                                        <label
                                            className="block text-gray-500 font-bold mb-1.5 capitalize">E-mailadres</label>
                                    </div>
                                    <div className="">
                                        <input
                                            className="bg-gray-100 appearance-none border-2 border-gray-100 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                            id="inline-full-name" type="email"
                                            onChange={(e) => setNewUser({...newUser, email: e.target.value})}/>
                                    </div>
                                </div>
                                <div className="mb-4 mx-2">
                                    <div>
                                        <label
                                            className="block text-gray-500 font-bold mb-1.5 capitalize">Wachtwoord</label>
                                    </div>
                                    <div className="">
                                        <input
                                            className="bg-gray-100 appearance-none border-2 border-gray-100 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                            id="inline-full-name" type="password"
                                            onChange={(e) => setNewUser({...newUser, password: e.target.value})}/>
                                    </div>
                                </div>
                                <div className="mb-4 mx-2">
                                    <div>
                                        <label className="block text-gray-500 font-bold mb-1.5 capitalize">Wachtwoord
                                            bevestigen</label>
                                    </div>
                                    <div className="">
                                        <input
                                            className="bg-gray-100 appearance-none border-2 border-gray-100 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                            id="inline-full-name" type="password"
                                            onChange={(e) => setNewUser({
                                                ...newUser,
                                                passwordConfirm: e.target.value
                                            })}/>
                                    </div>
                                </div>

                                <div className="mb-4 mx-2">
                                    <div>
                                        <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                            Rechten
                                        </label>
                                    </div>
                                    <Select
                                        onChange={(selectedOption) => setRole(selectedOption)}
                                        options={roles}/>
                                </div>

                            </div>
                        </div>
                        <div className={`bg-slate-200 py-6 flex justify-end pr-5 rounded-br-md rounded-bl-md w-full`}>
                            <button
                                className="bg-cyan-500 focus:outline-none transition duration-150 ease-in-out hover:bg-cyan-600 rounded text-white px-8 py-2 text-sm mr-4"
                                onClick={() => setShowAddUser(false)}>Annuleren
                            </button>
                            <button
                                className="transition focus:outline-none duration-150 ease-in-out border border-cyan-500 bg-cyan-500 rounded text-white px-8 py-2 text-md hover:text-cyan-500 hover:bg-gray-100"
                                onClick={() => injectUser()}>Gebruiker aanmaken
                            </button>
                        </div>
                    </div>
                </div>
            </>}
            <DeletePopup/>
        </div>
    )
}
export default Users
