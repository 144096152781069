import React, { useEffect, useState } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Line, Bar } from "react-chartjs-2";


const MultiAxis = ({ lastMonth }) => {

    const [analyticsData, setAnalyticsData] = useState(false)
console.log(lastMonth)
    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
            mode: "index",
            intersect: false,
        },
        stacked: false,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Overzicht bestellingen',
            },
        },
        elements: {
            point: {
                radius: 0
            }
        },
        scales: {
            y: {
                min: 0,
                ticks: {
                    stepSize: 1
                },
                grid: {
                    display: true
                },
                suggestedMax: lastMonth.suggestedMax
            },
            x: {
                grid: {
                    display: true
                }
            }
        }
    };


    const data = {
        labels: lastMonth.allDates.map(i => i.x),
        datasets: [
            {
                label: "Opbrengst per dag deze maand",
                data: lastMonth.allDates,
                borderColor: "rgb(255, 99, 132)",
                backgroundColor: "rgba(255, 99, 132, 0.5)",
                yAxisID: "y",
            },
            {
                label: "Opbrengst per dag vorige maand",
                data: lastMonth.lastMonth,
                borderColor: "rgba(77, 182, 172, 0.5)",
                backgroundColor: "rgba(77, 182, 172, 0.5)",
                yAxisID: "y",
            },
        ],
    };

    return <Line options={options} data={data} />;
}
export default MultiAxis