import React, { useEffect, useState, useContext } from "react";
import axios from 'axios';
import 'tw-elements';
import { SiteContext } from "../contexts/SiteContext"
import FormCreate from "../components/FormCreate";
import { addMinutes, format } from 'date-fns'
import _ from 'lodash'
import Select from "react-select";
import ClipLoader from "react-spinners/ClipLoader";


const Orders = () => {
    const contextStorage = useContext(SiteContext)
    const [orders, setOrders] = useState(false)
    const [orderObjs, setOrderObjs] = useState([]);
    const [itemIndex, setItemIndex] = useState();
    const [activeOrder, setActiveOrder] = useState();
    const [formData, setFormData] = useState({});
    const [changes, setChanges] = useState(false);
    const [useFilter, setUseFilter] = useState(false);
    const [dataFetching, setDataFetching] = useState(false);

    const statuses = [{
        label: "In afwachting",
        value: 'in afwachting',
    }, {
        label: "Betaald",
        value: 'betaald'
    }, {
        label: "Afgekeurd (voucher)",
        value: 'afgekeurd'
    }, {
        label: "Betaling mislukt",
        value: 'betaling mislukt'
    }, {
        label: "Te controleren (voucher)",
        value: 'te controleren'
    }, {
        label: "Terugbetaald",
        value: 'terugbetaald'
    }]

    useEffect(() => {
        getOrders()
    }, [useFilter])

    useEffect(() => {
        if(orders && contextStorage.search) {
            const tmpOrders = orders

            const filteredOrders = tmpOrders.filter(order => ((order.full_customer.firstname || '')+(order.full_customer.lastname || '')).toLowerCase().includes(contextStorage.search.toLowerCase()))
            setOrders(filteredOrders)
        }else{
            const cache = JSON.parse(localStorage.getItem('preload_orders'))
            setOrders(cache)
        }
    }, [contextStorage.search]);

    const getOrders = async () => {
        setDataFetching(true)
        // Preloader
        let cache = {}
        if (localStorage.getItem('preload_orders')) {
            cache = JSON.parse(localStorage.getItem('preload_orders'))
            setOrders(cache)
        }
        // END Preloader
        console.log(`${process.env.REACT_APP_BASE_URL}/api/general-orders/${localStorage.getItem('__nn_l__')}${useFilter ? '/7' : ''}`)
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/general-orders/${localStorage.getItem('__nn_l__')}${useFilter ?'/7' : ''}`)
        
        setDataFetching(false)

        // res.data.forEach(d => console.log(d.full_customer, d.))
        res.data.sort((a, b) => b.created_at - a.created_at)

        if (!_.isEqual(cache, res.data)) setOrders(res.data)

        localStorage.setItem('preload_orders', JSON.stringify(res.data))
    }

    useEffect(() => {
        getOrders()
    }, []);

    useEffect(() => {
        orders && orders.map(order => setOrderObjs(orderObjs => [...orderObjs, order]))
    }, [orders]);

    useEffect(() => {
        if (!contextStorage.tmpData || !orders) return // Prevent empty triggers

        const diff = {} // Object to put changed properties
        Object.entries(orders[itemIndex]).map(prop => { // Looping over all props of the original order
            const [property, value] = prop
            if (contextStorage.tmpData[property] !== orders[itemIndex][property]) { // Checking each prop if it has a different value in tmpData as on the original order
                diff[property] = contextStorage.tmpData[property] // If different, add this property with its new value to const diff = {}
            }
        })

        // Also adding general_order_id of course
        diff.general_order_id = orders[itemIndex].general_order_id

        console.log('diff', diff)

        if (contextStorage.tmpData) {
            // axios.post(`${process.env.REACT_APP_BASE_URL}/api/grouped-orders`, diff, {headers: {'content-type': 'application/json'}}).then(res => {
            //     console.log(res)
            //     contextStorage.setTmpData(false)
            //     contextStorage.setEditForm(false)
            //     setItemIndex()
            //     getOrders()
            // }).catch(err => {
            //     contextStorage.setTmpData(false)
            //     contextStorage.setEditForm(false)
            //     setItemIndex()
            //     getOrders()
            // })
        }
    }, [contextStorage.tmpData]);

    const updateStatus = async (id, status) => {
        console.log(`Changing order "${id}"'s status to: ${status.value}`)
        const payload = _.cloneDeep(activeOrder)
        payload.payment.status = status.value
        setChanges(payload)
    }

    const saveChangedStatus = async () => {
        axios.post(process.env.REACT_APP_BASE_URL+'/api/general-orders/update/'+changes.id, changes).then(r => {
            setChanges(false)
            setActiveOrder(false)
            getOrders()
        })
    }

    const handleClick = (index, id, el) => {
        // contextStorage.setEditForm(true)
        setFormData({ ...formData, id })
        setActiveOrder(el)
        // setItemIndex(index)
    }

    useEffect(() => {
        console.log(orderObjs[itemIndex])
        // setActiveOrder(orderObjs[itemIndex])
    }, [itemIndex]);

    const endTimeString = (time, duration) => {
        const timesplit = time.split(':')
        const starttime = new Date()
        starttime.setHours(timesplit[0])
        starttime.setMinutes(timesplit[1])
        const endtime = addMinutes(starttime, duration)
        console.log(starttime)
        console.log(endtime)
        const starthour = starttime.getHours().toString().length === 2 ? starttime.getHours() : `${starttime.getHours().toString()}0`
        const startminutes = starttime.getMinutes().toString().length === 2 ? starttime.getMinutes() : `${starttime.getMinutes().toString()}0`
        const endhour = endtime.getHours().toString().length === 2 ? endtime.getHours() : `${endtime.getHours().toString()}0`
        const endminutes = endtime.getMinutes().toString().length === 2 ? endtime.getMinutes() : `${endtime.getMinutes().toString()}0`
        console.log(`${endtime.getMinutes().toString()}0`)
        const string = `${endhour}:${endminutes}`
        return string
    }

    const validateVoucher = async (status, id) => {
        axios.post(process.env.REACT_APP_BASE_URL + '/api/general-orders/update/'+id, {"payment.status": status}, {
            // 'content-type': 'multipart/form-data'
            'content-type': 'application/json',
        }).then(r => {
            getOrders()
            setActiveOrder(false)
        }).catch(e => console.log(e))
    }

    // const voucherStatus = (status) => {
    //     switch (status){
    //         case 'goedgekeurd':
    //             return ''
    //     }
    // }



    return (
        <div className="mx-auto overflow-x-auto">
            <div
                className="mx-auto flex flex-col md:flex-row items-start md:items-center justify-between py-10 mb-2 bg-slate-200 rounded-md px-6">
                <div className="flex">
                    <h4 className="text-3xl font-bold leading-tight text-gray-800 pr-4">Bestellingen</h4>
                    <ClipLoader loading={dataFetching} />
                </div>
                <div className="mt-6 md:mt-0">
                    {/*<button className="mr-3 focus:outline-none transition duration-150 ease-in-out rounded hover:bg-cyan-500 hover:text-white text-cyan-500 border-cyan-500 border px-5 py-2 text-md">Back</button>*/}
                    <button
                        className="transition focus:outline-none duration-150 ease-in-out border border-cyan-500 bg-cyan-500 rounded text-white px-8 py-2 text-md hover:text-cyan-500 hover:bg-gray-100"
                        onClick={() => window.location = process.env.REACT_APP_FORM_URL + '/admin/' + localStorage.getItem('__nn_at__')}>Bestelling
                        aanmaken
                    </button>
                </div>
            </div>
            <table className="w-full shadow text-left bg-slate-50 rounded-lg py-24">
                <thead className="bg-slate-200 w-full">
                    <tr className="border-b border-slate-200">
                        <th className="py-5 pl-2 sm:pl-10 w-1/6 text-base text-gray-800">Bestelnummer</th>
                        <th className="py-5 w-1/6 text-base  text-gray-800">Datum</th>
                        {/*<th className="py-5 w-1/6 text-base  text-gray-800">Werknemer</th>*/}
                        <th className="py-5 w-1/6 text-base  text-gray-800">Klant</th>
                        {/*<th className="py-5 w-1/6 text-base  text-gray-800">Activiteit</th>*/}
                        {/*<th className="py-5 w-1/6 text-base  text-gray-800">Tijdsduur</th>*/}
                        <th className="py-5 w-1/6 text-base  text-gray-800">Kortingscode</th>
                        <th className="py-5 w-1/6 text-base  text-gray-800">Betaling</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {orders && orders.length == 0 || orders == undefined ?
                        <h2 className=" pl-10 text-4xl py-12 w-full whitespace-nowrap">Orders worden opgehaald</h2>
                        :
                        orders && orders.map((el, index) =>
                            <tr className="border-b border-slate-200">
                                <td className="pl-2 sm:pl-10 pr-2 py-4 w-1/6">{el.id}</td>
                                <td className="pt-4 pb-5 w-1/6">
                                    <div className=" flex flex-col">
                                        <div>
                                            {/*<p className=" text-gray-800 pb-1 capitalize">{new Date(el.date).toISOString().split('T')[0]} {el.time}</p>*/}
                                            <p className=" text-gray-800 pb-1 capitalize">{format(new Date(el.created_at), "dd-MM-yyyy HH:mm")}</p>
                                        </div>
                                    </div>
                                </td>
                                {/*<td className="pt-4 pb-5 w-1/6">{el.employee ? el.employee : 'Online'}</td>*/}
                                <td className="pr-2 py-4 w-1/6">
                                    <div className="flex items-center">
                                        <div className="">
                                            <a href={`/customers/${el.full_customer.email}`} className=" text-gray-800 mb-1">{el.full_customer.firstname} {el.full_customer.lastname}</a>
                                            <a href={`mailto:${el.full_customer.email}`} className="text-xs mb-0.5 text-cyan-500 hover:underline block">{el.full_customer.email}</a>
                                            {/* <a href={`/customers/${el.customer}`} className="text-xs mb-0.5 text-cyan-500 hover:underline block">+32 496 47 46 66</a> */}
                                        </div>
                                    </div>
                                </td>
                                {/*<td className="pt-4 pb-5 text-gray-800 w-1/6">{el.serviceDetail}</td>*/}
                                {/*<td className="pt-4 pb-5 text-gray-800 w-1/6">{el.duration} Min</td>*/}
                                {/*<td className="pt-4 pb-5 text-gray-800 w-1/6">{el.payment.status || 'onbekend'} {el.id}</td>*/}
                                <td className="pt-4 pb-5 w-1/6">{el.coupon ? (`${el.coupon.coupon.code} (€ ${el.coupon.discountAmount})`) : 'Geen'}</td>
                                <td className="pt-4 pb-5 text-gray-800 w-1/6">€ {(el.coupon ? (parseFloat(el.payment.amount) - parseFloat(el.coupon.discountAmount)) : parseFloat(el.payment.amount).toFixed(2)) || ''} | {el.payment.method || ''} |
                                    <span
                                        onClick={() => el.payment.status === 'te controleren' && window.open(el.voucher, '_blank')}
                                        className={` capitalize font-medium ${el.payment.status === 'te controleren' && 'cursor-pointer'} ${el.payment.status == 'betaald' || el.payment.status == 'goedgekeurd' ? ' text-green-500' : el.payment.status == 'in afwachting' ? ' text-yellow-500' : ' text-red-600'}`}> {el.payment.status} </span>
                                </td>
                                <td className="px-5 py-3 flex justify-end">
                                    <button
                                        className="transition focus:outline-none duration-150 ease-in-out border border-cyan-500 bg-cyan-500 rounded text-white px-8 py-2 text-md hover:text-cyan-500 hover:bg-gray-100 mr-4"
                                        onClick={() => handleClick(index, el.id, el)}>Detail
                                    </button>
                                    <button
                                        className="text-cyan-500 hover:text-white border border-cyan-500 rounded-md px-4 py-1 hover:bg-cyan-500 bg transition-all whitespace-nowrap"
                                        onClick={() => window.location = '/orders/' + el.id}>Bekijk reservaties
                                    </button>
                                </td>
                            </tr>)}
                </tbody>
            </table>
            {/* <div className="flex justify-center py-10">
                <button
                    className="transition focus:outline-none duration-150 ease-in-out border border-cyan-500 bg-cyan-500 rounded text-white px-8 py-2 text-md hover:text-cyan-500 hover:bg-gray-100 mr-4"
                    onClick={() => setUseFilter(!useFilter)}>{useFilter ? 'Alle bestellingen' : 'Laatste 7 dagen'} laden
                </button>
            </div> */}
            {activeOrder && (
                <div
                    className={`${activeOrder && activeOrder.full_customer ? 'fixed' : "hidden"} h-screen w-screen inset-0 flex flex-col justify-center items-center bg-black bg-opacity-70 z-50`}>
                    <div className="min-w-[50vw]">
                        <div className="pb-0 bg-white w-full max-h-[80vh] overflow-y-scroll rounded-tl-md rounded-tr-md block">
                            <div className="flex flex-col items-center w-full pt-7">
                                <h4 className="text-3xl font-normal leading-tight text-cyan-500">Order detail</h4>
                            </div>
                            <div className="grid grid-cols-1 xl:grid-cols-2 gap-x-10 gap-y-2 py-10 px-10">
                                {/*{activeOrder && activeOrder.full_customer && Object.entries(activeOrder.full_customer).map(el =>*/}
                                {/*    <div className="mb-4 mx-2">*/}
                                {/*        <div>*/}
                                {/*            <label className="block text-gray-500 font-bold mb-1.5 capitalize">*/}
                                {/*                {el[0]}*/}
                                {/*            </label>*/}
                                {/*        </div>*/}
                                {/*        <p>{el[1]} </p>*/}
                                {/*    </div>*/}
                                {/*)}*/}
                                <div className="mb-4 mx-2">
                                    <div>
                                        <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                            Aangemaakt op
                                        </label>
                                    </div>
                                    <p>{format(new Date(activeOrder.created_at), 'dd-MM-yyyy HH:mm')}</p>
                                </div>

                                <div className="mb-4 mx-2">
                                    <div>
                                        <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                            Naam
                                        </label>
                                    </div>
                                    <a href={`/customers/${activeOrder.full_customer.email}`}>{activeOrder.full_customer.firstname + ' ' + activeOrder.full_customer.lastname}</a>
                                </div>

                                <div className="mb-4 mx-2">
                                    <div>
                                        <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                            Adres
                                        </label>
                                    </div>
                                    <p>{`${activeOrder.full_customer.street} ${activeOrder.full_customer.number}, ${activeOrder.full_customer.postal} ${activeOrder.full_customer.city}`}</p>
                                </div>

                                <div className="mb-4 mx-2">
                                    <div>
                                        <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                            Bestelnummer
                                        </label>
                                    </div>
                                    <p>{activeOrder.id}</p>
                                </div>

                                <div className="mb-4 mx-2">
                                    <div>
                                        <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                            Status
                                        </label>
                                    </div>
                                    <div className="mb-4 mr-2">
                                        <Select
                                            onChange={(selectedOption) => updateStatus(activeOrder.id, selectedOption)}
                                            options={statuses}
                                        />
                                    </div>
                                </div>
                                <div className="mb-4 mx-2">
                                    <div>
                                        <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                            Totaal
                                        </label>
                                    </div>
                                    <div className="mb-4 mr-2">
                                        <p>€ {activeOrder.total}</p>
                                    </div>
                                </div>
                                <div className="mb-4 mx-2">
                                    <div>
                                        <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                            Totaal
                                        </label>
                                    </div>
                                    <div className="mb-4 mr-2">
                                        <p>{activeOrder.coupon ? (`${activeOrder.coupon.coupon.code} (€ ${activeOrder.coupon.discountAmount})`) : 'Geen'}</p>
                                    </div>
                                </div>

                                {activeOrder.payment.method === 'Voucher' && (
                                    <div className="mb-4 mx-2">
                                        <div>
                                            <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                                Voucher valideren ({activeOrder.payment.status})
                                            </label>
                                        </div>
                                        <div className="flex justify-between">
                                            <button
                                                className={activeOrder.payment.status === 'afgekeurd' ? "bg-cyan-500 focus:outline-none transition duration-150 ease-in-out hover:bg-cyan-600 rounded text-white px-8 py-2 text-sm mr-4" : "text-cyan-500 bg-slate-50 hover:text-white border border-cyan-500 rounded-md px-4 py-1 bg-transparent hover:bg-cyan-500 bg transition-all mr-4"}
                                                onClick={() => validateVoucher('afgekeurd', activeOrder.id)}>Afwijzen
                                            </button>
                                            <button
                                                className={activeOrder.payment.status === 'goedgekeurd' ? "bg-cyan-500 focus:outline-none transition duration-150 ease-in-out hover:bg-cyan-600 rounded text-white px-8 py-2 text-sm" : "text-cyan-500 bg-slate-50 hover:text-white border border-cyan-500 rounded-md px-4 py-1 bg-transparent hover:bg-cyan-500 bg transition-all"}
                                                onClick={() => validateVoucher('goedgekeurd', activeOrder.id)}>Goedkeuren
                                            </button>
                                            {activeOrder.voucher && <button
                                                className="bg-cyan-500 focus:outline-none transition duration-150 ease-in-out hover:bg-cyan-600 rounded text-white px-8 py-2 text-sm ml-4"
                                                onClick={() => window.open(activeOrder.voucher, '_blank')}>Bekijken</button>}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={`bg-slate-200 py-6 flex justify-end pr-5 rounded-br-md rounded-bl-md w-full`}>
                            {changes && (
                                <button
                                    className="transition focus:outline-none duration-150 ease-in-out border border-cyan-500 bg-cyan-500 rounded text-white px-8 py-2 text-md hover:text-cyan-500 hover:bg-gray-100 mr-4"
                                    onClick={() => saveChangedStatus()}>Status bijwerken
                                </button>
                            )}
                            <button
                                className="text-cyan-500 bg-slate-50 hover:text-white border border-cyan-500 rounded-md px-4 py-1 bg-transparent hover:bg-cyan-500 bg transition-all"
                                onClick={() => {
                                    setActiveOrder(null)
                                    contextStorage.setEditForm(false)
                                    setChanges(false)
                                }}>Sluiten
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <FormCreate th={['Aangemaakt op', 'Geldigheidsperiode', 'Korting', 'Categorie', 'Status']} td={orders} />
        </div>

    )
}
export default Orders
