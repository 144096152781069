import React, { useEffect, useState, useContext } from "react";
import axios from 'axios';
import 'tw-elements';
import { SiteContext } from "../contexts/SiteContext"
import Form from "../components/Form";
import FormCreate from "../components/FormCreate";
import { addDays, addMinutes, format, parse } from 'date-fns'
import _ from "lodash";
import Select from "react-select";
import ClipLoader from "react-spinners/ClipLoader";

const Reservations = () => {
    const contextStorage = useContext(SiteContext)
    const [orders, setOrders] = useState(false)
    const [orderObjs, setOrderObjs] = useState([]);
    const [itemIndex, setItemIndex] = useState();
    const [activeOrder, setActiveOrder] = useState();
    const [formData, setFormData] = useState({});
    const [options, setOptions] = useState(false);
    const [showCancellation, setShowCancellation] = useState(false);
    const [cancellationReason, setCancellationReason] = useState('');
    const [cancelButton, setCancelButton] = useState('Verwijderen zonder mail');
    const [cancelMailButton, setCancelMailButton] = useState('Verwijderen met mail');
    const [filterMode, setFilterMode] = useState('future');
    const [changes, setChanges] = useState(false);
    const [useFilter, setUseFilter] = useState(true);
    const [dataFetching, setDataFetching] = useState(false)


    useEffect(() => {
        console.log(SiteContext)
    }, []);


    const statuses = [{
        label: "In afwachting",
        value: 'in afwachting',
    }, {
        label: "Betaald",
        value: 'betaald'
    }, {
        label: "Afgekeurd (voucher)",
        value: 'afgekeurd'
    }, {
        label: "Betaling mislukt",
        value: 'betaling mislukt'
    }, {
        label: "Te controleren (voucher)",
        value: 'te controleren'
    }, {
        label: "Terugbetaald",
        value: 'terugbetaald'
    }]

    useEffect(() => {
        getReservations()
    }, [useFilter])

    const getReservations = async () => {

        setDataFetching(true)

        // Preloader
        let cache = {}
        if (localStorage.getItem('preload_reservations')) {
            cache = JSON.parse(localStorage.getItem('preload_reservations'))
            setOrders(cache)
        }
        // END Preloader

        const optsres = await axios.get(`https://deinze-kajakt-api.vercel.app/api/options`)
        setOptions(optsres.data)

        console.log(`${process.env.REACT_APP_BASE_URL}/api/orders/${localStorage.getItem('__nn_l__')}${useFilter ? '/7' : ''}`)
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/orders/${localStorage.getItem('__nn_l__')}${useFilter ? '/7' : ''}`)
        console.log(res)

        setDataFetching(false)

        // if(filterMode === 'future') res.data.filter(reservation => validateTimeStatusForFilter(reservation))

        // res.data.sort((a, b) => a.date - b.date)
        res.data.sort((a, b) => {
            const dateTimeA = new Date(a.date)
            dateTimeA.setHours(a.time.split(':')[0])
            dateTimeA.setMinutes(a.time.split(':')[1])
            const dateTimeB = new Date(b.date)
            dateTimeB.setHours(b.time.split(':')[0])
            dateTimeB.setMinutes(b.time.split(':')[1])
            console.log(dateTimeA)
            console.log(dateTimeB)

            return dateTimeA.getTime() - dateTimeB.getTime()
        })


        // if (!_.isEqual(cache, res.data)) setOrders(res.data)
        setOrders(res.data)

        localStorage.setItem('preload_reservations', JSON.stringify(res.data))
    }

    useEffect(() => {
        const UNIXNow = new Date().getTime()
        const addOneDay = addDays(new Date(UNIXNow), 1)
        const UNIXaddOneDay = addOneDay.getTime()
        console.log(UNIXNow)
        console.log(new Date(format(new Date(), 'yyyy-MM-dd')).getTime())
        getReservations()
    }, []);

    useEffect(() => {
        getReservations()
    }, [filterMode]);

    useEffect(() => {
        orders && orders.map(order => setOrderObjs(orderObjs => [...orderObjs, order]))
    }, [orders]);

    useEffect(() => {
        console.log(contextStorage.tmpData)
        if (contextStorage.tmpData) {
            axios.post(`${process.env.REACT_APP_BASE_URL}/api/orders`, contextStorage.tmpData, { headers: { 'content-type': 'application/json' } }).then(res => {
                console.log(res)
                contextStorage.setTmpData(false)
                contextStorage.setEditForm(false)
                getReservations()
            }).catch(err => {
                contextStorage.setTmpData(false)
                contextStorage.setEditForm(false)
                getReservations()
            })
        }
    }, [contextStorage.tmpData]);

    const handleClick = (index, id, el) => {
        contextStorage.setEditForm(true)
        setFormData({ ...formData, id })
        setActiveOrder(el)
        setItemIndex(index)
    }

    useEffect(() => {
        setActiveOrder(orderObjs[itemIndex])
    }, [itemIndex]);

    const saveChangedStatus = async () => {
        axios.post(process.env.REACT_APP_BASE_URL+'/api/general-orders/update/'+changes.id, changes).then(r => {
            setChanges(false)
            setActiveOrder(false)
            getReservations()
        })
        console.log('set changes')
    }

    const checkFilter = (el) => {
        if (filterMode === 'all') return true
        const now = new Date()
        const start = new Date(format(new Date(el.date), 'yyyy-MM-dd') + 'T' + el.time)
        const stop = addMinutes(new Date(format(new Date(el.date), 'yyyy-MM-dd') + 'T' + el.time), parseInt(el.duration))
        // console.log(stop > now)
        return stop > now
    }

    const validateTimeStatus = (el, textMode) => {
        const now = new Date()
        const start = new Date(format(new Date(el.date), 'yyyy-MM-dd') + 'T' + el.time)
        const stop = addMinutes(new Date(format(new Date(el.date), 'yyyy-MM-dd') + 'T' + el.time), parseInt(el.duration))
        // console.log(start)
        // console.log(stop)

        if (el.payment.status === 'geannuleerd') {
            // console.log('Geannuleerd')
            return textMode ? 'Geannuleerd' : 'border-l-red-500'
        } else if (start > now && stop > now) { // Not yet started
            // console.log('In afwachting')
            return textMode ? 'In afwachting' : 'border-l-green-500'
        } else if (start < now && stop > now) {
            // console.log('Bezig')
            return textMode ? 'Bezig' : 'border-l-orange-500'
        } else if (start < now && stop < now) {
            // console.log('Voltooid')
            return textMode ? 'Voltooid' : 'border-l-slate-200'
        }

    }

    const endTimeString = (time, duration) => {
        const timesplit = time.split(':')
        const starttime = new Date()
        starttime.setHours(timesplit[0])
        starttime.setMinutes(timesplit[1])
        const endtime = addMinutes(starttime, duration)
        // console.log(starttime)
        // console.log(endtime)
        const starthour = starttime.getHours().toString().length === 2 ? starttime.getHours() : `0${starttime.getHours().toString()}`
        const startminutes = starttime.getMinutes().toString().length === 2 ? starttime.getMinutes() : `${starttime.getMinutes().toString()}0`
        const endhour = endtime.getHours().toString().length === 2 ? endtime.getHours() : `0${endtime.getHours().toString()}`
        const endminutes = endtime.getMinutes().toString().length === 2 ? endtime.getMinutes() : `${endtime.getMinutes().toString()}0`
        // console.log(`${endtime.getMinutes().toString()}0`)
        const string = `${endhour}:${endminutes}`
        return string
    }

    const getOptionName = (id) => {
        if (!options) return
        const opt = options.find(opt => opt.id === id)
        return opt ? opt.name : false
    }

    const handleCancellation = (mail, id) => {
        mail ? setCancelMailButton('Aan het annuleren...') : setCancelButton('Aan het annuleren...')
        axios.post(`${process.env.REACT_APP_BASE_URL}/api/orders/cancel`, {
            id: id,
            mail: mail,
            reason: cancellationReason
        }).then(r => {
            console.log(r)
            setShowCancellation(false)
            getReservations().then(r => {
                mail ? setCancelMailButton('Verwijderen met mail') : setCancelButton('Verwijderen zonder mail.')
            })
        })
    }

    const updateStatus = async (id, status) => {
        console.log(`Changing order "${id}"'s status to: ${status.value}`)
        const payload = _.cloneDeep(activeOrder)
        payload.payment.status = status.value
        setChanges(payload)
    }

    useEffect(() => {
        console.log(activeOrder)
    }, [activeOrder]);

    const isToday = (el) => {
        const orderDate = new Date(el.date)
        const today = new Date()

        return orderDate.getDate() == today.getDate() && orderDate.getMonth() == today.getMonth() && orderDate.getFullYear() == today.getFullYear()
    }

    return (
        <div className="mx-auto overflow-x-auto relative">
            <div
                className="mx-auto flex flex-col md:flex-row items-start md:items-center justify-between py-10 mb-2 bg-slate-200 rounded-md px-6">
                <div className="flex">
                    <h4 className="text-3xl font-bold leading-tight text-gray-800 pr-4">Reservaties</h4>
                    <ClipLoader loading={dataFetching} />
                </div>
                <div className="mt-6 md:mt-0">
                    {/*<button className="mr-3 focus:outline-none transition duration-150 ease-in-out rounded hover:bg-cyan-500 hover:text-white text-cyan-500 border-cyan-500 border px-5 py-2 text-md">Back</button>*/}
                    <button
                        className="transition focus:outline-none duration-150 ease-in-out border border-cyan-500 bg-cyan-500 rounded text-white px-8 py-2 text-md hover:text-cyan-500 hover:bg-gray-100"
                        onClick={() => window.location = `https://boeking.${localStorage.getItem('__nn_l__')}kajakt.be/admin/${localStorage.getItem('__nn_at__')}`}>Bestelling
                        aanmaken
                    </button>
                </div>
            </div>

            {/* <div className="flex my-2">
                <button onClick={() => setFilterMode('future')} className={filterMode === 'future' ? 'rounded my-1 text-lg py-2 px-5 bg-cyan-500 text-white hover:bg-cyan-500 mr-2' : 'rounded my-1 text-lg py-2 px-5  bg-slate-100 text-cyan-500 hover:bg-white mr-2'}>Toekomstige reservaties</button>
                <button onClick={() => setFilterMode('all')} className={filterMode === 'all' ? 'rounded my-1 text-lg py-2 px-5 bg-cyan-500 text-white hover:bg-cyan-500 mr-2' : 'rounded my-1 text-lg py-2 px-5  bg-slate-100 text-cyan-500 hover:bg-white mr-2'}>Alle reservaties</button>
            </div> */}

            <table className="w-full shadow text-left bg-slate-50 rounded-lg py-24">
                <thead className="bg-slate-200 w-full">
                    <tr className="border-b border-slate-200">
                        <th className="py-5 pl-2 sm:pl-10 w-1/6 text-base text-gray-800">Reservatie datum</th>
                        {/*<th className="py-5 w-1/6 text-base  text-gray-800">Werknemer</th>*/}
                        <th className="py-5 pl-4 w-1/6 text-base  text-gray-800">Klant</th>
                        <th className="py-5 w-1/6 text-base  text-gray-800">Activiteit</th>
                        <th className="py-5 w-1/6 text-base  text-gray-800">Capaciteit</th>
                        <th className="py-5 w-1/6 text-base  text-gray-800">Status</th>
                        <th className="py-5 w-1/6 text-base  text-gray-800">Opties</th>
                        {/*<th className="py-5 w-1/6 text-base  text-gray-800">Status</th>*/}
                        {/*<th className="py-5 w-1/6 text-base  text-gray-800">Betaling</th>*/}
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {orders && orders.length == 0 || orders == undefined ?
                        <h2 className=" pl-10 text-4xl py-12 w-full whitespace-nowrap">Orders worden opgehaald</h2>
                        :
                        orders && orders.map((el, index) => checkFilter(el) &&
                            <tr className={`border-b border-slate-200 border-l-2 ${validateTimeStatus(el)} ${isToday(el) ? 'bg-white' : 'bg-slate-100'}`}>
                                <td className="pl-2 sm:pl-10 pr-2 py-4 w-1/6">
                                    <div className=" flex flex-col">
                                        <div>
                                            {/*<p className=" text-gray-800 pb-1 capitalize">{new Date(el.date).toISOString().split('T')[0]} {el.time}</p>*/}
                                            <p className=" text-gray-800 pb-1 capitalize">{`${format(new Date(el.date), "dd-MM-yyyy")} ${el.time} - ${endTimeString(el.time, el.duration)}`}</p>
                                        </div>
                                    </div>
                                </td>
                                {/*<td className="pt-4 pb-5 w-1/6">{el.employee ? el.employee : 'Online'}</td>*/}
                                <td className="pr-2 pl-4 py-4 w-1/6">
                                    <div className="flex items-center">
                                        <div className="">
                                            <a href={`/customers/${el.customer.email}`}
                                                className=" text-gray-800 mb-1">{el.customer.firstname} {el.customer.lastname}</a>
                                            <a href={`tel:${el.customer.phone}`}
                                                className="text-xs mb-0.5 text-cyan-500 hover:underline block">{el.customer.phone}</a>
                                            {/* <a href={`/customers/${el.customer}`} className="text-xs mb-0.5 text-cyan-500 hover:underline block">+32 496 47 46 66</a> */}
                                        </div>
                                    </div>
                                </td>

                                <td className="pt-4 pb-5 text-gray-800 w-1/6">{el.serviceDetail}</td>
                                <td className="pt-4 pb-5 text-gray-800 w-1/6">{el.vessel_amount && el.vessel_amount > 0 ? <p>{el.vessel_amount + ' voertuigen'}</p> : <div><p>{el.adult} volwassenen</p><p>{el.child} kinderen</p></div> }</td>
                                <td className="pt-4 pb-5 text-gray-800 w-1/6">{validateTimeStatus(el, true)}</td>
                                <td className="pt-4 pb-5 text-gray-800 w-1/6">
                                    <ul>
                                        {el.options ? Object.entries(el.options).map(([key, value], i) => <li
                                            key={i}>{getOptionName(key) ? value : ''} {getOptionName(key)}</li>) : 'Geen'}
                                    </ul>
                                </td>
                                {/*<td className="pt-4 pb-5 text-gray-800 w-1/6">€ {el.payment.amount} | {el.payment.method} |*/}
                                {/*    <span*/}
                                {/*        // onClick={() => el.payment.status === 'te controleren' && window.open(el.voucher, '_blank')}*/}
                                {/*        className={` capitalize font-medium ${el.payment.status === 'te controleren' && 'cursor-pointer'} ${el.payment.status == 'betaald' || el.payment.status == 'goedgekeurd' ? ' text-green-500' : el.payment.status == 'in afwachting' ? ' text-yellow-500' : ' text-red-600'}`}> {el.payment.status} </span>*/}
                                {/*</td>*/}
                                <td className="px-5 py-3">
                                    <div className="flex items-center">
                                        {el.payment.status === 'geannuleerd' ? (
                                            <p className="text-red-500 text-center">Geannuleerd</p>
                                        ) : (
                                            <>
                                                <button
                                                    className="text-cyan-500 hover:text-white border border-cyan-500 rounded-md px-4 py-1 hover:bg-cyan-500 bg transition-all"
                                                    onClick={() => handleClick(index, el.id, el)}>Detail
                                                </button>
                                                <button
                                                    className="ml-2 text-red-500 hover:text-white border border-red-500 rounded-md px-4 py-1 hover:bg-red-500 bg transition-all"
                                                    onClick={() => setShowCancellation(el)}>Annuleren
                                                </button>
                                            </>
                                        )}
                                    </div>
                                </td>
                            </tr>)}
                </tbody>
            </table>
            {/* <div className="flex justify-center py-10">
                <button
                    className="transition focus:outline-none duration-150 ease-in-out border border-cyan-500 bg-cyan-500 rounded text-white px-8 py-2 text-md hover:text-cyan-500 hover:bg-gray-100 mr-4"
                    onClick={() => setUseFilter(!useFilter)}>{useFilter ? 'Alle bestellingen' : 'Laatste 7 dagen'} laden
                </button>
            </div> */}

            {showCancellation &&
                <div
                    className="fixed inset-0 bg-black bg-opacity-70 rounded-md mx-auto flex flex-col w-full h-full justify-center items-center z-50">
                    <div className=" relative bg-slate-100 py-16 px-20 rounded-2xl">
                        <p className=" text-center text-2xl mb-9 text-gray-500">{showCancellation.serviceDetail}</p>
                        <p>Bericht voor annulatie-mail</p>
                        <textarea className="p-1" cols="30" rows="5" value={cancellationReason}
                            onChange={(e) => setCancellationReason(e.target.value)} />
                        <div className="flex flex-col">
                            <button onClick={() => handleCancellation(true, showCancellation.id)}
                                className=" rounded my-1 text-lg py-2 px-5 bg-cyan-500 text-white hover:bg-cyan-600">{cancelMailButton}</button>
                            <button onClick={() => handleCancellation(false, showCancellation.id)}
                                className=" rounded my-1 text-lg py-2 px-5  bg-cyan-600 text-white hover:bg-cyan-500">{cancelButton}</button>
                            <button onClick={() => setShowCancellation(false)}
                                className=" rounded my-1 text-lg py-2 px-5 border-red-500 border text-red-500 hover:bg-red-500 mt-5 hover:text-white">Annuleren
                            </button>
                        </div>
                    </div>
                </div>}

            {activeOrder && (
                <div
                    className={`${activeOrder && activeOrder.full_customer ? 'fixed' : "hidden"} h-screen w-screen inset-0 flex flex-col justify-center items-center bg-black bg-opacity-70 z-50`}>
                    <div className="min-w-[50vw]">
                        <div className="pb-0 bg-white w-full max-h-[80vh] overflow-y-scroll rounded-tl-md rounded-tr-md block">
                            <div className="flex flex-col items-center w-full pt-7">
                                <h4 className="text-3xl font-normal leading-tight text-cyan-500">Reservatie detail</h4>
                            </div>
                            <div className="grid grid-cols-1 xl:grid-cols-2 gap-x-10 gap-y-2 py-10 px-10">
                                <div className="mb-4 mx-2">
                                    <div>
                                        <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                            Aangemaakt op
                                        </label>
                                    </div>
                                    <p>{format(new Date(activeOrder.created_at), 'dd-MM-yyyy HH:mm')}</p>
                                </div>

                                <div className="mb-4 mx-2">
                                    <div>
                                        <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                            Naam
                                        </label>
                                    </div>
                                    <a href={`/customers/${activeOrder.full_customer.email}`}>{activeOrder.full_customer.firstname + ' ' + activeOrder.full_customer.lastname}</a>
                                </div>

                                {activeOrder.vessel_amount && activeOrder.vessel_amount > 0 ? <div className="mb-4 mx-2">
                                    <div>
                                        <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                            Voertuigen
                                        </label>
                                    </div>
                                    <p>{activeOrder.vessel_amount}</p>
                                </div> : <>
                                    <div className="mb-4 mx-2">
                                        <div>
                                            <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                                Volwassenen
                                            </label>
                                        </div>
                                        <p>{activeOrder.adult}</p>
                                    </div>

                                    <div className="mb-4 mx-2">
                                        <div>
                                            <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                                Kinderen
                                            </label>
                                        </div>
                                        <p>{activeOrder.child}</p>
                                    </div>
                                </>}

                                <div className="mb-4 mx-2">
                                    <div>
                                        <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                            Dag
                                        </label>
                                    </div>
                                    <p>{format(new Date(activeOrder.date), "dd-MM-yyyy")}</p>
                                </div>

                                <div className="mb-4 mx-2">
                                    <div>
                                        <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                            Startuur
                                        </label>
                                    </div>
                                    <p>{activeOrder.time} u</p>
                                </div>

                                <div className="mb-4 mx-2">
                                    <div>
                                        <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                            Prijs
                                        </label>
                                    </div>
                                    <p>€ {activeOrder.subtotal}</p>
                                </div>

                                <div className="mb-4 mx-2">
                                    <div>
                                        <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                            Opties
                                        </label>
                                    </div>
                                    <ul>
                                        {activeOrder.options ? Object.entries(activeOrder.options).map(([key, value], i) => <li
                                            key={i}>{getOptionName(key) ? value : ''} {getOptionName(key)}</li>) : 'Geen'}
                                    </ul>
                                </div>


                                <div className="mb-4 mx-2">
                                    <div>
                                        <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                            Adres
                                        </label>
                                    </div>
                                    <p>{`${activeOrder.full_customer.street} ${activeOrder.full_customer.number}, ${activeOrder.full_customer.postal} ${activeOrder.full_customer.city}`}</p>
                                </div>

                                <div className="mb-4 mx-2">
                                    <div>
                                        <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                            Bestelnummer
                                        </label>
                                    </div>
                                    <a href={`/orders/${activeOrder.id}`}>{activeOrder.id}</a>
                                </div>

                                {/*<div className="mb-4 mx-2">*/}
                                {/*    <div>*/}
                                {/*        <label className="block text-gray-500 font-bold mb-1.5 capitalize">*/}
                                {/*            Status*/}
                                {/*        </label>*/}
                                {/*    </div>*/}
                                {/*    <div className="mb-4 mr-2">*/}
                                {/*        <Select*/}
                                {/*            onChange={(selectedOption) => updateStatus(activeOrder.id, selectedOption)}*/}
                                {/*            value={statuses.find(s => s.value === activeOrder.payment.status)}*/}
                                {/*            options={statuses}*/}
                                {/*        />*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                                <div className="mb-4 mx-2">
                                    <div>
                                        <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                            Duurtijd
                                        </label>
                                    </div>
                                    <p>{activeOrder.duration}min</p>
                                </div>

                                <div className="mb-4 mx-2">
                                    <div>
                                        <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                            Activiteit
                                        </label>
                                    </div>
                                    <p>{activeOrder.serviceDetail}</p>
                                </div>

                            </div>
                        </div>

                        <div className={`bg-slate-200 py-6 flex justify-end pr-5 rounded-br-md rounded-bl-md w-full`}>
                            {changes && (
                                <button
                                    className="transition focus:outline-none duration-150 ease-in-out border border-cyan-500 bg-cyan-500 rounded text-white px-8 py-2 text-md hover:text-cyan-500 hover:bg-gray-100 mr-4"
                                    onClick={() => saveChangedStatus()}>Status bijwerken
                                </button>
                            )}
                            <button
                                className="text-cyan-500 bg-slate-50 hover:text-white border border-cyan-500 rounded-md px-4 py-1 bg-transparent hover:bg-cyan-500 bg transition-all"
                                onClick={() => {
                                    setActiveOrder(null)
                                    contextStorage.setEditForm(false)
                                    setChanges(false)
                                }}>Sluiten
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* <Form data={activeOrder} /> */}
            {/*<FormCreate th={['Aangemaakt op', 'Geldigheidsperiode', 'Korting', 'Categorie', 'Status']} td={orders}/>*/}
        </div>

    )
}
export default Reservations
