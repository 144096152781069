import React from 'react'
import FullCalendar, {formatDate} from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import {INITIAL_EVENTS, createEventId} from './event-utils'
import axios from "axios"
import {format, addDays, setHours} from "date-fns";
import {utcToZonedTime} from 'date-fns-tz'
import _ from "lodash";

export default class DemoApp extends React.Component {

    state = {
        weekendsVisible: true,
        currentEvents: [],
        events: [],
        showLockDay: false,
        initialView: "dayGridMonth",
        selectionMode: "default",
        selection: false,
        setHoursTo: "default",
        latenight: false
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData() {

        // Preloader
        let cache = {}
        if(localStorage.getItem('preload_events')){
            cache = JSON.parse(localStorage.getItem('preload_events'))
            this.setState({events: cache})
        }
        // END Preloader

        axios.get('https://deinze-kajakt-api.vercel.app/api/orders/calendar/'+localStorage.getItem('__nn_l__')).then(res => {

            if(!_.isEqual(cache, res.data)) this.setState({events: res.data})

            localStorage.setItem('preload_events', JSON.stringify(res.data))
            console.log(res.data)
        })
    }

    setDatesHours() {
        axios.post('https://deinze-kajakt-api.vercel.app/api/calendar/multi/'+localStorage.getItem('__nn_l__'), {
            dates: this.state.selection,
            hours: this.state.setHoursTo,
            latenight: this.state.latenight,
            location: localStorage.getItem('__nn_l__')
        }).then(res => {
            // this.setState({events: res.data})
            this.fetchData()
            console.log(res.data)
        })
    }

    render() {
        return (
            <div className='demo-app'>
                {/*{this.renderSidebar()}*/}
                <div className='demo-app-main'>
                    <div className="flex">
                        <div className="my-8">
                            <h3>Interactiemodus</h3>
                            <form action="">
                                <div className="flex items-center mb-4 mt-2">
                                    <input checked={this.state.selectionMode === "default"} id="default-radio-1" type="radio" value="" name="default-radio" onChange={() => this.setState({selectionMode: "default"})}
                                           className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                    <label htmlFor="default-radio-1"
                                           className="ml-2 text-sm font-medium">Standaard</label>
                                </div>
                                <div className="flex items-center">
                                    <input checked={this.state.selectionMode === "multi"} id="default-radio-2" type="radio" value="" name="default-radio" onChange={() => this.setState({selectionMode: "multi"})}
                                           className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                    <label htmlFor="default-radio-2"
                                           className="ml-2 text-sm font-medium">Openingsuren wijzigen</label>
                                </div>
                            </form>
                        </div>
                        {this.state.selection && this.state.selectionMode === 'multi' && <div className="my-8 ml-16">
                            <h3>Kies openingsuren voor selectie</h3>
                            <form action="">
                                <div className="flex items-center mb-4 mt-2">
                                    <input checked={this.state.setHoursTo === "vacation"} id="default-radio-1" type="radio" value="" name="default-radio" onChange={() => this.setState({setHoursTo: "vacation"})}
                                           className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                    <label htmlFor="default-radio-1"
                                           className="ml-2 text-sm font-medium">Schoolvakantie</label>
                                </div>
                                <div className="flex items-center mb-4">
                                    <input checked={this.state.setHoursTo === "default"} id="default-radio-2" type="radio" value="" name="default-radio" onChange={() => this.setState({setHoursTo: "default"})}
                                           className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                    <label htmlFor="default-radio-2"
                                           className="ml-2 text-sm font-medium">Buiten schoolvakantie (standaard)</label>
                                </div>
                                <div className="flex items-center">
                                    <input checked={this.state.setHoursTo === "closed"} id="default-radio-2" type="radio" value="" name="default-radio" onChange={() => this.setState({setHoursTo: "closed"})}
                                           className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                    <label htmlFor="default-radio-2"
                                           className="ml-2 text-sm font-medium">Gesloten</label>
                                </div>
                            </form>
                        </div>}

                        {this.state.selection && this.state.selectionMode === 'multi' && <div className="flex flex-col justify-between ml-16 my-8">
                            <h3>Instellingen</h3>
                            <div className="flex items-center">
                                <input checked={this.state.latenight === true} id="default-radio-2" type="checkbox" value="" name="default-radio" onChange={(e) => this.setState({latenight: e.target.checked})}
                                       className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                <label htmlFor="default-radio-2"
                                       className="ml-2 text-sm font-medium">Late Night</label>
                            </div>
                            <button className="bg-slate-700 rounded-md p-2 text-white mt-4" onClick={this.setDatesHours.bind(this)}>Doorvoeren</button>
                        </div>}

                        {/*{this.state.selection && }*/}
                    </div>

                    {/*Event details popup*/}
                    {this.state.eventDetails && <div  tabIndex="-1"
                                                     class="fixed left-1/3 top-0 right-0 z-50 p-4">
                        <div class="relative max-w-2xl md:h-auto">
                            <div class="relative bg-white rounded-lg shadow">

                                <div className="p-6 calendar-description" dangerouslySetInnerHTML={{__html: this.state.eventDetails}} />

                                <div class="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b">
                                    <button data-modal-hide="defaultModal" type="button" onClick={() => this.setState({eventDetails: false})}
                                            className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10">Sluiten
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>}

                    <FullCalendar
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                        headerToolbar={{
                            left: 'prev,next today',
                            center: 'title',
                            right: ''
                        }}
                        initialView={this.state.initialView}
                        editable={true}
                        select={this.selectCallback}
                        timeZone={"UTC"}
                        dateClick={this.state.selectionMode === "default" ? this.handleDateSelect : false}
                        selectable={this.state.selectionMode === "multi"  ? this.handleMultiDateSelect : false}
                        events={this.state.events}
                        selectMirror={true}
                        dayMaxEvents={true}
                        firstDay={1}
                        eventStartEditable={false}
                        weekends={this.state.weekendsVisible}
                        // initialEvents={INITIAL_EVENTS} // alternatively, use the `events` setting to fetch from a feed
                        eventContent={renderEventContent} // custom render function
                        eventClick={this.handleEventClick}
                        eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
                        /* you can update a remote database when these fire:
                        eventAdd={function(){}}
                        eventChange={function(){}}
                        eventRemove={function(){}}
                        */
                    />
                </div>
            </div>
        )
    }

    renderSidebar() {
        return (
            <div className='demo-app-sidebar'>
                {/* <div className='demo-app-sidebar-section'>
          <h2>Instructions</h2>
          <ul>
            <li>Select dates and you will be prompted to create a new event</li>
            <li>Drag, drop, and resize events</li>
            <li>Click an event to delete it</li>
          </ul>
        </div> */}
                {/*<div className='demo-app-sidebar-section'>*/}
                {/*  <label>*/}
                {/*    <input*/}
                {/*      type='checkbox'*/}
                {/*      checked={this.state.weekendsVisible}*/}
                {/*      onChange={this.handleWeekendsToggle}*/}
                {/*    ></input> */}
                {/*    <span>toggle weekends</span>*/}
                {/*  </label>*/}
                {/*</div>*/}
                <div className='demo-app-sidebar-section'>
                    <h2>All Events ({this.state.currentEvents.length})</h2>
                    <ul>
                        {this.state.currentEvents.map(renderSidebarEvent)}
                    </ul>
                </div>
            </div>
        )
    }

    renderLockDayModal() {
        if (this.state.showLockDay) {
            return <button className="bg-slate-700 rounded-md p-2 text-white"
                           onClick={() => this.closeDay(this.state.showLockDay, true)}>{format(new Date(this.state.showLockDay), 'dd-MM-yyyy')} sluiten</button>
        }
    }

    closeDay(date, locked) {
        axios.post("https://deinze-kajakt-api.vercel.app/api/calendar/"+localStorage.getItem('__nn_l__'), {
            time_string: new Date(date).getTime(),
            hours: locked ? "closed" : "default"
        }).then((r) => {
            console.log(r)
            this.setState({showLockDay: false})
            this.fetchData()
        }).catch(e => console.log(e))
    }

    handleWeekendsToggle = () => {
        this.setState({
            weekendsVisible: !this.state.weekendsVisible
        })
    }

    selectCallback = (selection) => {
        console.log('----')
        console.log(selection)
        // console.log(selection.start)
        console.log(format(selection.start, "dd-MM-yyyy"))
        console.log(selection.start)
        console.log(utcToZonedTime(new Date(selection.start), 'Europe/Brussels'))
        console.log(selection.end)
        console.log(addDays(selection.start, 1))
        console.log(format(addDays(selection.start, 1), 'dd-MM-yyyy') === format(selection.end, 'dd-MM-yyyy') ? "ONE DAY" : "MULTIPLE DAYS")

        let currentDate = selection.start
        const dates = []
        while(format(currentDate, "dd-MM-yyyy") !== format(selection.end, "dd-MM-yyyy")){
            dates.push(format(currentDate, 'dd-MM-yyyy'))
            currentDate = addDays(currentDate, 1)
        }
        console.log('DONE')
        console.log(dates) // This is the functional output, now add functionality to it (change hours to holiday, regular or closed) and send to server
        this.setState({selection: dates})
    }

    handleDateSelect = (selectInfo) => {
        console.log("hello")
        console.log(selectInfo)
        console.log(format(new Date(selectInfo.date), 'dd-MM-yyyy'))
        this.setState({showLockDay: selectInfo.date.toString(), initialView: "timeGridDay"})
        // let title = prompt('Please enter a new title for your event')
        let calendarApi = selectInfo.view.calendar

        calendarApi.gotoDate(selectInfo.date)
        calendarApi.changeView("timeGridDay")
        calendarApi.unselect() // clear date selection
        //
        // if (title) {
        //   calendarApi.addEvent({
        //     id: createEventId(),
        //     title,
        //     start: selectInfo.startStr,
        //     end: selectInfo.endStr,
        //     allDay: selectInfo.allDay
        //   })
        // }
    }

    handleMultiDateSelect = (selectInfo) => {
        console.log("hello")
        console.log(selectInfo)
    }

    handleEventClick = (clickInfo) => {
        if(this.state.selectionMode === 'multi') return
        console.log(clickInfo)
        if (clickInfo.event._def.title === 'Gesloten') {
            if (window.confirm(`Wilt u de sluiting van ${format(new Date(clickInfo.event._instance.range.start), 'dd-MM-yyyy')} opheffen?\nHierdoor zullen de standaard-openingsuren voor ${localStorage.getItem('__nn_l__')} van toepassing zijn op deze dag.`)) {
                this.closeDay(new Date(clickInfo.event._instance.range.start), false)
            }
        }else{
            this.setState({eventDetails: clickInfo.event._def.extendedProps.description})
        }
    }

    handleEvents = (events) => {
        this.setState({
            currentEvents: events
        })
    }

}

function renderEventContent(eventInfo) {
    // console.log(eventInfo)
    const parsedDate = eventInfo.event._instance.range.start
    // console.log(parsedDate)
    // parsedDate.setMinutes(parsedDate.getMinutes() - parsedDate.getTimezoneOffset()) // Parsing TimeZone offset, not yet DST

    return (
        <div style={{
            backgroundColor: eventInfo.event._def.ui.backgroundColor,
            padding: '2px 8px',
            borderRadius: '3px',
            color: eventInfo.event._def.ui.textColor,
            width: '100%',
            textAlign: 'center'
        }}>
            {eventInfo.event.title !== 'Gesloten' && eventInfo.event.title !== 'Schoolvakantie' && eventInfo.event.title !== 'Schoolvakantie (Late Night)' && eventInfo.event.title !== 'Late Night' &&
            <b>{format(parsedDate, 'HH:mm')}</b>}
            &nbsp;
            <i>{eventInfo.event.title}</i>
        </div>
    )
}

function renderSidebarEvent(event) {
    return (
        <li key={event.id}>
            <b>{format(event.start, 'dd-MM-yy')}</b>&nbsp;
            <i>{event.title}</i>
        </li>
    )
}
