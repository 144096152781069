import React, {useState, useEffect} from "react";
import MultiAxis from "../components/MultiAxis.jsx";
import axios from "axios"
import BarChart from "../components/BarChart.jsx";
import BarChartMoney from "../components/BarChartMoney";

const Analytics = () => {

    const [lastMonth, setLastMonth] = useState(false)
    const [lastWeek, setLastWeek] = useState(false)
    const [lastYear, setLastYear] = useState(false)

    useEffect(() => {
        // Last month
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/get-data/${localStorage.getItem('__nn_l__')}`).then(res => {
            setLastMonth(res.data)
            console.log('res', res)
        })

        // Last week
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/get-last-week/${localStorage.getItem('__nn_l__')}`).then(res => {
            setLastWeek(res.data)
            console.log(res)
        })

        // Years monthly
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/get-years-monthly/${localStorage.getItem('__nn_l__')}`).then(res => {
            setLastYear(res.data)
            console.log(res)
        })
    }, [])

    return (
        <div>
            <div
                className="mx-auto flex flex-col md:flex-row items-start md:items-center justify-between py-10 mb-2 bg-slate-200 rounded-md px-6">
                <h4 className="text-3xl font-bold leading-tight text-gray-800 ">Analytics</h4>
                <div className="mt-6 md:mt-0">
                    {/*<button*/}
                    {/*    className="mr-3 focus:outline-none transition duration-150 ease-in-out rounded hover:bg-cyan-500 hover:text-white text-cyan-500 border-cyan-500 border px-5 py-2 text-md">Back*/}
                    {/*</button>*/}
                    {/* <button className="transition focus:outline-none duration-150 ease-in-out border border-cyan-500 bg-cyan-500 rounded text-white px-8 py-2 text-md hover:text-cyan-500 hover:bg-gray-100">Reservatie aanmaken</button> */}
                </div>
            </div>
            {lastMonth && <div style={{height: '50vh'}}>
                <MultiAxis lastMonth={lastMonth}/>
            </div>}
            <div className="my-20"></div>
            <div className="flex w-full flex-wrap">
                <div className="w-1/2">
                    {lastWeek && <BarChart lastWeek={lastWeek}/>}
                </div>
                <div className="w-1/2">
                    {lastYear && <BarChartMoney data={lastYear}/>}
                </div>
            </div>
        </div>
    )
}
export default Analytics
