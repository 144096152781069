import React, { useEffect, useState, useContext } from "react";
import axios from 'axios';
import 'tw-elements';
import { SiteContext } from "../contexts/SiteContext"
import Form from "../components/Form";
import { addMinutes, format } from 'date-fns'
import DeletePopup from "../components/DeletePopup";


const Vessels = () => {
    const contextStorage = useContext(SiteContext)
    const [vessels, setVessels] = useState(false)
    const [vesselObjs, setVesselObjs] = useState([]);
    const [itemIndex, setItemIndex] = useState();
    const [activeOrder, setActiveOrder] = useState();
    const [formData, setFormData] = useState({});
    const [createForm, setCreateForm] = useState();
    const [addFormSaveButton, setAddFormSaveButton] = useState('Toevoegen')
    const [itemID, setItemID] = useState();

    useEffect(() => {
        console.log(SiteContext)
    }, []);

    const getVessels = async () => {
        const res = await axios.get(`https://deinze-kajakt-api.vercel.app/api/vessels/`)

        const filtered = res.data.filter(option => !option.soft_deleted)
        filtered.sort((a, b) => b.date - a.date)

        setVessels(filtered)
    }

    useEffect(() => {
        getVessels()
    }, []);

    useEffect(() => {
        vessels && vessels.map(vessel => setVesselObjs(vesselObjs => [...vesselObjs, vessel]))
        console.log(vessels)
    }, [vessels]);

    useEffect(() => {
        if (contextStorage.tmpData) {
            // console.log(contextStorage.tmpData)
            // console.log('RAN')
            axios.post(process.env.REACT_APP_BASE_URL + "/api/vessels/update", contextStorage.tmpData, { headers: { 'content-type': 'application/json' } }).then(res => {
                console.log(res)
                contextStorage.setTmpData(false)
                contextStorage.setEditForm(false)
                getVessels()
            }).catch(err => console.log(err))
        }
    }, [contextStorage.tmpData]);

    const handleSave = async () => {
        console.log('handleSave')

        const fd = {}
        fd['name'] = formData.name
        fd['inventory_dentergem'] = formData.inventory_dentergem
        fd['inventory_deinze'] = formData.inventory_deinze
        fd['capacity'] = formData.capacity
        fd['whole_vessel'] = formData.whole_vessel

        console.log(formData)
        setAddFormSaveButton('Even geduld...')
        axios.post(process.env.REACT_APP_BASE_URL + '/api/vessels/add', fd, {
            'content-type': 'application/json'
        }).then(r => {
            getVessels()
            setAddFormSaveButton('Toevoegen')
            setCreateForm(false)
        }).catch(e => console.log(e))
    }

    const deleteVessel = async (id) => {
        setItemID(id)
        contextStorage.setWarning(true)
    }

    useEffect(() => {
        contextStorage.continueDelete ?
            axios.get(process.env.REACT_APP_BASE_URL + '/api/vessels/rm/' + itemID)
                .then(r => {
                    getVessels()
                    setCreateForm(false)
                    contextStorage.setDeletePending(true)
                    setTimeout(() => {
                        contextStorage.setWarning(false)
                        contextStorage.setDeletePending(false)
                        contextStorage.setContinueDelete(false)
                    }, 1000);
                })
                .catch(e => {
                    contextStorage.setDeletePending(true)
                    contextStorage.setWarningMessage("Verwijderen lukt niet")
                    setTimeout(() => {
                        contextStorage.setDeletePending(false)
                        contextStorage.setContinueDelete(false)
                    }, 1000);
                })
            : contextStorage.setDeletePending(false)
    }, [contextStorage.continueDelete]);

    const handleClick = (index, id) => {
        contextStorage.setEditForm(true)
        setFormData({ ...formData, id })
        setItemIndex(index)
    }

    useEffect(() => {
        setActiveOrder(vesselObjs[itemIndex])
    }, [itemIndex]);

    const endTimeString = (time, duration) => {
        const timesplit = time.split(':')
        const starttime = new Date()
        starttime.setHours(timesplit[0])
        starttime.setMinutes(timesplit[1])
        const endtime = addMinutes(starttime, duration)
        console.log(starttime)
        console.log(endtime)
        const starthour = starttime.getHours().toString().length === 2 ? starttime.getHours() : `${starttime.getHours().toString()}0`
        const startminutes = starttime.getMinutes().toString().length === 2 ? starttime.getMinutes() : `${starttime.getMinutes().toString()}0`
        const endhour = endtime.getHours().toString().length === 2 ? endtime.getHours() : `${endtime.getHours().toString()}0`
        const endminutes = endtime.getMinutes().toString().length === 2 ? endtime.getMinutes() : `${endtime.getMinutes().toString()}0`
        console.log(`${endtime.getMinutes().toString()}0`)
        const string = `${endhour}:${endminutes}`
        return string
    }

    return (
        <div className="mx-auto overflow-x-auto">
            <div
                className="mx-auto flex flex-col md:flex-row items-start md:items-center justify-between py-10 mb-2 bg-slate-200 rounded-md px-6">
                <h4 className="text-3xl font-bold leading-tight text-gray-800 ">Capaciteit</h4>
                <div className="mt-6 md:mt-0">
                    {/*<button className="mr-3 focus:outline-none transition duration-150 ease-in-out rounded hover:bg-cyan-500 hover:text-white text-cyan-500 border-cyan-500 border px-5 py-2 text-md">Back</button>*/}
                    <button
                        className="transition focus:outline-none duration-150 ease-in-out border border-cyan-500 bg-cyan-500 rounded text-white px-8 py-2 text-md hover:text-cyan-500 hover:bg-gray-100"
                        onClick={() => setCreateForm(true)}>Nieuw aanmaken
                    </button>
                </div>
            </div>
            <table className="w-full shadow text-left bg-slate-50 rounded-lg py-24">
                <thead className="bg-slate-200 w-full">
                    <tr className="border-b border-slate-200">
                        <th className="py-5 pl-2 sm:pl-10 w-1/6 text-base text-gray-800">Naam</th>
                        <th className="py-5 w-1/6 text-base  text-gray-800">Aantal (Deinze)</th>
                        <th className="py-5 w-1/6 text-base  text-gray-800">Aantal (Dentergem)</th>
                        {/*<th className="py-5 w-1/6 text-base  text-gray-800">In gebruik</th>*/}
                        <th className="py-5 w-1/6 text-base  text-gray-800">Aantal personen</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {vessels && vessels.length == 0 || vessels == undefined ?
                        <h2 className=" pl-10 text-4xl py-12 w-full whitespace-nowrap">Capaciteit wordt opgehaald</h2>
                        :
                        vessels && vessels.map((el, index) =>
                            <tr className="border-b border-slate-200">
                                <td className="pl-2 sm:pl-10 pr-2 py-4 w-1/6">
                                    <div className=" flex flex-col">
                                        <div>
                                            {/*<p className=" text-gray-800 pb-1 capitalize">{new Date(el.date).toISOString().split('T')[0]} {el.time}</p>*/}
                                            <p className="text-gray-800 pb-1 capitalize">{el.name}</p>
                                        </div>
                                    </div>
                                </td>
                                <td className="pt-4 pb-5 w-1/6">{el['inventory_deinze']}</td>
                                <td className="pt-4 pb-5 w-1/6">{el['inventory_dentergem']}</td>
                                {/*<td className="pr-2 py-4 w-1/6">12</td>*/}

                                <td className="pt-4 pb-5 text-gray-800 w-1/6">{el.capacity}</td>

                                <td className=" px-5 py-3">
                                    <div className="flex justify-end">
                                        <button
                                            className="text-cyan-500 hover:text-white border border-cyan-500 rounded-md px-4 py-1 hover:bg-cyan-500 bg transition-all mr-4"
                                            onClick={() => handleClick(index, el.id)}>Aanpassen
                                        </button>
                                        <button
                                            className="text-red-500 hover:text-white border border-red-500 rounded-md px-4 py-1 hover:bg-red-500 bg transition-all"
                                            onClick={() => deleteVessel(el.id)}>Verwijderen
                                        </button>
                                    </div>
                                </td>
                            </tr>)}
                </tbody>
            </table>

            {formData && <Form data={activeOrder} clear={() => setFormData(false)} customFields={[
                {type: 'number', name: 'inventory_dentergem', label: 'Inventaris Dentergem'}
            ]} />}

            <div
                className={` ${createForm ? "fixed" : "hidden "} fixed h-screen w-screen inset-0 flex flex-col justify-center items-center bg-black bg-opacity-70 z-50`}>
                <div
                    className="dynamic-width pb-0 bg-white min-w-[50vw] max-h-[80vh] overflow-y-scroll rounded-tl-md rounded-tr-md block">
                    <div className="flex flex-col items-center w-full pt-7">
                        <h4 className="text-3xl font-normal text-cyan-500">Aanmaken</h4>
                    </div>
                    <div className="grid grid-cols-2 gap-x-10 gap-y-2 py-10 px-10">

                        <div className="mb-4 mx-2">
                            <div>
                                <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                    Naam
                                </label>
                            </div>
                            <div className="">
                                <input
                                    className="bg-gray-100 appearance-none border-2 border-gray-100 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                    id="inline-full-name" type="text"
                                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                    value={formData.name} />
                            </div>
                        </div>

                        <div className="mb-4 mx-2">
                            <div>
                                <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                    Aantal personen
                                </label>
                            </div>
                            <div className="">
                                <input
                                    className="bg-gray-100 appearance-none border-2 border-gray-100 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                    id="inline-full-name" type="number"
                                    onChange={(e) => setFormData({ ...formData, capacity: e.target.value })}
                                    value={formData.capacity} />
                            </div>
                        </div>

                        <div className="mb-4 mx-2">
                            <div>
                                <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                    Inventaris Dentergem
                                </label>
                            </div>
                            <div className="">
                                <input
                                    className="bg-gray-100 appearance-none border-2 border-gray-100 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                    id="inline-full-name" type="number"
                                    onChange={(e) => setFormData({ ...formData, inventory_dentergem: e.target.value })}
                                    value={formData.inventory_dentergem} />
                            </div>
                        </div>

                        <div className="mb-4 mx-2">
                            <div>
                                <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                    Inventaris Deinze
                                </label>
                            </div>
                            <div className="">
                                <input
                                    className="bg-gray-100 appearance-none border-2 border-gray-100 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                    id="inline-full-name" type="number"
                                    onChange={(e) => setFormData({ ...formData, inventory_deinze: e.target.value })}
                                    value={formData.inventory_deinze} />
                            </div>
                        </div>

                        {/*<div className="mb-4 mx-2">*/}
                        {/*    <div>*/}
                        {/*        <label className="block text-gray-500 font-bold mb-1.5 capitalize">*/}
                        {/*            Volledig voertuig per boeking*/}
                        {/*        </label>*/}
                        {/*    </div>*/}
                        {/*    <div className="">*/}
                        {/*        <input*/}
                        {/*            // className="bg-gray-100 appearance-none border-2 border-gray-100 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"*/}
                        {/*            id="inline-full-name" type="checkbox"*/}
                        {/*            onChange={(e) => setFormData({...formData, whole_vessel: e.target.value})}*/}
                        {/*            value={formData.whole_vessel}/>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                    </div>
                    <div className={`bg-slate-200 py-6 flex justify-end pr-5 rounded-br-md rounded-bl-md`}>
                        <button
                            className="bg-cyan-500 focus:outline-none transition duration-150 ease-in-out hover:bg-cyan-600 rounded text-white px-8 py-2 text-sm mr-4"
                            onClick={() => handleSave()}>{addFormSaveButton}
                        </button>
                        <button
                            className="text-cyan-500 bg-slate-50 hover:text-white border border-cyan-500 rounded-md px-4 py-1 bg-transparent hover:bg-cyan-500 bg transition-all"
                            onClick={() => setCreateForm(false)}>Sluiten
                        </button>
                    </div>
                </div>
            </div>
            <DeletePopup />
        </div>

    )
}
export default Vessels
