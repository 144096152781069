
import DemoApp from "../components/Calendar/react/src/DemoApp"

export default function Calendar() {
    return (
        <>
            <div className="mx-auto flex flex-col md:flex-row items-start md:items-center justify-between py-10 mb-2 bg-slate-200 rounded-md px-6">
                <h4 className="text-3xl font-bold leading-tight text-gray-800 ">Kalender</h4>
                <div className="mt-6 md:mt-0">
                    {/*<button className="mr-3 focus:outline-none transition duration-150 ease-in-out rounded hover:bg-cyan-500 hover:text-white text-cyan-500 border-cyan-500 border px-5 py-2 text-md">Back</button>*/}
                </div>
            </div>
            <DemoApp />
        </>
    )
}