import React, { useState, useEffect, useContext } from "react";
import Logo from './../assets/images/logo/logo.svg'
import LogoDentergem from './../assets/images/logo/logodentergem.png'

import { BsCardChecklist, BsCalendarDate, BsGraphUp } from 'react-icons/bs'
import { CiDiscount1 } from 'react-icons/ci'
import { MdOutlineDirectionsBoat, MdOutlinePointOfSale } from 'react-icons/md'
import { CiSettings } from 'react-icons/ci'
import { FiUsers } from 'react-icons/fi'
import { IoIosColorFilter, IoMdPeople } from 'react-icons/io'
import { Link } from "react-router-dom";
import { IoLocationSharp } from 'react-icons/io5';
import { TbSpeedboat } from 'react-icons/tb'
import { FaVest } from 'react-icons/fa'
import { SiteContext } from "../contexts/SiteContext"

export default function IndexPage(props) {
    const [show, setShow] = useState(false);
    const [profile, setProfile] = useState(false);
    const [activeLink, setActiveLink] = useState(localStorage.getItem('menuIndex'));
    const [menu, setMenu] = useState(false);
    const [menu1, setMenu1] = useState(false);
    const [menu2, setMenu2] = useState(false);
    const [menu3, setMenu3] = useState(false);
    const contextStorage = useContext(SiteContext)


    const pagesObjs = [
        { page: "Bestellingen", url: "/orders", icon: <MdOutlinePointOfSale className="text-xl" /> },
        { page: "Reservaties", url: "/reservations", icon: <BsCardChecklist className="text-xl" /> },
        { page: "Analytics", url: "/analytics", icon: <BsGraphUp className="text-xl" /> },
        { page: "Kalender", url: "/calendar", icon: <BsCalendarDate className="text-xl" />, end: true },
        { page: "Diensten", url: "/services", icon: <MdOutlineDirectionsBoat className="text-xl" /> },
        { page: "Categorieën", url: "/categories", icon: <IoIosColorFilter className="text-xl" /> },
        { page: "Capaciteit", url: "/vessels", icon: <TbSpeedboat className="text-xl" /> },
        { page: "Opties", url: "/options", icon: <FaVest className="text-xl" /> },
        { page: "Klanten", url: "/customers", icon: <IoMdPeople className="text-xl" /> },
        { page: "Kortingen", url: "/discounts", icon: <CiDiscount1 className="text-xl" /> },
        { page: "Settings", url: "/settings", icon: <CiSettings className="text-xl" /> },
        { page: "Gebruikers", url: "/users", icon: <FiUsers className="text-xl" /> }
    ]
    const regularUserPagesObjs = [
        { page: "Bestellingen", url: "/orders", icon: <MdOutlinePointOfSale className="text-xl" /> },
        { page: "Reservaties", url: "/reservations", icon: <BsCardChecklist className="text-xl" /> },
        // { page: "Analytics", url: "/analytics", icon: <BsGraphUp className="text-xl" /> },
        { page: "Kalender", url: "/calendar", icon: <BsCalendarDate className="text-xl" /> },
        // { page: "Diensten", url: "/services", icon: <MdOutlineDirectionsBoat className="text-xl" /> },
        // { page: "Categorieën", url: "/categories", icon: <IoIosColorFilter className="text-xl" /> },
        // { page: "Vaartuigen", url: "/vessels", icon: <TbSpeedboat className="text-xl" /> },
        // { page: "Opties", url: "/options", icon: <FaVest className="text-xl" /> },
        { page: "Klanten", url: "/customers", icon: <IoMdPeople className="text-xl" /> },
        // { page: "Kortingen", url: "/discounts", icon: <CiDiscount1 className="text-xl" /> },
        // { page: "Settings", url: "/settings", icon: <CiSettings className="text-xl" /> },
        // { page: "Gebruikers", url: "/users", icon: <FiUsers className="text-xl" /> }
    ]

    useEffect(() => {
        localStorage.setItem('menuIndex', activeLink)
    }, [activeLink]);

    const signOut = () => {
        if (window.location.pathname !== "/") localStorage.setItem('__nn_rf__', window.location.pathname)
        localStorage.removeItem('__nn_at__')
        localStorage.removeItem('__nn_e__')
        window.location = '/'
    }

    const toggleLocation = () => {
        localStorage.setItem('__nn_l__', localStorage.getItem('__nn_l__') == 'deinze' ? 'dentergem' : 'deinze')
        window.location.reload()
    }

    return (
        <>
            <div className="w-full h-full bg-slate-100">
                <div className="flex flex-no-wrap">
                    {/* Sidebar starts */}
                    {window.location.pathname !== '/signin' && <>
                        <div
                            className="fixed left-0 top-0 w-64 min-h-screen h-full shadow bg-slate-100 hidden lg:block">
                            <div className="h-16 w-full flex items-center px-8 mt-8 mb-3">
                                <a href="/orders">{localStorage.getItem('__nn_l__') == "deinze" ?
                                    <img src={Logo} alt="logo"
                                        className=' mx-4 mix-blend-exclusion w-32' /> : <img src={LogoDentergem} alt="logo Dentergem" className=' mx-4 mix-blend-exclusion w-32' />}
                                </a>
                            </div>
                            <ul aria-orientation="vertical" className=" py-6">
                                {localStorage.getItem('__nn_ud__') && JSON.parse(localStorage.getItem('__nn_ud__')).role === 'administrator' ? pagesObjs.map((el, i) => <Link to={el.url}>
                                    <>
                                        <li onClick={() => setActiveLink(i)}
                                            className={`pl-6 cursor-pointer text-gray-600 text-sm leading-3 tracking-normal pb-4 pt-5 my-1 mx-2 rounded-md hover:bg-slate-200 hover:text-cyan-600 transition-all ${i == activeLink ? 'text-cyan-600 bg-slate-200' : ''}`}>
                                            <div className="flex items-center">
                                                {el.icon}
                                                <span className="ml-2">{el.page} </span>
                                            </div>

                                        </li>
                                        {el.end && <hr/>}
                                    </>
                                </Link>) : regularUserPagesObjs.map((el, i) => <Link to={el.url}>
                                    <li onClick={() => setActiveLink(i)}
                                        className={`pl-6 cursor-pointer text-gray-600 text-sm leading-3 tracking-normal pb-4 pt-5 my-1 mx-2 rounded-md hover:bg-slate-200 hover:text-cyan-600 transition-all ${i == activeLink ? 'text-cyan-600 bg-slate-200' : ''}`}>
                                        <div className="flex items-center">
                                            {el.icon}
                                            <span className="ml-2">{el.page} </span>
                                        </div>

                                    </li>
                                </Link>)}

                                <hr/>

                                {/*Gent/Deinze Toggler*/}
                                <li
                                    className={`pl-6 cursor-pointer text-gray-600 text-sm leading-3 tracking-normal pb-5 pt-5 my-1.5 mx-2 rounded-md hover:bg-slate-200 hover:text-cyan-600 transition-all`}
                                    onClick={() => toggleLocation()}>

                                    <div className="flex items-center">
                                        <IoLocationSharp className="text-xl" />
                                        <div className="flex flex-col pl-2">
                                            <span
                                                className="font-bold mb-1 text-md">{localStorage.getItem('__nn_l__') ? localStorage.getItem('__nn_l__').toUpperCase() : ''}</span>
                                            <button>Wijzig locatie</button>
                                        </div>
                                    </div>

                                </li>

                            </ul>
                        </div>
                        {/*Mobile responsive sidebar*/}
                        <div
                            className={` overflow-y-hidden ${show ? "w-full h-full absolute z-40  transform  translate-x-0" : "w-full h-full absolute z-40 transform -translate-x-full"}`}
                            id="mobile-nav">
                            <div className="bg-gray-800 opacity-50 absolute h-full w-full lg:hidden overflow-y-hidden"
                                onClick={() => setShow(!show)} />
                            <div
                                className="absolute z-40 sm:relative w-64 md:w-96 shadow pb-4 bg-gray-100 lg:hidden transition duration-150 ease-in-out h-full">
                                <div className="flex flex-col justify-between h-full w-full overflow-y-scroll">
                                    <div>
                                        <div className="flex items-center justify-between px-8">
                                            <div className="h-16 w-full flex items-center">
                                                <a href="/orders">{localStorage.getItem('__nn_l__') == "deinze" ?
                                                    <img src={Logo} alt="logo"
                                                        className=' mx-4 mix-blend-exclusion w-32' /> : "Dentergem"}</a>
                                            </div>
                                            <div id="closeSideBar"
                                                className="flex items-center justify-center h-10 w-10"
                                                onClick={() => setShow(!show)}>
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    className="icon icon-tabler icon-tabler-x" width={20} height={20}
                                                    viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                                                    fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" />
                                                    <line x1={18} y1={6} x2={6} y2={18} />
                                                    <line x1={6} y1={6} x2={18} y2={18} />
                                                </svg>
                                            </div>
                                        </div>
                                        <ul aria-orientation="vertical" className=" py-6">
                                            {pagesObjs.map((el, i) => <Link to={el.url}>
                                                <li
                                                    className={`pl-6 cursor-pointer text-gray-600 text-sm leading-3 tracking-normal pb-4 pt-5 my-1 mx-2 rounded-md hover:bg-slate-200 hover:text-cyan-600 transition-all ${i == activeLink ? 'text-cyan-600 bg-slate-200' : ''}`}>
                                                    <div className="flex items-center" onClick={() => setActiveLink(i)}>
                                                        {el.icon}
                                                        <span className="ml-2">{el.page}</span>
                                                    </div>
                                                    {el.end && <p>ss</p>}
                                                </li>
                                            </Link>)}

                                            {/*Gent/Deinze Toggler*/}
                                            <li
                                                className={`pl-6 cursor-pointer text-gray-600 text-sm leading-3 tracking-normal pb-5 pt-5 my-1.5 mx-2 rounded-md hover:bg-slate-200 hover:text-cyan-600 transition-all`}
                                                onClick={() => toggleLocation()}>

                                                <div className="flex items-center">
                                                    <IoLocationSharp className="text-xl" />
                                                    <div className="flex flex-col pl-2 items-start">
                                                        <span
                                                            className="font-bold mb-1 text-md">{localStorage.getItem('__nn_l__') ? localStorage.getItem('__nn_l__').toUpperCase() : ''}</span>
                                                        <button>Wijzig locatie</button>
                                                    </div>
                                                </div>

                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>}
                    {/* Mobile responsive sidebar */}
                    {/* Sidebar ends */}
                    <div className="w-full">
                        {/* Navigation starts */}
                        {window.location.pathname !== '/signin' && <>
                            <nav
                                className=" lg:ml-64 h-16 flex items-center lg:items-stretch justify-end lg:justify-between bg-slate-200 shadow relative z-10">
                                <div className="hidden lg:flex w-full pr-6">
                                    <div className="w-1/2 h-full hidden lg:flex items-center pl-6 pr-24">
                                        {(window.location.pathname === "/orders" || window.location.pathname === "/customers") && (
                                            <div className="relative w-full">
                                                <div className="text-gray-500 absolute ml-4 inset-0 m-auto w-4 h-4">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        className="icon icon-tabler icon-tabler-search" width={16}
                                                        height={16}
                                                        viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                                                        fill="none"
                                                        strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" />
                                                        <circle cx={10} cy={10} r={7} />
                                                        <line x1={21} y1={21} x2={15} y2={15} />
                                                    </svg>
                                                </div>
                                                <input
                                                    className="border border-gray-100 focus:outline-none focus:border-indigo-700 rounded w-full text-sm text-gray-500 bg-gray-100 pl-12 py-2"
                                                    type="text" placeholder="Search"
                                                    onChange={(e) => contextStorage.setSearch(e.target.value)}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className="w-1/2 hidden lg:flex">
                                        <div className="w-full flex items-center pl-8 justify-end">
                                            <div className="flex items-center relative cursor-pointer"
                                                onClick={() => setProfile(!profile)}>
                                                <div className="rounded-full">
                                                    {profile ? (
                                                        <ul className="p-2 w-full border-r bg-white absolute rounded left-0 shadow mt-12 sm:mt-16 ">
                                                            <li className="flex w-full justify-between text-gray-600 hover:text-indigo-700 cursor-pointer items-center mt-2">
                                                                <div className="flex items-center">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        className="icon icon-tabler icon-tabler-logout"
                                                                        width={20} height={20} viewBox="0 0 24 24"
                                                                        strokeWidth="1.5" stroke="currentColor"
                                                                        fill="none"
                                                                        strokeLinecap="round" strokeLinejoin="round">
                                                                        <path stroke="none" d="M0 0h24v24H0z" />
                                                                        <path
                                                                            d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
                                                                        <path d="M7 12h14l-3 -3m0 6l3 -3" />
                                                                    </svg>
                                                                    <span className="text-sm ml-2"
                                                                        onClick={() => signOut()}>Sign out</span>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                                <p className="text-gray-800 text-sm mx-3">{localStorage.getItem('__nn_u__')}</p>
                                                <div className="cursor-pointer text-gray-600">
                                                    <svg aria-haspopup="true" xmlns="http://www.w3.org/2000/svg"
                                                        className="icon icon-tabler icon-tabler-chevron-down"
                                                        width={20}
                                                        height={20} viewBox="0 0 24 24" strokeWidth="1.5"
                                                        stroke="currentColor" fill="none" strokeLinecap="round"
                                                        strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" />
                                                        <polyline points="6 9 12 15 18 9" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-gray-600 mr-8 visible lg:hidden relative"
                                    onClick={() => setShow(!show)}>
                                    {show ? (
                                        " "
                                    ) : (
                                        <svg aria-label="Main Menu" aria-haspopup="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="icon icon-tabler icon-tabler-menu cursor-pointer" width={30}
                                            height={30} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                                            fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" />
                                            <line x1={4} y1={8} x2={20} y2={8} />
                                            <line x1={4} y1={16} x2={20} y2={16} />
                                        </svg>
                                    )}
                                </div>
                            </nav>
                        </>}
                        {/* Navigation ends */}
                        {/* Remove class [ h-64 ] when adding a card block */}
                        <div className=" mx-auto lg:py-10 px-2 lg:px-6 lg:ml-44 px">
                            {/* Remove class [ border-dashed border-2 border-gray-300 ] to remove dotted border */}
                            <div className="w-full min-h-screen lg:pl-44">{props.children}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
