import React, { useContext, useEffect } from "react";
import { SiteContext } from "../contexts/SiteContext"

const DeletePopup = () => {
  const contextStorage = useContext(SiteContext)

  // useEffect(() => {
  //   console.log(contextStorage.deletePending)
  // }, []);

  const handleClick = () => {
    contextStorage.setWarning(false)
    contextStorage.setWarningMessage('')
  }

  return (
    contextStorage.warning &&
    <div className=" bg-red-900 bg-opacity-50 fixed inset-0 h-full w-full flex justify-center items-center z-50">
      <div className="py-12 px-7 flex flex-col justify-center items-center bg-red-50 rounded-lg w-full max-w-xl">
        <h3 className="text-2xl font-medium mb-3 text-red-500 ">{contextStorage.warningMessage ? "Er ging iets mis" : contextStorage.deletePending ? "Even geduld" : "Opgelet!"} </h3>
        <p className="mb-9 text-lg font-medium">{contextStorage.warningMessage ? contextStorage.warningMessage : contextStorage.deletePending ? "Item wordt verwijderd" : "Bent u zeker dat u verder wil gaan met verwijderen?"} </p>
        <div className="flex">
          {!contextStorage.warningMessage && <button onClick={() => contextStorage.setContinueDelete(true)} className="hover:text-red-700 hover:bg-slate-50 text-white border border-red-700 rounded-md px-4 py-1 bg-transparent bg-red-700 bg transition-all">Verwijderen</button>}
          <div className="mx-2"></div>
          <button onClick={handleClick} className="text-cyan-500 bg-slate-50 hover:text-white border border-cyan-500 rounded-md px-4 py-1 bg-transparent hover:bg-cyan-500 bg transition-all">Sluiten</button>
        </div>
      </div>
    </div>

  )
};
export default DeletePopup;
