import React, { useEffect, useState, useContext } from "react";
import axios from 'axios';
import Form from "../components/Form";
import { SiteContext } from "../contexts/SiteContext"
import FormCreate from "../components/FormCreate";
import DatePicker from "react-datepicker";
import Select from "react-select";
import DeletePopup from "../components/DeletePopup";
import _ from "lodash";

const Categories = () => {
    const [services, setServices] = useState()
    const [serviceObjs, setServiceObjs] = useState([]);
    const contextStorage = useContext(SiteContext)
    const [itemIndex, setItemIndex] = useState();
    const [activeService, setActiveService] = useState();
    const [createForm, setCreateForm] = useState();
    const [formData, setFormData] = useState({});
    const [file, setFile] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const [itemID, setItemID] = useState();

    const getServices = async () => {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/categories`)
        setServices(res.data.sort((a, b) => (parseInt(a.sort) > parseInt(b.sort)) ? 1 : ((parseInt(b.sort) > parseInt(a.sort)) ? -1 : 0)))
    }

    useEffect(() => {
        getServices()
    }, []);

    useEffect(() => {
        services && services.map(order => setServiceObjs(serviceObjs => [...serviceObjs, order]))
    }, [services]);

    const handleClick = (category) => {
        contextStorage.setEditForm(true)
        setFormData({ ...formData, id: category.id })
        setActiveService(category)
    }

    useEffect(() => {
        if (contextStorage.tmpData == {} || !contextStorage.tmpData) return
        console.log(contextStorage.tmpData)
        const fd = new FormData()
        fd.append('name', contextStorage.tmpData.name)
        fd.append('img', contextStorage.tmpData.img)
        fd.append('sort', contextStorage.tmpData.sort)
        fd.append('id', contextStorage.tmpData.id)
        fd.append('active', contextStorage.tmpData.active)
        fd.append('redirect', contextStorage.tmpData.redirect)
        fd.append('beschrijving', contextStorage.tmpData.beschrijving)
        fd.append('locations', JSON.stringify(contextStorage.tmpData.locations))
        axios.post(process.env.REACT_APP_BASE_URL + "/api/categories", fd, {
            'content-type': file.type || 'application/json',
            'content-length': `${file.size}`
        }).then(res => {
            contextStorage.setTmpData(false)
            contextStorage.setEditForm(false)
            getServices()
        }).catch(err => console.log(err))
    }, [contextStorage.tmpData]);

    useEffect(() => {
        setActiveService(serviceObjs[itemIndex])
    }, [itemIndex]);

    const handleImageUpload = (e) => {
        setFile(e.target.files[0])
        setFormData({ ...formData, img: e.target.files[0] })
    }

    const handleSave = async () => {
        setInProgress(true)
        const fd = new FormData()
        fd.append('name', formData.name || '')
        fd.append('img', formData.img || false)
        fd.append('sort', formData.sort || 0)
        fd.append('beschrijving', formData.beschrijving || '')
        fd.append('active', true)
        fd.append('redirect', formData.redirect || '')
        fd.append('locations', JSON.stringify(formData.locations))
        axios.post(process.env.REACT_APP_BASE_URL + '/api/categories/add', fd, {
            // 'content-type': 'multipart/form-data'
            'content-type': file.type,
            'content-length': `${file.size}`
        }).then(r => {
            setInProgress(false)
            getServices()
            setCreateForm(false)
        }).catch(e => {
            console.log(e)
            setInProgress(false)
        })
    }

    const deleteCategory = async (id) => {
        setItemID(id)
        contextStorage.setWarning(true)
    }

    const setAvailableIn = (location, state) => {
        const copy = _.cloneDeep(formData)
        const locations = copy.locations || {dentergem: false, deinze: false}
        locations[location] = state
        copy.locations = locations
        setFormData(copy)
    }

    useEffect(() => {
        contextStorage.continueDelete ?
            axios.get(process.env.REACT_APP_BASE_URL + '/api/categories/' + itemID)
                .then(r => {
                    contextStorage.setDeletePending(true)
                    setTimeout(() => {
                        contextStorage.setWarning(false)
                        contextStorage.setDeletePending(false)
                        contextStorage.setContinueDelete(false)
                    }, 1000);
                    getServices()
                })
                .catch(e => {
                    contextStorage.setDeletePending(true)
                    contextStorage.setWarningMessage("Verwijderen lukt niet")
                    setTimeout(() => {
                        contextStorage.setWarning(false)
                        contextStorage.setDeletePending(false)
                        contextStorage.setContinueDelete(false)
                    }, 1000);
                })
            : contextStorage.setDeletePending(false)
    }, [contextStorage.continueDelete]);

    const handleCreateCategory = (val) => {
        setFormData({})
        setCreateForm(val)
    }

    useEffect(() => {
        console.log(formData)
    }, [formData]);

    return (
        <div>
            <div
                className="mx-auto flex flex-col md:flex-row items-start md:items-center justify-between py-10 mb-2 bg-slate-200 rounded-md px-6">
                <h4 className="text-3xl font-bold leading-tight text-gray-800 ">Overzicht van categorieën</h4>
                <div className="mt-6 md:mt-0">
                    <button
                        className="transition focus:outline-none duration-150 ease-in-out border border-cyan-500 bg-cyan-500 rounded text-white px-8 py-2 text-md hover:text-cyan-500 hover:bg-gray-100"
                        onClick={() => handleCreateCategory(true)}>Categorie aanmaken
                    </button>
                </div>
            </div>
            <table className="w-full text-left bg-slate-50 rounded-md overflow-hidden py-24">
                <thead className="bg-slate-200 w-full text-gray-800 rounded-tr-md rounded-tl-md overflow-hidden">
                    <tr className=" mb-3 border-b border-slate-200">
                        <th className=" py-5 px-5 text-left">Image</th>
                        <th className=" py-5 px-5 text-left">Naam</th>
                        <th className=" py-5 px-5 text-left">Volgorde</th>
                        <th className=" py-5 px-5 text-left">Korte beschrijving</th>
                        <th className=" py-5 px-5 text-left"></th>
                    </tr>
                </thead>
                <tbody>
                    {services && services.length == 0 || services == undefined ?
                        <h2 className=" pl-10 text-4xl py-12 w-full whitespace-nowrap">Categorieën worden opgehaald</h2>
                        : services && services.map((el, index) =>
                            <tr key={index} className="capitalize border-b border-slate-200">
                                <td className="pt-4 px-5 pb-5 text-xs sm:text-sm"><img src={el.img}
                                    className=" w-20 h-20 rounded-md"
                                    alt="" /></td>
                                <td className="pt-4 px-5 pb-5 text-xs sm:text-sm">{el.name}</td>
                                <td className="pt-4 px-5 pb-5 text-xs sm:text-sm">{el.sort}</td>
                                <td className="pt-4 px-5 pb-5 text-xs sm:text-sm">{el.beschrijving}</td>

                                <td className="px-5 pt-4 pb-5">
                                    <div className="flex justify-end items-center">
                                        <button
                                            className="text-cyan-500 bg-slate-50 hover:text-white border border-cyan-500 rounded-md px-4 py-1 hover:bg-cyan-500 bg transition-all"
                                            onClick={() => handleClick(el)}>Aanpassen
                                        </button>
                                        <button
                                            className="ml-2 text-red-700 bg-slate-50 hover:text-white border border-red-700 rounded-md px-4 py-1 bg-transparent hover:bg-red-700 bg transition-all"
                                            onClick={() => deleteCategory(el.id)}>Verwijderen
                                        </button>
                                    </div>
                                </td>
                            </tr>)}
                </tbody>
            </table>
            {formData && services && <Form data={activeService} clear={() => setFormData(false)} customFields={[{ type: 'file', name: 'afbeelding' }, { type: 'toggle', name: 'active', label: 'Actief' }, {
                type: 'locations',
                name: 'locations',
                label: 'Beschikbaar in'
            }]} />}
            <div
                className={` ${createForm ? "fixed" : "hidden "} fixed h-screen w-screen inset-0 flex flex-col justify-center items-center bg-black bg-opacity-70 z-50`}>
                <div
                    className="dynamic-width pb-0 bg-white min-w-[50vw] max-h-[80vh] overflow-y-scroll rounded-tl-md rounded-tr-md block">
                    <div className="flex flex-col items-center w-full pt-7">
                        <h4 className="text-3xl font-normal text-cyan-500">{inProgress ? "Opslaan wordt verwerkt" : "Nieuwe categorie aanmaken"}</h4>
                    </div>
                    <div className="grid grid-cols-2 gap-x-10 gap-y-2 py-10 px-10">
                        <div className="mb-4 mx-2" >
                            <div>
                                <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                    Naam
                                </label>
                            </div >
                            <div className="">
                                <input className="bg-gray-100 appearance-none border-2 border-gray-100 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name" type="text"
                                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                    value={formData.name || ""} />
                            </div>
                        </div>
                        <div className="mb-4 mx-2" >
                            <div>
                                <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                    Korte beschrijving
                                </label>
                            </div >
                            <div className="">
                                <input className="bg-gray-100 appearance-none border-2 border-gray-100 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name" type="text"
                                    onChange={(e) => setFormData({ ...formData, beschrijving: e.target.value })}
                                    value={formData.beschrijving || ""} />
                            </div>
                        </div>
                        <div className="mb-4 mx-2">
                            <div>
                                <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                    Afbeelding
                                </label>
                            </div>
                            <div
                                className="bg-gray-100 appearance-none border-2 border-gray-100 rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500">
                                <input type="file" accept="image/*" onChange={handleImageUpload} />
                            </div>
                        </div>
                        <div className="mb-4 mx-2" >
                            <div>
                                <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                    Volgorde
                                </label>
                            </div >
                            <div className="">
                                <input className="bg-gray-100 appearance-none border-2 border-gray-100 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name" type="number"
                                    onChange={(e) => setFormData({ ...formData, sort: e.target.value })}
                                    value={formData.sort || ""} />
                            </div>
                        </div>
                        <div className="mb-4 mx-2">

                            <div>
                                <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                    Beschikbaar in
                                </label>
                            </div>
                            <div className="flex">
                                <label className="flex flex-col items-center mr-4">
                                    Dentergem
                                    <input className="" id="inline-full-name" type="checkbox"
                                           onChange={(e) => setAvailableIn('dentergem', e.target.checked)} />
                                </label>
                                <label className="flex flex-col items-center">
                                    Deinze
                                    <input className="" id="inline-full-name" type="checkbox"
                                           onChange={(e) => setAvailableIn('deinze', e.target.checked)} />
                                </label>

                            </div>
                        </div>
                        <div className="mb-4 mx-2" >
                            <div>
                                <label className="block text-gray-500 font-bold mb-1.5 capitalize">
                                    Doorverwijs-url
                                </label>
                            </div >
                            <div className="">
                                <input className="bg-gray-100 appearance-none border-2 border-gray-100 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name" type="text"
                                    onChange={(e) => setFormData({ ...formData, redirect: e.target.value })}
                                    value={formData.redirect} />
                            </div>
                        </div>
                    </div>
                    <div className={`bg-slate-200 py-6 flex justify-end pr-5 rounded-br-md rounded-bl-md ${inProgress ? "pointer-events-none" : "pointer-events-auto"}`}>
                        <button
                            className={`focus:outline-none transition duration-150 ease-in-out rounded text-white px-8 py-2 text-sm mr-4 
                             ${inProgress ? 'bg-red-300 pointer-events-none' : formData.name == '' || formData.description == '' || !formData.img || formData.sort == '' ? "bg-red-300 pointer-events-none" : "bg-cyan-500 hover:bg-cyan-600 pointer-events-auto"}`}
                            onClick={handleSave}>{inProgress ? 'Even geduld' : formData.name == '' || formData.description == '' || !formData.img || formData.sort == '' ? 'Velden niet compleet' : "Opslaan"}
                        </button>
                        {!inProgress && <button
                            className="text-cyan-500 bg-slate-50 hover:text-white border border-cyan-500 rounded-md px-4 py-1 bg-transparent hover:bg-cyan-500 bg transition-all"
                            onClick={() => setCreateForm(false)}>Sluiten
                        </button>}
                    </div>
                </div>
                {/* {inProgress && <div className=" h-full w-full bg-black bg-opacity-10 absolute inset-0 -z-10"></div>} */}
            </div>
            <DeletePopup />
        </div>
    )
}
export default Categories